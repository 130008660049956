import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { environment } from '@candidate/environments/environment'
import { CommunityInviteStore, CommunityMemberStore, CommunityMessageStore, CommunityRoleStore, CommunityStore } from '@engineering11/community-web'
import { ConversationMessageStore, ConversationStore } from '@engineering11/messaging-web'
import { NotificationStore } from '@engineering11/notifications-web'
import { ConnectionStore, InviteStore } from '@engineering11/social-web'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { UiScrollLockingService } from 'projects/shared-lib/src/lib/service/scroll-locking.service'
import { hookToGlobalState, WebpackTranslateLoader } from 'shared-lib'
import { ConfigService } from './services/config.service'
import { FaviconSwitchingService } from './services/favicon-switching.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  preserveWhitespaces: true,
})
export class AppComponent implements OnInit {
  theme: string = 'default'
  features: any[] = []
  settings = false
  _environment = environment
  constructor(
    private faviconService: FaviconSwitchingService, //  ! This is needed to ensure we switch the favicon when we need to
    private titleService: Title,
    private translateService: TranslateService,
    private configService: ConfigService,
    private backdropService: E11BackdropService,
    private conversationStore: ConversationStore,
    private messageStore: ConversationMessageStore,
    private communityStore: CommunityStore,
    private communityMessageStore: CommunityMessageStore,
    private communityMemberStore: CommunityMemberStore,
    private communityInviteStore: CommunityInviteStore,
    private communityRoleStore: CommunityRoleStore,
    private notificationStore: NotificationStore,
    private store: Store,
    private scrollLockService: UiScrollLockingService,
    private notificationsService: E11NotificationsService,
    private connectionStore: ConnectionStore,
    private inviteStore: InviteStore // Store
  ) {
    translateService.setDefaultLang('en')
    translateService.currentLoader = new WebpackTranslateLoader()
    this.theme = configService.config.theme
    this.features = configService.config.featureFlags
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle)
  }

  ngOnInit(): void {
    hookToGlobalState(this.conversationStore.getState, 'messaging-conversation', this.store)
    hookToGlobalState(this.messageStore.getState, 'messaging-message', this.store)

    hookToGlobalState(this.communityStore.getState, 'community', this.store)
    hookToGlobalState(this.communityMessageStore.getState, 'community-message', this.store)
    hookToGlobalState(this.communityMemberStore.getState, 'community-member', this.store)
    hookToGlobalState(this.communityInviteStore.getState, 'community-invite', this.store)
    hookToGlobalState(this.communityRoleStore.getState, 'community-role', this.store)

    hookToGlobalState(this.connectionStore.getState, 'social-connections', this.store)
    hookToGlobalState(this.inviteStore.getState, 'social-invites', this.store)

    hookToGlobalState(this.notificationStore.getState, 'notification', this.store)

    this.setTitle('cnect')
    this.settings = this.isFeatureFlagEnabled('settings')
  }
  reload() {
    location.reload()
  }

  isFeatureFlagEnabled(flag: any) {
    return this.features && this.features[flag]
  }

  close(value: boolean) {
    this.backdropService.backdropDisplay(false, '')
  }
}
