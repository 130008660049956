import { Injectable } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { TokenStorage } from 'shared-lib'
import { TokenManagementService as Base } from '@engineering11/token-web'

@Injectable({ providedIn: 'root' })
export class TokenManagementService extends Base {
  constructor(tokenStorage: TokenStorage) {
    super({
      baseUrl: environment.services.token,
      token: () => tokenStorage.getAccessToken(),
    })
  }
}
