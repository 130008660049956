<header>
  <nav class="e11-bg-skin-primary e11-fixed e11-w-full e11-z-[12]">
    <div class="e11-container-full e11-mx-auto e11-flex e11-px-6">
      <div class="e11-hidden e11-header-logo lg:e11-flex e11-items-center e11-cursor-pointer" [routerLink]="'/network'">
        <app-logo-white [height]="'64px'"></app-logo-white>
      </div>

      <div class="e11-grow e11--mr-2 e11-flex e11-items-center lg:e11-hidden">
        <!-- Mobile menu button -->
        <button
          *ngIf="currentUser$ | async as currentUser"
          (click)="toggleMobileSideDrawer()"
          type="button"
          class="e11-inline-flex e11-items-center e11-justify-center e11-p-2 e11-rounded-md e11-text-gray-100 hover:e11-text-gray-100 focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="e11-sr-only">Open main menu</span>
          <span class="material-icons-outlined" *ngIf="(mobileSideDrawerOpened$ | async) != true">menu</span>
          <span class="material-icons-outlined" *ngIf="mobileSideDrawerOpened$ | async">close</span>
        </button>

        <div class="e11-flex e11-items-center e11-ml-5">
          <e11-avatar
            *ngIf="currentUser$ | async as currentUser"
            [imageUrl]="currentUser.photoURL"
            [size]="'md'"
            [initials]="currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)"
            (click)="dropDownOpen = !dropDownOpen"
          ></e11-avatar>

          <span
            (click)="dropDownOpen = !dropDownOpen"
            *ngIf="currentUser$ | async as currentUser"
            class="e11-flex e11-items-center e11-px-3 e11-py-2 e11-rounded-md e11-text-md e11-text-white/80 e11-cursor-pointer"
          >
            {{ currentUser.firstName }} {{ currentUser.lastName }}
            <span class="material-icons">expand_more</span>
          </span>
        </div>
      </div>

      <div class="e11-grow e11-hidden lg:e11-flex e11-text-white e11-gap-4 e11-pl-6">
        <a
          class="e11-flex e11-items-center e11-gap-2 e11-text-white/80 hover:e11-text-white"
          *ngFor="let item of navigationItems"
          [routerLink]="item.route"
        >
          <span class="material-icons">{{ item.icon }}</span> {{ item.name | translate }}
        </a>
      </div>

      <div class="e11-hidden lg:e11-ml-6 lg:e11-flex lg:e11-items-center lg:e11-grow lg:e11-justify-end e11-relative">
        <referral-link-copy *ngIf="features?.referrals && currentUser"></referral-link-copy>

        <!-- Info Tip Initial Implementation -->
        <span
          class="material-icons-outlined e11-text-white e11-cursor-pointer"
          *ngIf="features?.informationalContent && currentUser"
          (click)="infoTipStatus = !infoTipStatus"
        >
          tips_and_updates
        </span>

        <!-- Ad Banner -->
        <info-content-header
          *ngIf="features?.informationalContent && infoTipStatus"
          (closeInfoContent)="infoTipStatus = !infoTipStatus"
        ></info-content-header>

        <ui-notification *ngIf="features?.notifications && currentUser"></ui-notification>

        <!-- Profile dropdown -->
        <div class="e11-ml-3">
          <e11-avatar
            *ngIf="currentUser$ | async as currentUser"
            [imageUrl]="currentUser.photoURL"
            [size]="'md'"
            [initials]="currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)"
            (click)="dropDownOpen = !dropDownOpen"
          ></e11-avatar>

          <div
            [ngClass]="{
              'e11-transition e11-ease-out e11-duration-200 e11-transform e11-opacity-0 e11-scale-95 e11-invisible': !dropDownOpen,
              'e11-transition e11-ease-in duration-75 e11-e11-transform e11-opacity-100 e11-scale-100': dropDownOpen
            }"
            class="e11-origin-top-right e11-absolute e11-right-0 e11-mt-2 e11-w-48 e11-rounded-md e11-shadow-lg e11-py-1 e11-bg-white e11-border e11-border-skin-app-borders focus:e11-outline-none focus:e11-ring-opacity-100"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <a
              [routerLink]="[routes.CONNECTIONS]"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
            >
              {{ 'Home' | translate }}
            </a>
            <a
              [routerLink]="[routes.ACCOUNT]"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
            >
              {{ 'My Account' | translate }}
            </a>

            <a
              href="#"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              (click)="signOut()"
            >
              {{ 'Sign out' | translate }}
            </a>
          </div>
        </div>
        <span
          (click)="dropDownOpen = !dropDownOpen"
          *ngIf="currentUser$ | async as currentUser"
          class="e11-flex e11-items-center sm:e11-visible e11-px-3 e11-py-2 e11-rounded-md e11-text-sm e11-text-white/80 hover:e11-text-white e11-cursor-pointer"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
          <span class="material-icons">expand_more</span>
        </span>
      </div>

      <div class="e11-grow e11-justify-end e11--mr-2 e11-flex e11-items-center lg:e11-hidden">
        <referral-link-copy *ngIf="features?.referrals && currentUser"></referral-link-copy>
        <ui-notification *ngIf="features?.notifications && currentUser"></ui-notification>
      </div>
    </div>
    <!-- </div> -->

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="lg:e11-hidden" [ngClass]="{ 'e11-hidden': !dropDownOpen }" id="e11-mobile-menu">
      <!-- Mobile bg -->
      <div class="e11-py-4 e11-border-t e11-border-skin-app-borders nav-mobile-bg">
        <div class="e11-flex e11-items-center e11-px-4">
          <div class="e11-flex-shrink-0">
            <e11-avatar
              *ngIf="currentUser$ | async as currentUser"
              [imageUrl]="currentUser.photoURL"
              [size]="'sm'"
              (click)="dropDownOpen = !dropDownOpen"
            ></e11-avatar>
          </div>
          <div class="e11-ml-3">
            <div class="e11-text-base e11-font-medium e11-text-gray-200" *ngIf="currentUser$ | async as currentUser">
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </div>
            <div class="e11-text-sm e11-font-medium e11-text-skin-extra-light" *ngIf="currentUser$ | async as currentUser">
              {{ currentUser.email }}
            </div>
          </div>
        </div>

        <div class="e11-mt-3 e11-space-y-1">
          <a
            [routerLink]="[routes.ACCOUNT]"
            class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100"
          >
            {{ 'My Account' | translate }}
          </a>
          <a href="#" class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100" (click)="signOut()">
            {{ 'Sign out' | translate }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>
