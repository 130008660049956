import { IPublicJobPostVM } from '@candidate/app/models/jobs/job-post-public.model'
import { AtLeast } from '@engineering11/types'
import { Action } from '@ngrx/store'
import {
  ICandidateJobApplicationStash,
  ICandidateJobSubmission,
  ICandidateJobSubmissionPreview,
  ICandidateJobVM,
  IProcessedJobApplication,
  IVirtualDialogue,
} from 'shared-lib'

export enum JobActionTypes {
  getAllJobs = '[Job] get all jobs',
  getAllJobsSuccess = '[Job Effects] get all jobs success',
  getJob = '[Job] get job',
  getJobSuccess = '[Job Effects] get job success',
  getPublicJob = '[Job] get public job',
  getPublicJobSuccess = '[Job Effects] get public job success',
  listenJob = '[Application Update] listen job',
  listenJobSuccess = '[Application Effects] listen job success',
  selectJob = '[Job Applications List] select job',
  buildCandidateJobSubmission = '[Job] build candidate job submission',
  buildCandidateJobSubmissionSuccess = '[Job] build candidate job submission success',
  stashJobSubmission = '[Job] stash job submission',
  stashJobSubmissionSuccess = '[Job] stash job submission success',
  updateJobApplicationStash = '[Job] update job application stash',
  updateJobApplicationStashSuccess = '[Job] update job application stash success',
  getJobSubmissionStash = '[Job] get job submission stash',
  getJobSubmissionStashSuccess = '[Job] get job submission stash success',
  getJobSubmissionStashSuccessWithEmpty = '[Job] get job submission stash success with empty',
  processJobApplication = '[Job] process job application',
  processJobApplicationSuccess = '[Job] process job application success',
  processJobApplicationPreview = '[Job] process job application preview',
  processJobApplicationPreviewSuccess = '[Job] process job application preview success',
  candidateResponsesChanged = '[Job] candidate responses changed',
  candidateResponsesChangedSuccess = '[Job] candidate responses changed success',
  createCandidateJob = '[Job] create a new candidate job',
  createCandidateJobSuccess = '[Job] create a new candidate job success',
  reset = '[Job] search reset',
  getAllVirtualDialogues = '[Job Effects] get all virtual dialogues',
  getAllVirtualDialoguesSuccess = '[Job Effects] get all virtual dialogues success',
  getVirtualDialogue = '[Job Effects] get virtual dialogue',
  getVirtualDialogueSuccess = '[Job Effects] get virtual dialogue success',
  error = '[Job] error',
}

export class GetAllJobs implements Action {
  readonly type = JobActionTypes.getAllJobs
  constructor(public payload?: object) {}
}

export class GetAllJobsSuccess implements Action {
  readonly type = JobActionTypes.getAllJobsSuccess
  constructor(public payload: ICandidateJobVM[]) {}
}

export class SelectJob implements Action {
  readonly type = JobActionTypes.selectJob
  constructor(public payload: string) {}
}

export class GetJob implements Action {
  readonly type = JobActionTypes.getJob
  constructor(public payload: string) {}
}

export class GetJobSuccess implements Action {
  readonly type = JobActionTypes.getJobSuccess
  constructor(public payload: ICandidateJobVM) {}
}

export class GetPublicJob implements Action {
  readonly type = JobActionTypes.getPublicJob
  constructor(public payload: string) {}
}

export class GetPublicJobSuccess implements Action {
  readonly type = JobActionTypes.getPublicJobSuccess
  constructor(public payload: IPublicJobPostVM) {}
}

export class ListenJob implements Action {
  readonly type = JobActionTypes.listenJob
  constructor(public payload: string) {}
}

export class ListenJobSuccess implements Action {
  readonly type = JobActionTypes.listenJobSuccess
  constructor(public payload: ICandidateJobVM) {}
}

export class BuildCandidateJobSubmission implements Action {
  readonly type = JobActionTypes.buildCandidateJobSubmission
  constructor(public payload: { jobPostId: string; userId: string }) {}
}
export class BuildCandidateJobSubmissionSuccess implements Action {
  readonly type = JobActionTypes.buildCandidateJobSubmissionSuccess
  constructor(public payload: ICandidateJobSubmissionPreview) {}
}

export class StashJobSubmission implements Action {
  readonly type = JobActionTypes.stashJobSubmission
  constructor(public payload: ICandidateJobSubmissionPreview) {}
}

export class StashJobSubmissionSuccess implements Action {
  readonly type = JobActionTypes.stashJobSubmissionSuccess
  constructor() {}
}

export class GetJobSubmissionStash implements Action {
  readonly type = JobActionTypes.getJobSubmissionStash
  constructor(public payload: { userId: string; jobPostId: string }) {}
}

export class GetJobSubmissionStashSuccess implements Action {
  readonly type = JobActionTypes.getJobSubmissionStashSuccess
  constructor(public payload: ICandidateJobApplicationStash) {}
}

export class UpdateJobApplicationStash implements Action {
  readonly type = JobActionTypes.updateJobApplicationStash
  constructor(public payload: AtLeast<ICandidateJobApplicationStash, 'jobPostId' | 'candidateId'>) {}
}

export class UpdateJobApplicationStashSuccess implements Action {
  readonly type = JobActionTypes.updateJobApplicationStashSuccess
  constructor(public payload: any) {}
}

export class GetJobSubmissionStashSuccessWithEmpty implements Action {
  readonly type = JobActionTypes.getJobSubmissionStashSuccessWithEmpty
  constructor(public payload: { userId: string; jobPostId: string }) {}
}

export class ProcessJobApplication implements Action {
  readonly type = JobActionTypes.processJobApplication
  constructor(public payload: ICandidateJobSubmission) {}
}

export class ProcessJobApplicationSuccess implements Action {
  readonly type = JobActionTypes.processJobApplicationSuccess
  constructor(public payload: IProcessedJobApplication) {}
}
export class ProcessJobApplicationPreview implements Action {
  readonly type = JobActionTypes.processJobApplicationPreview
  constructor(public payload: ICandidateJobSubmissionPreview) {}
}

export class ProcessJobApplicationPreviewSuccess implements Action {
  readonly type = JobActionTypes.processJobApplicationPreviewSuccess
  constructor(public payload: IProcessedJobApplication) {}
}

export class CandidateResponsesChanged implements Action {
  readonly type = JobActionTypes.candidateResponsesChanged
  constructor(public payload: Required<Pick<ICandidateJobApplicationStash, 'jobPostId' | 'candidateId' | 'candidateResponses'>>) {}
}

export class CandidateResponsesChangedSuccess implements Action {
  readonly type = JobActionTypes.candidateResponsesChangedSuccess
  constructor(public payload: any) {}
}

export class CreateCandidateJob implements Action {
  readonly type = JobActionTypes.createCandidateJob
  constructor(public payload: string) {}
}

export class CreateCandidateJobSuccess implements Action {
  readonly type = JobActionTypes.createCandidateJobSuccess
  constructor(public payload: { jobId: string }) {}
}
export class Reset implements Action {
  readonly type = JobActionTypes.reset
  constructor() {}
}

export class GetAllVirtualDialogues implements Action {
  readonly type = JobActionTypes.getAllVirtualDialogues
  constructor() {}
}

export class GetAllVirtualDialoguesSuccess implements Action {
  readonly type = JobActionTypes.getAllVirtualDialoguesSuccess
  constructor(public payload: IVirtualDialogue[]) {}
}

export class GetVirtualDialogue implements Action {
  readonly type = JobActionTypes.getVirtualDialogue
  constructor(public payload: string) {}
}
export class GetVirtualDialogueSuccess implements Action {
  readonly type = JobActionTypes.getVirtualDialogueSuccess
  constructor(public payload: IVirtualDialogue | undefined) {}
}

export class ErrorAction implements Action {
  readonly type = JobActionTypes.error
  constructor(public payload?: any) {}
}

export type JobActions =
  | GetAllJobs
  | GetAllJobsSuccess
  | GetJob
  | GetJobSuccess
  | GetPublicJob
  | GetPublicJobSuccess
  | ListenJob
  | ListenJobSuccess
  | SelectJob
  | BuildCandidateJobSubmission
  | BuildCandidateJobSubmissionSuccess
  | StashJobSubmission
  | StashJobSubmissionSuccess
  | UpdateJobApplicationStash
  | UpdateJobApplicationStashSuccess
  | GetJobSubmissionStash
  | GetJobSubmissionStashSuccess
  | GetJobSubmissionStashSuccessWithEmpty
  | ProcessJobApplication
  | ProcessJobApplicationSuccess
  | ProcessJobApplicationPreview
  | ProcessJobApplicationPreviewSuccess
  | CandidateResponsesChanged
  | CandidateResponsesChangedSuccess
  | CreateCandidateJob
  | CreateCandidateJobSuccess
  | Reset
  | GetAllVirtualDialogues
  | GetAllVirtualDialoguesSuccess
  | GetVirtualDialogue
  | GetVirtualDialogueSuccess
  | ErrorAction
