import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IJobPostReactivatedNotification } from '@candidate/app/models/app-notification.model'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class JobPostReactivatedNotificationHandler implements INotificationHandler<IJobPostReactivatedNotification, INotificationView> {
  notificationType = 'job-post-reactivated'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}

  toViewModel(notification: IJobPostReactivatedNotification): INotificationView {
    return {
      description: `The ${notification.jobTitle} job post has been reactivated.`,
      title: 'Job Post - Reactivated',
      originalNotification: notification,
      viewed: notification.viewed,
      logoUrl: '',
    }
  }

  async onClick(notification: IJobPostReactivatedNotification): Promise<boolean> {
    if (!notification.viewed) {
      await this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.candidateHomeNavigationService.jobApplication(notification.jobPostId)
  }
}
