<!-- <div class="container-view">
  <div class="loader" [ngClass]="{ inactive: !isLoading }">
  </div>
  <ui-loader color="primary" size="large" label=""></ui-loader>

  <app-header-unauthenticated [logo]="'blue'" *ngIf="header !== 'login'"> </app-header-unauthenticated>
  <app-header-unauthenticated [logo]="'blue'" [cta]="'signin'" *ngIf="header !== 'login'"> </app-header-unauthenticated>

  <main class="authentication">
    <div class="e11-container-full container-content block">
      <router-outlet></router-outlet>
    </div>
  </main>
</div> -->
<div class="e11-min-h-full-full e11-bg-skin-app-bg">
  <router-outlet></router-outlet>
</div>
