// Framework
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
@Component({
  selector: 'modal-browser-detection',
  templateUrl: './browser-detection.component.html',
})
export class ModalBrowserDetectionComponent implements OnInit {
  @ViewChild('modalBrowserDetection') modalBrowserDetection: any
  @Input() status: boolean = false
  @Output() closingBrowserModal = new EventEmitter()

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status.currentValue === true) {
      this.modalBrowserDetection.open()
    } else {
      this.modalBrowserDetection?.close()
    }
  }

  closeModal(value: boolean) {
    // true = chose to continue. false = dead stop
    this.closingBrowserModal.emit(value)
    this.modalBrowserDetection.close()
  }
}
