import { Injectable } from '@angular/core'
import { IRegistrationResult } from '@engineering11/registration-web'
import { IUser } from '@engineering11/user-shared'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { NGXLogger } from 'ngx-logger'

export interface IReferrerData {
  campaign: number
  shortCode: string
  source: string
}

/**
 * getAmbassador global javascript
 */
declare global {
  interface Window {
    mbsy: any
  }
}

@Injectable({ providedIn: 'root' })
export class AmbassadorService {
  constructor(private logger: NGXLogger, private errorHandler: E11ErrorHandlerService) {}

  async applyReferrerDataIfNecessary(registrationResult: IRegistrationResult): Promise<IReferrerData | undefined> {
    const referrerData = await this.getReferrerInfo()
    this.logger.log({ referrerData })
    this.logger.log(`Referer: ${referrerData}`)
    if (referrerData && referrerData.campaign && referrerData.shortCode) {
      await this.identifyRegisteredUser(registrationResult.appUser)
      this.track(referrerData.campaign)
    }
    return referrerData
  }

  async getReferrerInfo(): Promise<IReferrerData | undefined> {
    return new Promise((resolve, reject) => {
      window.mbsy.getReferrerInfo((referrerData: IReferrerData) => resolve(referrerData)).catch((err: any) => this.errorHandler.handleError(err))
    })
  }

  identifyRegisteredUser(user: IUser) {
    try {
      window.mbsy.identify(user.id, { email: user.email })
      this.logger.log(`user identified ${user.id} ${user.email}`)
    } catch (err) {
      this.logger.error('An error occurred while identifying user to Ambassador')
    }
  }

  async track(campaign: number) {
    try {
      window.mbsy.track({ campaign: campaign })
      this.logger.log(`user sent to GA for campaign: ${campaign}`)
    } catch (err) {
      this.logger.error('An error occurred while applying referrer campaign')
    }
  }
}
