import { Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'
import { SentApplicationPages } from 'shared-lib'
import { INavigationItem } from '@engineering11/ui-lib/e11-nav-left'
import { Subject } from 'rxjs'
import { scan } from 'rxjs/operators'

export const CNECT_ROUTES = Object.freeze({
  CONNECTIONS: '/network/cnections',
  INVITATIONS: '/network/invitations',
  PROFILES: '/home/profiles',
  APPLICATIONS: '/home/applications',
  ACCOUNT: '/account',
  VIDEOS: '/home/videos',
  COMMUNITIES: '/c/m/messaging/communities',
  NOT_FOUND: '/home/404',
})

@Injectable({ providedIn: 'root' })
export class CandidateHomeNavigationService {
  navigationItems: INavigationItem[] = [
    {
      id: 'applications',
      name: 'Applications',
      route: '/home/applications',
      icon: 'contact_page',
    },
    {
      id: 'profiles',
      name: 'Profiles',
      route: '/home/profiles',
      icon: 'person_pin',
    },
    {
      id: 'videos',
      name: 'Videos',
      route: '/home/videos',
      icon: 'smart_display',
    },
  ]

  mobileSideDrawerToggle$ = new Subject<boolean>()

  mobileSideDrawerToggledState$ = this.mobileSideDrawerToggle$.pipe(scan((acc, _) => !acc, false))

  constructor(private router: Router) {}

  application() {
    return this.applicationPage()
  }

  jobApplication(jobPostId: string) {
    return this.applicationPage({ jobPostId })
  }

  requestedUpdates(jobPostId: string) {
    return this.applicationPage({ jobPostId, applicationPage: SentApplicationPages.ApplicationUpdate })
  }

  virtualDialogue(jobPostId: string, dialogueSessionId: string) {
    return this.applicationPage({ jobPostId, dialogueSessionId })
  }

  networkConnections() {
    return this.router.navigate([CNECT_ROUTES.CONNECTIONS])
  }

  networkInvitations() {
    return this.router.navigate([CNECT_ROUTES.INVITATIONS])
  }

  private applicationPage(queryParams?: Params) {
    return this.router.navigate([CNECT_ROUTES.APPLICATIONS], { queryParams })
  }

  resumeById(resumeId: string) {
    this.resumePage({ resumeId })
  }

  private resumePage(queryParams?: Params) {
    return this.router.navigate([CNECT_ROUTES.PROFILES], { queryParams })
  }

  // Potentially re-add as a feature when bugs are worked out
  // jobApplicationPage(jobPostId: string, applicationPage: ApplicationPage) {
  //   return this.applicationPage({ jobPostId, applicationPage })
  // }
}
