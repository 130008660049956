import { Injectable } from '@angular/core'
import { IQuestionResponse, IVirtualDialogue, TokenStorage } from 'shared-lib'
import { Observable, of } from 'rxjs'
import { VirtualDialogueCandidateRepository } from './virtual-dialogue-candidate.repository'
import { RestApiClient } from '@engineering11/web-api-rest'
import { environment } from '@candidate/environments/environment'
import { map } from 'rxjs/operators'
import { Timestamp } from '@engineering11/web-api-firebase'
import { sortWith, compareAsc, compareDesc, valueOf } from '@engineering11/utility'
@Injectable({ providedIn: 'root' })
export class VirtualDialogueService {
  private client: RestApiClient
  constructor(private candidateRepository: VirtualDialogueCandidateRepository, tokenStorage: TokenStorage) {
    this.client = new RestApiClient({ baseUrl: environment.services.virtualDialogue, token: () => tokenStorage.getAccessToken() })
  }

  get(dialogueId: string): Observable<Timestamp<IVirtualDialogue> | undefined> {
    return this.candidateRepository.get(dialogueId)
  }

  getValueChanges(dialogueId: string): Observable<Timestamp<IVirtualDialogue> | undefined> {
    return this.candidateRepository.getValueChanges(dialogueId)
  }

  getAllByUser(userId: string): Observable<IVirtualDialogue[]> {
    return this.candidateRepository.getAllByCandidate(userId)
  }

  getAllByUserValueChanges(userId: string): Observable<IVirtualDialogue[]> {
    return this.candidateRepository.getAllByCandidateValueChanges(userId)
  }

  getAllByUserAndJobValueChanges(userId: string, jobPostId: string): Observable<IVirtualDialogue[]> {
    const ascByStatus = compareAsc<IVirtualDialogue>(valueOf('status'))
    const descByDate = compareDesc<IVirtualDialogue>(valueOf('dateSentToCandidate'))
    return this.candidateRepository
      .getAllByCandidateAndJobValueChanges(userId, jobPostId)
      .pipe(map(dialogues => sortWith(dialogues, [ascByStatus, descByDate])))
  }

  // This method will also take a string/IFileReceipt when Dave gets the Files SDK install into web. For now I'll just put it as any
  async answerQuestion(virtualDialogue: IVirtualDialogue, questionId: string, response: IQuestionResponse): Promise<IVirtualDialogue> {
    const dialogue = await this.client
      .put<IVirtualDialogue>(`virtual-dialogues/${virtualDialogue.id}/questions/${questionId}/response`, { response })
      .pipe(map(res => res.data))
      .toPromise()
    return dialogue!
  }

  async submitToEmployer(virtualDialogue: IVirtualDialogue): Promise<unknown> {
    return this.client.put(`virtual-dialogues/${virtualDialogue.id}/submit`, {}).toPromise()
  }
}
