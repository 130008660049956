import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { ICandidateUser } from '@cnect/user-shared'
import { AtLeast } from '@engineering11/types'
import { UiModalComponent } from '@engineering11/ui/ui-modal'
import { IUserService } from '@engineering11/user-web'
import { isNotNil } from '@engineering11/utility'
import { select, Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { filter, take } from 'rxjs/operators'
import { getCurrentUser, getCurrentUserCandidate, TokenStorage, UpdateUser } from 'shared-lib'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { Observable, Subject } from 'rxjs'
import { ViewportService } from '@candidate/app/services/viewport.service'
@Component({
  selector: 'first-impression-intro-modal',
  templateUrl: './first-impression-intro-modal.component.html',
  styleUrls: ['./first-impression-intro-modal.component.scss'],
})
export class FirstImpressionIntroModalComponent implements OnInit {
  @ViewChild('modalFirstImpression') modalFirstImpression!: UiModalComponent
  @Output() introComplete = new EventEmitter()

  user$ = this.store.pipe(getCurrentUserCandidate, filter(isNotNil))
  user?: ICandidateUser

  viewport: string = ''
  subs = new SubscriptionManagementService()
  destroy$: Subject<boolean> = new Subject<boolean>()

  // Carousel - need to refactor and let this object be passed into a ui-component
  // NOTE: The images here are static, but we should still make and use a ui-component
  carousel: any = [
    {
      image: '/assets/images/first-impression-tips/1.jpg',
      title: 'Camera-Ready',
      content: "Let's get started… Position yourself 2 to 3 feet from your device and ensure your entire face is visible on the screen.",
    },
    // {
    //   image: '/assets/images/first-impression-tips/2.jpg',
    //   title: 'Mic Check',
    //   content:
    //     'Use the mic to check your audio levels. Soften your delivery and move away from the mic with high levels, or speak louder and move closer with low levels.',
    // },
    {
      image: '/assets/images/first-impression-tips/3.jpg',
      title: 'Illuminate your Settings',
      content:
        'Natural lighting is best, but you can use any well-lit room and face the light source. If you can see yourself clearly in the preview window, you are good to go. If not, try adding some light or moving closer to a window.',
    },
    {
      image: '/assets/images/first-impression-tips/6.jpg',
      title: 'Dress for Success',
      content:
        'Your attire enhances your confidence and personality, so dress to impress! Select shirts or blouses with limited patterns to avoid camera issues.',
    },
    {
      image: '/assets/images/first-impression-tips/7.jpg',
      title: 'Sit up Straight',
      content: 'Posture helps demonstrate confidence, respect, and attentiveness… which employers like!',
    },
    {
      image: '/assets/images/first-impression-tips/8.jpg',
      title: 'Tone',
      content: 'A positive tone has a positive impact on your video. Smile and get ready to deliver your best first impression!',
    },
    {
      image: '/assets/images/first-impression-tips/10.jpg',
      title: 'Setting the Stage',
      content: 'Check your surroundings. A clean, tidy background is key to limit distractions in your video.',
    },
    {
      image: '/assets/images/first-impression-tips/4.jpg',
      title: 'Teleprompter Power',
      content:
        'Communication is key! Use the teleprompter tool to ensure consistent, accurate delivery while appearing spontaneous to employers. Adjust the size, speed, and more to showcase your skills.',
    },
    {
      image: '/assets/images/first-impression-tips/5.jpg',
      title: "That's a Wrap!",
      content: "Once you've finished recording, review and save your video to add to your first impression collection.",
    },
  ]
  firstImpressionTutorialEnabled = true
  slideCurrent: number = 0
  cycle: boolean = false
  userTutorialsEnabledUI: boolean = true
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(getCurrentUser))
  constructor(private tokenStorage: TokenStorage, private store: Store, private logger: NGXLogger, private viewportService: ViewportService) {}

  ngOnInit(): void {
    this.subs.next = this.viewportService.viewportSizeChanged$.subscribe(data => {
      this.viewport = data
    })
    this.user$.pipe(take(1)).subscribe(user => {
      this.user = user
      if (user.settingsFirstImpression?.tutorialsEnabled === undefined || user.settingsFirstImpression?.tutorialsEnabled) {
        this.firstImpressionTutorialEnabled = true
      } else {
        this.firstImpressionTutorialEnabled = false
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  // Carousel
  navigate(direction: string) {
    if (direction == 'next') {
      if (this.slideCurrent >= this.carousel.length - 1) {
        this.cycle ? (this.slideCurrent = 0) : null
      } else {
        this.slideCurrent++
      }
    } else {
      if (this.slideCurrent === 0) {
        this.cycle ? (this.slideCurrent = this.carousel.length - 1) : null
      } else {
        this.slideCurrent--
      }
    }
  }

  disableTipsCheckbox(event: any) {
    // NOTE: We are reversing the value because a checked value of true really means 'tutorialsEnabled' needs to be false
    const tutorialEnabled = !event.target.checked
    this.logger.log({ tutorialEnabled, event })
    this.updateUser(tutorialEnabled)
    this.firstImpressionTutorialEnabled = tutorialEnabled
  }

  openIfEnabled() {
    if (this.modalFirstImpression) this.modalFirstImpression.open()

    this.slideCurrent = 0
  }

  closeModal() {
    if (this.modalFirstImpression) this.modalFirstImpression.close()
    this.introComplete.emit()
  }

  updateUser(tutorialsEnabled = false) {
    const updateRecord: AtLeast<ICandidateUser, 'id'> = { id: this.user!.id, settingsFirstImpression: { tutorialsEnabled } }
    return this.store.dispatch(new UpdateUser(updateRecord))
  }
}
