import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { RequestedUpdateNotification } from '@candidate/app/models/app-notification.model'
import { GetJob } from '@candidate/app/store/job/job.actions'
import { INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'

import { INotificationHandler } from '@engineering11/notifications-web/lib/models/notification-handler.model'
import { Store } from '@ngrx/store'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class RequestedUpdateNotificationHandler implements INotificationHandler<RequestedUpdateNotification, INotificationView> {
  notificationType = 'requested-update'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService,
    private store: Store
  ) {}

  toViewModel(notification: RequestedUpdateNotification): INotificationView {
    let title: string
    let description: string
    // ! needs translations
    switch (notification.requestedFieldChanged) {
      case 'skills': {
        title = 'Skills & Certifications Requested'
        description = `Skills and certifications have been requested for the ${notification.jobTitle} application.`
        break
      }
      case 'certifications': {
        title = 'Skills & Certifications Requested'
        description = `Skills and certifications have been requested for the ${notification.jobTitle} application.`
        break
      }
      case 'education': {
        title = 'Education History Requested'
        description = `Education History has been requested for the ${notification.jobTitle} application.`
        break
      }
      case 'references': {
        title = 'References Requested'
        description = `References have been requested for the ${notification.jobTitle} application.`
        break
      }
      case 'workHistory': {
        title = 'Work History Requested'
        description = `Work History has been requested for the ${notification.jobTitle} application.`
        break
      }
      case 'portfolio': {
        title = 'Portfolio Requested'
        description = `${notification.jobTitle} requested update`
        break
      }
      case 'firstImpression': {
        title = 'First Impression Requested'
        description = `A First Impression for the ${notification.jobTitle} application has been requested.`
        break
      }
    }
    return {
      description,
      title,
      originalNotification: notification,
      viewed: notification.viewed,
      logoUrl: '',
    }
  }

  onClick(notification: RequestedUpdateNotification): boolean | Promise<boolean> {
    this.store.dispatch(new GetJob(notification.jobId))
    this.markAsRead(notification)
    return this.candidateHomeNavigationService.requestedUpdates(notification.jobId)
  }

  markAsRead(notification: RequestedUpdateNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
