// Framework
import { Component, Input, OnInit } from '@angular/core'

// Platform specific

// Third party
import { TranslateService } from '@ngx-translate/core'
import { IApplicationHistory } from '../../model/application-history.model'

@Component({
  selector: 'application-history',
  templateUrl: './application-history.component.html',
  styleUrls: ['./application-history.component.scss'],
})
export class ApplicationHistoryComponent implements OnInit {
  @Input() applicationHistory?: IApplicationHistory[] | null

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}
}
