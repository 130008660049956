<div class="row">
  <div class="col-xs-12 nopadding">
    <ui-panel-classic title="{{ 'The Job' | translate }}" color="primary-alt">
      <div class="row">
        <div class="col-xs-2 e11-hidden md:e11-block logo">
          <img class="image" src="{{ jobDetail.companyLogoUrl }}" alt="{{ jobDetail.companyName }}" width="100%" *ngIf="jobDetail.companyLogoUrl" />
          <div *ngIf="!jobDetail.companyLogoUrl">
            <empty-avatar [name]="jobDetail.companyName" size="large"></empty-avatar>
          </div>
        </div>
        <div class="col-xs-10">
          <h3>{{ jobDetail.jobTitle }}</h3>
          <span>
            {{ jobDetail.companyName }}
          </span>
          <div class="spacer-1rem"></div>
          <div *ngIf="jobDetail.locationAddress">
            <p class="e11-mb-0">{{ jobDetail.locationAddress?.address1 }}</p>
            <p class="e11-mb-0" *ngIf="jobDetail.locationAddress?.address2">{{ jobDetail.locationAddress?.address2 }}</p>
            <p class="e11-mb-0">
              {{ jobDetail.locationAddress?.city }}, {{ jobDetail.locationAddress?.state }}. {{ jobDetail.locationAddress?.zip }}
            </p>
            <p class="e11-mb-0">{{ jobDetail.locationAddress?.countryCode }}</p>
          </div>

          <div class="job-details e11-mt-4">
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Employment Type' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left" *ngIf="jobDetail.employmentType">
                {{ jobDetail.employmentType | employmentType | translate }}
              </div>
              <div class="col-xs-12 col-md-7 nopadding-left" *ngIf="!jobDetail.employmentType">{{ 'Not Specified' | translate }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Remote or In-Person' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">
                {{ jobDetail.employeeLocation }}
              </div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Experience Level' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left" *ngIf="jobDetail.experienceLevel">
                {{ jobDetail.experienceLevel | experienceLevel | translate }}
              </div>
              <div class="col-xs-12 col-md-7 nopadding-left" *ngIf="!jobDetail.experienceLevel">{{ 'Not Specified' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="col-xs-4"></div>
      </div>
    </ui-panel-classic>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 nopadding">
    <ui-panel-classic title="{{ 'Job Details' | translate }}" color="primary-alt">
      <h4>{{ 'Job Description' | translate }}</h4>
      <div [ngxSummernoteView]="jobDetail.jobDescription || ''"></div>

      <div class="spacer-2rem"></div>

      <h4>{{ 'Minimum Qualifications' | translate }}</h4>
      <div [ngxSummernoteView]="jobDetail.minimumQualifications || ''"></div>

      <div class="spacer-2rem"></div>

      <div *ngIf="jobDetail.responsibilities">
        <h4>{{ 'Responsibilities' | translate }}</h4>
        <div [ngxSummernoteView]="jobDetail.responsibilities || ''"></div>
      </div>

      <div class="job-details">
        <!-- loop through job details -->
        <div class="row line-item e11-mb-3" *ngFor="let key of modelKeys">
          <div class="col-xs-12 col-md-3 nopadding-left">{{ translationModelKeys[key] | translate }}:</div>
          <div class="col-xs-12 col-md-8 nopadding-left" *ngIf="!jobDetail[key]">
            <span>{{ 'Not Specified' | translate }}</span>
          </div>
          <div class="col-xs-12 col-md-8 nopadding-left" *ngIf="jobDetail[key]">
            <span *ngIf="key == 'compensationLow' || key == 'compensationHigh'">
              {{ jobDetail[key] | currency }}
            </span>
            <span *ngIf="key === 'educationLevel'">
              {{ jobDetail[key] | degreeLevel | translate }}
            </span>
            <span *ngIf="key !== 'compensationLow' && key !== 'compensationHigh' && key !== 'schedule' && key !== 'educationLevel'">
              {{ jobDetail[key] }}<span *ngIf="key == 'travelPercentage' && jobDetail[key]">%</span>
            </span>
            <ng-container *ngIf="key === 'schedule'">
              <job-schedule [selectedDays]="jobDetail.schedule"></job-schedule>
            </ng-container>
          </div>
        </div>
      </div>
    </ui-panel-classic>
  </div>
</div>

<div class="row disclaimer" *ngIf="jobDetail.legalDisclaimer">
  <div class="col-xs-12 nopadding">
    <ui-panel-classic title="{{ 'Legal Disclaimer' | translate }}" color="primary-alt">
      <div *ngIf="jobDetail.legalDisclaimer" [ngxSummernoteView]="jobDetail!.legalDisclaimer || ''"></div>
    </ui-panel-classic>
  </div>
</div>
