// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ICandidateEnvironment } from 'shared-lib'
const CANDIDATE_BASE_URL = 'https://stage-network.cnected.com'

export const environment: ICandidateEnvironment = {
  production: false,
  env: 'stage',
  firebaseConfig: {
    apiKey: 'AIzaSyBRNqXm82BMKEVWhs9uNjMkwnWTCAOcFoE',
    authDomain: 'cnect-stage-100.firebaseapp.com',
    projectId: 'cnect-stage-100',
    storageBucket: 'cnect-stage-100.appspot.com',
    messagingSenderId: '861440716888',
    appId: '1:861440716888:web:6560b03d55cad55cbbda2d',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-stage-100.cloudfunctions.net',
    jobs: 'https://jobs-api-gateway-azqnk76g.uc.gateway.dev',
    registration: 'https://registration-api-gateway-azqnk76g.uc.gateway.dev',
    events: 'https://events-api-gateway-azqnk76g.uc.gateway.dev',
    applicationHistory: 'https://application-history-api-gateway-azqnk76g.uc.gateway.dev',
    content: 'https://content-api-gateway-azqnk76g.uc.gateway.dev',
    candidate: 'https://candidate-api-gateway-azqnk76g.uc.gateway.dev',
    employer: 'https://employer-api-gateway-azqnk76g.uc.gateway.dev',
    auth: 'https://auth-api-gateway-azqnk76g.uc.gateway.dev',
    files: 'https://files-api-gateway-azqnk76g.uc.gateway.dev',
    messaging: 'https://messaging-api-gateway-azqnk76g.uc.gateway.dev',
    community: 'https://community-api-gateway-azqnk76g.uc.gateway.dev',
    virtualDialogue: 'https://virtual-dialogue-api-gateway-azqnk76g.uc.gateway.dev',
    token: 'https://token-api-gateway-azqnk76g.uc.gateway.dev',
    social: 'https://social-api-gateway-azqnk76g.uc.gateway.dev',
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LdL9f4bAAAAAFVMFNz49EExxbLn-T9KMrDIXg1W',
  tenantId: 'B2C-USER-go3sj',
  cdnCname: 'stage-content.cnected.com',
  algoliaAppId: '3YG4F5RXCG',
  version: 'a13f9225',
  employerEmailActionRoute: 'http://stage-employer.cnected.com/#/auth/actions',
  candidateEmailSuccessRoute: 'https://stage-network.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  emailVerificationEmailTemplateId: 'cwdpiIuZAP8dPQqB5s5K',
  betaMessageId: 'FoyYlvOzSxbxbMvU2ykE',
  candidateBaseUrl: CANDIDATE_BASE_URL,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
