import { Injectable } from '@angular/core'
import { ICandidateJobSubmissionPreview, IProcessedJobApplication } from 'shared-lib'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { combineLatest, from, iif, of } from 'rxjs'

import {
  GetJob,
  GetAllJobs,
  GetAllJobsSuccess,
  GetJobSuccess,
  ErrorAction,
  ProcessJobApplicationPreview,
  ProcessJobApplicationPreviewSuccess,
  ProcessJobApplication,
  ProcessJobApplicationSuccess,
  BuildCandidateJobSubmission,
  BuildCandidateJobSubmissionSuccess,
  GetJobSubmissionStash,
  GetJobSubmissionStashSuccess,
  GetJobSubmissionStashSuccessWithEmpty,
  CreateCandidateJobSuccess,
  CandidateResponsesChanged,
  CandidateResponsesChangedSuccess,
  CreateCandidateJob,
  UpdateJobApplicationStash,
  UpdateJobApplicationStashSuccess,
  GetAllVirtualDialogues,
  GetAllVirtualDialoguesSuccess,
  SelectJob,
  GetVirtualDialogue,
  JobActionTypes,
  GetVirtualDialogueSuccess,
  ListenJob,
  ListenJobSuccess,
  GetPublicJobSuccess,
} from './job.actions'
import { MyJobService } from '@candidate/app/services/jobs/my-job.service'
import { JobApplicationService } from '@candidate/app/services/jobs/job-application.service'
import { Store } from '@ngrx/store'

import * as fromReduce from '@candidate/app/store/reducers'
import { CandidateApplicationStashService } from '@candidate/app/services/candidate-application/candidate-application-stash.service'

import { buildCandidateJobSubmission } from '@candidate/app/util/profile-job-submission.service'
import { NGXLogger } from 'ngx-logger'
import { selectors } from '@candidate/app/store/selectors'
import { isNotNil } from '@engineering11/utility'
import { combineWithInput } from '@engineering11/stream-utility'
import { isEqual } from 'lodash'
import { ThrowNewCustomError } from '../error/error.actions'
import { VirtualDialogueService } from '@candidate/app/services/virtual-dialogue/virtual-dialogue.service'
import { PublicJobPostService } from '@candidate/app/services/jobs/jobs-public.service'

@Injectable()
export class JobsEffects {
  constructor(
    private store: Store<fromReduce.job.State>,
    private actions$: Actions,
    private myJobService: MyJobService,
    private publicJobPostService: PublicJobPostService,
    private jobApplicationService: JobApplicationService,
    private candidateApplicationStashService: CandidateApplicationStashService,
    private virtualDialogueService: VirtualDialogueService,
    private logger: NGXLogger
  ) {}

  onGetAllJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllJobs>(JobActionTypes.getAllJobs),
      switchMap(_ => this.store.pipe(selectors.getCurrentUserId)),
      filter(isNotNil),
      switchMap(userId =>
        this.myJobService.getMyJobs(userId).pipe(
          map(
            response => new GetAllJobsSuccess(response),
            catchError(error => of(new ErrorAction(error)))
          )
        )
      )
    )
  )

  onGetJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetJob>(JobActionTypes.getJob),
      switchMap(action => this.myJobService.getJob(action.payload)),
      filter(isNotNil),
      mergeMap(job => of(new GetJobSuccess(job)))
    )
  )

  onGetPublicJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetJob>(JobActionTypes.getPublicJob),
      switchMap(action => this.publicJobPostService.get(action.payload)),
      filter(isNotNil),
      mergeMap(job => of(new GetPublicJobSuccess(job)))
    )
  )

  onListenJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ListenJob>(JobActionTypes.listenJob),
      switchMap(action => this.store.pipe(selectors.getCurrentUserId, take(1), filter(isNotNil), map(combineWithInput(action)))),
      switchMap(([action, userId]) => this.myJobService.getJobValueChanges(userId, action.payload)),
      filter(isNotNil),
      mergeMap(job => of(new ListenJobSuccess(job)))
    )
  )

  onSelectJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectJob>(JobActionTypes.selectJob),
      switchMap(action => this.store.select(selectors.getSingleJob(action.payload))),
      filter(isNotNil), // TODO: Handle null
      mergeMap(job => of(new GetJobSuccess(job)))
    )
  )

  onBuildCandidateJobSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BuildCandidateJobSubmission>(JobActionTypes.buildCandidateJobSubmission),
      switchMap(action =>
        combineLatest([
          this.store.select(selectors.getCandidateProfileDocument).pipe(filter(isNotNil)),
          this.store.select(selectors.getSingleJob(action.payload.jobPostId)).pipe(filter(isNotNil)),
          this.store.pipe(selectors.getJobApplicationStash),
        ]).pipe(
          tap(jobSubmissionData => this.logger.log({ jobSubmissionData })),
          map(([profile, jobDetail, jobSubmissionStash]) => buildCandidateJobSubmission(jobDetail, profile, jobSubmissionStash)),
          distinctUntilChanged<ICandidateJobSubmissionPreview>(isEqual), // ? Deep equality check - the equality check may be a higher performance cost than a second call
          tap(builtJobSubmission => this.logger.log({ builtJobSubmission })),
          map(jobSubmission => new BuildCandidateJobSubmissionSuccess(jobSubmission))
        )
      )
    )
  )

  onBuildCandidateJobSubmissionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BuildCandidateJobSubmissionSuccess>(JobActionTypes.buildCandidateJobSubmissionSuccess),
      switchMap(action => of(new ProcessJobApplicationPreview(action.payload)))
    )
  )

  onGetJobSubmissionStash$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetJobSubmissionStash>(JobActionTypes.getJobSubmissionStash),
      switchMap(action =>
        this.candidateApplicationStashService.getByUserAndJobPost(action.payload.userId, action.payload.jobPostId).pipe(
          tap(fetchedFromStash => this.logger.log({ fetchedFromStash })),
          switchMap(response =>
            iif(
              () => isNotNil(response),
              of(new GetJobSubmissionStashSuccess(response!)),
              of(new GetJobSubmissionStashSuccessWithEmpty(action.payload))
            )
          ),
          catchError(error => of(new ThrowNewCustomError(error), new BuildCandidateJobSubmission(action.payload)))
        )
      )
    )
  )

  onGetJobSubmissionStashSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetJobSubmissionStashSuccess>(JobActionTypes.getJobSubmissionStashSuccess),
      switchMap(action => of(new BuildCandidateJobSubmission({ jobPostId: action.payload.jobPostId, userId: action.payload.candidateId })))
    )
  )
  onGetJobSubmissionStashSuccessWithEmpty$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetJobSubmissionStashSuccessWithEmpty>(JobActionTypes.getJobSubmissionStashSuccessWithEmpty),
      switchMap(action => of(new BuildCandidateJobSubmission(action.payload)))
    )
  )

  onProcessApplicationPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ProcessJobApplicationPreview>(JobActionTypes.processJobApplicationPreview),
      switchMap(action => this.jobApplicationService.previewApplication(action.payload)),
      tap(fetchedPreview => this.logger.log({ fetchedPreview })),
      map((response: IProcessedJobApplication) => new ProcessJobApplicationPreviewSuccess(response)),
      catchError(error => of(new ErrorAction(error?.response ? error?.response.data?.message : [error])))
    )
  )

  onProcessApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ProcessJobApplication>(JobActionTypes.processJobApplication),
      mergeMap(action =>
        this.jobApplicationService.submitApplication(action.payload).pipe(
          map((response: IProcessedJobApplication) => new ProcessJobApplicationSuccess(response)),
          catchError(error => of(new ErrorAction(error instanceof Error ? [error.message || error] : error)))
        )
      )
    )
  )

  onUpdateJobApplicationStash$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateJobApplicationStash>(JobActionTypes.updateJobApplicationStash),
      switchMap(action =>
        from(this.candidateApplicationStashService.upsert(action.payload)).pipe(
          map(response => new UpdateJobApplicationStashSuccess(response)),
          catchError(error => of(new ErrorAction(error)))
        )
      )
    )
  )

  onCandidateResponsesChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CandidateResponsesChanged>(JobActionTypes.candidateResponsesChanged),
      switchMap(action =>
        from(this.candidateApplicationStashService.upsert(action.payload)).pipe(
          map(response => new CandidateResponsesChangedSuccess(response)),
          catchError(error => of(new ErrorAction(error)))
        )
      )
    )
  )
  /**
   * Uses mergeMap because we don't want inner request to be cancelled if we make a second request
   */
  onCreateCandidateJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCandidateJob>(JobActionTypes.createCandidateJob),
      mergeMap(action =>
        this.myJobService.addToMyJob({ jobId: action.payload }).pipe(
          map(response => new CreateCandidateJobSuccess({ jobId: action.payload })),
          catchError(error => of(new ErrorAction(error)))
        )
      )
    )
  )

  onCreateCandidateJobSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCandidateJobSuccess>(JobActionTypes.createCandidateJobSuccess),
      // map(action => new GetAllJobs()),
      map(action => new GetJob(action.payload.jobId))
    )
  )

  // ? Is there ever a case we need to get jobs data but NOT virtual dialogues?
  onGetAllJobsGetVirtualDialogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllJobs>(JobActionTypes.getAllJobs),
      map(action => new GetAllVirtualDialogues())
    )
  )

  onGetAllVirtualDialogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllVirtualDialogues>(JobActionTypes.getAllVirtualDialogues),
      switchMap(action => this.store.pipe(selectors.getCurrentUserId)),
      filter(isNotNil),
      switchMap(userId => this.virtualDialogueService.getAllByUserValueChanges(userId)),
      map(
        response => new GetAllVirtualDialoguesSuccess(response),
        catchError(error => of(new ErrorAction(error)))
      )
    )
  )

  onGetVirtualDialogue$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetVirtualDialogue>(JobActionTypes.getVirtualDialogue),
      switchMap(action => this.virtualDialogueService.get(action.payload)),
      map(response => new GetVirtualDialogueSuccess(response))
    )
  )
}
