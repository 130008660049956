<div class="header-container e11-w-full e11-bg-white">
  <header class="header e11-container-full e11-mx-auto e11-bg-white e11-boder-b e11-border-skin-app-borders">
    <div class="e11-w-full e11-flex e11-px-4 e11-items-center e11-items-center">
      <div class="e11-w-3/4">
        <ng-container *ngIf="logoImageUrl">
          <img [src]="logoImageUrl" class="img-company-logo" alt="{{ employerName }}" aria-describedby="imgLogoCNect" />
        </ng-container>
        <!-- <img src="{{ logoImageUrl }}" class="pointer" height="60" width="60" alt="{{ employerName }}" aria-describedby="imgLogoCNect" /> -->
      </div>
      <div class="e11-w-1/4 text-right">
        <h3 class="e11-mb-0 nomargin" *ngIf="employerName">{{ employerName }}</h3>
      </div>
    </div>
  </header>
</div>
<div class="container-view">
  <main class="landing e11-mt-20">
    <!-- This is the main content area for the application, including the space for the dynamic left side navigation panel -->
    <!-- <app-nav-side></app-nav-side> -->

    <!-- <div class="container-content" [ngClass]="{'nav-open': sidebarStatus}"> -->
    <div class="e11-container-full e11-mx-auto">
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- <app-footer [sideNav]="sidebarStatus"></app-footer> -->
  <!-- <app-footer></app-footer> -->
</div>
