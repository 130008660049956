import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { CandidateHomeNavigationService, CNECT_ROUTES } from '@candidate/app/services/candidate-home-navigation.service'
import { IdenticonService } from '@candidate/app/services/identicon.service'
import { APP_NAVIGATION_LEFT_GROUPS, NavLeftService } from '@candidate/app/services/nav-left.service'
import { selectors } from '@candidate/app/store/selectors'
import { ICandidateUser } from '@cnect/user-shared'
import { CommunityStore } from '@engineering11/community-web'
import { ConversationStore } from '@engineering11/messaging-web'
import { ConnectionStore } from '@engineering11/social-web'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CnectFeatures, ConversationTypes, getFeatures } from 'shared-lib'

const UI_NAVIGATION_POS = 112
@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss'],
})
export class AppNavLeftComponent implements OnInit, OnDestroy, AfterViewInit {
  routes = CNECT_ROUTES
  @ViewChild('navContainer') navContainer?: ElementRef
  @ViewChild('appFooter') appFooter?: ElementRef
  features$: Observable<CnectFeatures | undefined> = this.store.select(getFeatures)
  features?: CnectFeatures
  destroy$: Subject<boolean> = new Subject<boolean>()
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  conversationTypesEnum = ConversationTypes

  // Left Navigation
  navLeftBottomPos: number = 0
  navTop: number = UI_NAVIGATION_POS // Where the left nav starts out at
  // Groups/sections - Service will handle default values
  showMyNetworkLinks: boolean = false
  showDirectMessages: boolean = false
  showCommunities: boolean = false

  unreadConversationCount$ = this.conversationStore.getUnreadConversationCount
  unreadCommunityChannelCount$ = this.communityStore.unreadChannelCount$
  incomingConnectionCount$ = this.connectionStore.incomingConnectionCount$

  UNREAD_LIMIT = 9

  constructor(
    private store: Store,
    private candidateNavigationService: CandidateHomeNavigationService,
    private navLeftService: NavLeftService,
    public identiconService: IdenticonService,
    private conversationStore: ConversationStore,
    private communityStore: CommunityStore,
    private connectionStore: ConnectionStore
  ) {}

  ngOnInit(): void {
    this.navLeftService.statusSectionNetworkObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showMyNetworkLinks = response
    })

    this.navLeftService.statusSectionMessagesObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showDirectMessages = response
    })

    this.navLeftService.statusSectionCommunitiesObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showCommunities = response
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ngAfterViewInit() {
    let datas = this.navContainer?.nativeElement.getBoundingClientRect()
    this.navLeftBottomPos = datas.top + this.navContainer?.nativeElement.offsetHeight
  }

  getFooterPos() {
    let element = document?.getElementById('appFooter')
    return element!.getBoundingClientRect().top
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event: any[]) {
    if (this.navLeftBottomPos) {
      if (this.getFooterPos() - this.navLeftBottomPos < 112) {
        this.navTop = this.getFooterPos() - this.navLeftBottomPos
      } else {
        this.navTop = 112
      }
    }
  }

  toggleMyNetworkLinks() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Network, !this.showMyNetworkLinks)
  }

  toggleDirectMessages() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Messages, !this.showDirectMessages)
  }

  toggleCommunities() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Communities, !this.showCommunities)
  }

  getIdenticon(userId: string) {
    return this.identiconService.buildPath(userId)
  }
}
