<form [formGroup]="formFirstImpression" (ngSubmit)="onSubmitForm()">
  <div class="e11-pb-8">
    <e11-input
      [type]="'text'"
      name="name"
      id="id_years"
      [style]="'standard'"
      label="{{ 'Name your video' | translate }}"
      labelColor="primary"
      [size]="'md'"
      [borderGlow]="'primary'"
      autocomplete="off"
      [parentForm]="formFirstImpression"
      formControlName="name"
      [required]="true"
      [hasError]="f.name.invalid && f.name.touched"
    >
      <ui-input-errors [parentForm]="formFirstImpression" [formControl]="f.name" label="{{ 'Title' | translate }}"></ui-input-errors>
    </e11-input>
  </div>

  <div *ngIf="_videoURL" class="e11-mb-2">
    <video [controls]="true" [poster]="thumbnails.length > 0 ? thumbnails[selectedThumbnail] : null" [src]="_videoURL"></video>
  </div>
  <div class="e11-mb-2">
    <div *ngIf="thumbnails.length > 0" class="e11-flex e11-items-center e11-justify-between">
      <label for="company-name" class="e11-block e11-text-sm e11-font-medium e11-text-gray-900">
        {{ 'Available thumbnail images' | translate }}
      </label>
      <!-- @DO NOT REMOVE not ready yet -->
      <a class="e11-cursor-pointer e11-text-skin-secondary e11-flex e11-items-center e11-gap-1" (click)="generateThumbs(_video)">
        <span class="material-icons-outlined">replay</span> {{ 'Re-generate' | translate }}
      </a>
    </div>

    <ul role="list" class="e11-mt-2 e11-h-full e11-grid e11-grid-cols-2 e11-gap-x-4 e11-gap-y-8">
      <li *ngFor="let thumbnail of thumbnails; let i = index" class="e11-relative e11-cursor-pointer">
        <div
          *ngIf="i === maxThumbs - 1"
          (click)="file.click()"
          class="e11-absolute e11--right-2 e11--top-2 e11-z-10 e11-rounded-full e11-text-gray-600 e11-bg-white e11-shadow"
        >
          <span class="material-icons-outlined e11-text-center e11-pt-1 e11-text-[16px] e11-h-6 e11-w-6">edit </span>
        </div>
        <div
          class="
            e11-group e11-block e11-w-full e11-aspect-w-4 e11-aspect-h-3 e11-rounded-lg e11-bg-gray-100
            focus-within:e11-ring-2 focus-within:e11-ring-offset-2 focus-within:e11-ring-offset-gray-100 focus-within:e11-ring-skin-primary
            e11-overflow-hidden
          "
          [ngClass]="{ 'e11-ring-2 e11-ring-offset-2 e11-ring-offset-gray-100 e11-ring-skin-primary': selectedThumbnail === i }"
        >
          <img [src]="thumbnail" class="e11-object-cover e11-pointer-events-none group-hover:e11-opacity-75" />
          <button (click)="selectedThumbnail = i" type="button" class="e11-absolute e11-inset-0 focus:e11-outline-none">
            <span class="e11-sr-only">Select this thumbnails</span>
          </button>
        </div>
      </li>
      <li *ngIf="thumbnails.length < maxThumbs" class="e11-relative e11-cursor-pointer">
        <button
          type="button"
          class="
            e11-h-full
            e11-relative
            e11-block
            e11-w-full
            e11-border-2
            e11-border-skin-app-borders
            e11-border-dashed
            e11-rounded-lg
            e11-p-5
            e11-text-center
            hover:e11-border-skin-app-borders
            focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2 focus:e11-ring-secondary
          "
          (click)="file.click()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="e11-mx-auto e11-h-8 e11-w-8 e11-text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
          <p class="e11-mt-1 e11-text-sm e11-text-gray-500 e11-mb-0">
            {{ 'You can add a custom thumbnail image here' | translate }}
          </p>
          <span class="e11-mt-2 e11-block e11-text-sm e11-font-medium e11-text-gray-900"> {{ 'Upload image' | translate }} </span>
        </button>
      </li>
    </ul>
    <div *ngIf="thumbnailsLoading || generateThumbLoading">
      <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary'"></e11-loader-dots>
    </div>
  </div>
  <div>
    <input #file id="file" type="file" accept="image/*" class="e11-hidden" (change)="onFileChange($event)" />
  </div>
  <!-- <div class="e11-pt-4 e11-pb-8">
    <e11-input
      [type]="'text'"
      name="name"
      id="id_years"
      [style]="'standard'"
      label="{{ 'Title' | translate }}"
      labelColor="primary"
      [size]="'medium'"
      [borderGlow]="'primary'"
      autocomplete="off"
      [parentForm]="formFirstImpression"
      formControlName="name"
      [required]="true"
      [hasError]="f.name.invalid && f.name.touched"
    >
      <ui-input-errors [parentForm]="formFirstImpression" [formControl]="f.name" label="{{ 'Title' | translate }}"></ui-input-errors>
    </e11-input>
  </div> -->
  <!-- <div class="e11-mt-2">
    <ui-textarea
      label="{{ 'Description' | translate }}"
      labelColor="primary"
      name="description"
      id="text_description"
      borderGlow="primary"
      [parentForm]="formFirstImpression"
      formControlName="description"
      [rows]="4"
      [cols]="0"
      [required]="false"
      [disabled]="formReadOnly"
    >
    </ui-textarea>
  </div> -->
</form>
