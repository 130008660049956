<ui-panel size="md" id="panel_success" borderRadius="lg">
  <div class="spacer-2rem"></div>

  <div class="row">
    <div class="col-xs-12 text-center">
      <h1 class="text-success">{{ 'Success!' | translate }}</h1>

      <div class="spacer-1rem"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 text-center">
      <span class="h1-subtitle">{{ 'Your account has successfully been verified!' | translate }}</span>

      <div class="spacer-1rem"></div>
      <div class="spacer-2rem"></div>

      <e11-button id="submit_Success" value="{{ 'Back To Login' | translate }}" color="secondary" type="button" (click)="gotoLogin()"> </e11-button>
    </div>
  </div>

  <div class="spacer-2rem"></div>
</ui-panel>
