import { OnInit } from '@angular/core'
// Framework
import { Component } from '@angular/core'

// Analytics
import { AnalyticsService } from '@engineering11/web-api-firebase'
import { Platform, Module, View } from '@candidate/app/analytics.constants'
import { PRIMARY_OUTLET, Router } from '@angular/router'
import { Store } from '@ngrx/store'

@Component({
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class AuthEmailVerificationComponent implements OnInit {
  constructor(private analyticsService: AnalyticsService, private router: Router, private store: Store) {}
  ngOnInit(): void {
    const urlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    let redirectPath = ''
    if (primary.segments.length > 1) {
      redirectPath = decodeURIComponent(primary.segments[primary.segments.length - 1].path || redirectPath)
    }
  }

  resendEmail(): void {
    this.sendAnalyticsEvent('authentication_resend_email', 'form_submit')
  }

  sendAnalyticsEvent(analyticsEvent: string, userAction: string) {
    this.analyticsService.logEvent(Platform.dev, {
      module: Module.authentication,
      view: View.authenticationEmailVerification,
      analyticsEvent: analyticsEvent,
      userAction: userAction,
    })
  }
}
