<div class="e11-w-full e11-h-8 e11-bg-skin-primary-accent"></div>

<footer class="e11-bg-skin-app-bg-dark" aria-labelledby="footer-heading" id="appFooter">
  <h2 id="footer-heading" class="e11-sr-only">Footer</h2>
  <div class="e11-container-full e11-mx-auto">
    <div class="e11-grid e11-grid-cols-1 lg:e11-grid-cols-3">
      <div class="e11-pl-6">
        <app-logo-white [height]="'90px'"></app-logo-white>
      </div>
      <div class="e11-pt-6 e11-pl-6">
        <h4 class="e11-text-skin-primary-accent">{{ 'Support' | translate }}</h4>
        <ul role="list" class="e11-mt-4 e11-space-y-4">
          <li
            (click)="navigateTo('https://cnected.com/')"
            target="_blank"
            class="e11-text-base e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer"
          >
            {{ 'About us' | translate }}
          </li>
        </ul>
      </div>
      <div class="e11-pt-6 e11-pl-6">
        <h4 class="e11-text-skin-primary-accent">{{ 'Legal' | translate }}</h4>
        <ul role="list" class="e11-mt-4 e11-space-y-4">
          <li class="e11-text-base e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer" [routerLink]="['../public/privacy']">
            {{ 'Privacy policy' | translate }}
          </li>
          <li class="e11-text-base e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer" [routerLink]="['../public/terms']">
            {{ 'Terms of use' | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="e11-container-full e11-mx-auto e11-px-6 e11-py-8">
    <app-copyright></app-copyright>
  </div>
</footer>

<!-- Modals -->
<!-- Privacy modal -->
<modal-privacy-policy [status]="statusPrivacy"></modal-privacy-policy>
<!-- Terms modal -->
<modal-terms-of-use [status]="statusTerms"></modal-terms-of-use>
