import { Injectable } from '@angular/core'
import { CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'
import { CandidateResumeService, copyShareUrl } from '@candidate/app/services/resume/candidate-resume.service'
import { combineWithInput } from '@engineering11/stream-utility'
import { isNotNil } from '@engineering11/utility'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { from } from 'rxjs'
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { getCurrentUserId } from 'shared-lib'
import {
  CreateNewResume,
  CreateNewResumeSuccess,
  DeactivateResumeToken,
  DeactivateResumeTokenSuccess,
  DeleteResume,
  DeleteResumeSuccess,
  DeleteResumeToken,
  DeleteResumeTokenSuccess,
  ErrorAction,
  GetAllResumes,
  GetAllResumesSuccess,
  GetAllResumeTokens,
  GetAllResumeTokensSuccess,
  GetNewResumeThreshold,
  GetNewResumeThresholdSuccess,
  NavigateToResume,
  OnInitResume,
  ReactivateResumeToken,
  ReactivateResumeTokenSuccess,
  ResumeActionTypes,
  ShareResume,
  ShareResumeSuccess,
  UpdateResumeAction,
  UpdateResumeSuccess,
  UPDATE_RESUME_ACTION_TYPES,
} from './resume.actions'

@Injectable()
export class ResumeEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private resumeService: CandidateResumeService,
    private logger: NGXLogger,
    private candidateHomeNavigationService: CandidateHomeNavigationService
  ) {}

  ngrxOnInitEffects(): Action {
    return { type: ResumeActionTypes.onInitResume }
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OnInitResume>(ResumeActionTypes.onInitResume),
      switchMap(_ => this.store.pipe(getCurrentUserId)),
      filter(isNotNil),
      tap(userId => this.store.dispatch(new GetNewResumeThreshold(userId))),
      map(userId => new GetAllResumes(userId))
    )
  })

  onGetNewResumeThreshold$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetNewResumeThreshold>(ResumeActionTypes.getNewResumeThreshold),
      switchMap(({ userId }) => this.resumeService.getNewResumeThreshold(userId)),
      map(threshold => new GetNewResumeThresholdSuccess(threshold))
    )
  )

  onGetAllResumes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllResumes>(ResumeActionTypes.getAllResumes),
      switchMap(({ userId }) => this.resumeService.getAllForUser(userId)),
      map(resumes => new GetAllResumesSuccess(resumes))
    )
  )

  onGetAllResumesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllResumesSuccess>(ResumeActionTypes.getAllResumesSuccess),
      map(action => new GetAllResumeTokens())
    )
  )

  onCreateResume$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewResume>(ResumeActionTypes.createNewResume),
      mergeMap(action => this.resumeService.create(action.payload)),
      map(result => new CreateNewResumeSuccess(result))
    )
  )

  onCreateResumeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewResumeSuccess>(ResumeActionTypes.createNewResumeSuccess),
      map(action => new NavigateToResume(action.payload.id)) // Navigate right after we create
    )
  )

  onNavigateToResume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<NavigateToResume>(ResumeActionTypes.navigateToResume),
        map(action => this.candidateHomeNavigationService.resumeById(action.payload))
      ),
    { dispatch: false }
  )

  onUpdateResume$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateResumeAction>(...UPDATE_RESUME_ACTION_TYPES),
      mergeMap(action => from(this.resumeService.update(action.payload)).pipe(map(_ => action))),
      map(action => new UpdateResumeSuccess(action.payload))
    )
  )

  fetchNewThreshold$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewResumeSuccess | DeleteResumeSuccess>(ResumeActionTypes.createNewResumeSuccess, ResumeActionTypes.deleteResumeSuccess),
      switchMap(_ => this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))),
      map(userId => new GetNewResumeThreshold(userId))
    )
  )

  onDeleteResume$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteResume>(ResumeActionTypes.deleteResume),
      mergeMap(action => from(this.resumeService.delete(action.id)).pipe(map(_ => action))),
      map(action => new DeleteResumeSuccess(action.id))
    )
  )

  onShareResume$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShareResume>(ResumeActionTypes.shareResume),
      mergeMap(action => this.resumeService.createAccessToken(action.payload.resumeId, action.payload.settings)),
      tap(response => copyShareUrl(response)),
      map(response => new ShareResumeSuccess(response))
    )
  )

  onGetAllResumeTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllResumeTokens>(ResumeActionTypes.getAllResumeTokens),
      switchMap(action => this.resumeService.getAllTokens()),
      map(response => new GetAllResumeTokensSuccess(response))
    )
  )

  onDeactivateResumeToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeactivateResumeToken>(ResumeActionTypes.deactivateResumeToken),
      mergeMap(action => this.resumeService.deactivateAccessToken(action.payload.token).pipe(map(combineWithInput(action)))),
      map(([action, response]) => new DeactivateResumeTokenSuccess(action.payload))
    )
  )

  onReactivateResumeToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReactivateResumeToken>(ResumeActionTypes.reactivateResumeToken),
      mergeMap(action => this.resumeService.reactivateAccessToken(action.payload.token).pipe(map(combineWithInput(action)))),
      map(([action, response]) => new ReactivateResumeTokenSuccess(action.payload))
    )
  )

  onDeleteResumeToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteResumeToken>(ResumeActionTypes.deleteResumeToken),
      mergeMap(action => this.resumeService.deleteAccessToken(action.payload.token).pipe(map(combineWithInput(action)))),
      map(([action, response]) => new DeleteResumeTokenSuccess(action.payload))
    )
  )

  onError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ErrorAction>(ResumeActionTypes.error),
        map(action => this.logger.error(action.payload))
      ),
    { dispatch: false }
  )
}
