import { ICandidateDetails, ICandidateResume } from '@candidate/app/models/candidate-resume.model'
import { removeDuplicatesByNameIgnoreCase } from '@candidate/app/util/remove-duplicates'
import { omit } from '@engineering11/utility'
import { ICandidateApplicationDetails, ICandidateJobSubmission, IJobApplicationStage, SkillsAndCerts } from 'shared-lib'

export function resumeToApplication(resume: ICandidateResume, jobPostId: string): Omit<IJobApplicationStage, 'id'> {
  return {
    candidateId: resume.userId,
    jobPostId,
    firstName: resume.firstName,
    lastName: resume.lastName,
    email: resume.email,
    resumeId: resume.id,
    address: resume.address,
    phoneNumber: resume.phone,
    photoURL: resume.photo?.url ?? null, // Necessary to allow removing pfp
    summary: resume.summary,
    website: resume.website,
    militaryAffiliation: resume.militaryAffiliation,
    candidateCoreCopy: undefined, // ! When we build core, slot it in
    workHistory: resume.workHistory,
    education: resume.education,
    skills: resume.skills,
    certifications: resume.certifications,
    references: resume.references,
    firstImpression: resume.firstImpression ?? null,
    portfolio: resume.portfolio ?? [],
    parsedResumeFileId: resume.parsedResumeFileId,
  }
}

export function stageHasResumeData(applicationStage: IJobApplicationStage | undefined) {
  return !!(applicationStage && (applicationStage.resumeId || applicationStage.parsedResumeFileId))
}
export function resumeDetailsToApplicationDetails(candidateDetails: ICandidateDetails): ICandidateApplicationDetails {
  const matchingKeys = omit(candidateDetails, 'phone')
  return { ...matchingKeys, phoneNumber: candidateDetails.phone }
}

export function applicationDetailsToResumeDetails(applicationDetails: ICandidateApplicationDetails): ICandidateDetails {
  const matchingKeys = omit(applicationDetails, 'phoneNumber')
  return { ...matchingKeys, phone: applicationDetails.phoneNumber }
}

export function stageToSubmission(stagedApplication: IJobApplicationStage): ICandidateJobSubmission {
  return omit(stagedApplication, 'candidateId', 'id')
}

export function addNewSkillsAndCerts<T extends SkillsAndCerts>(currentSkillsCertsObj: T, skillsAndCerts: SkillsAndCerts): T {
  const skills = removeDuplicatesByNameIgnoreCase([...currentSkillsCertsObj.skills, ...skillsAndCerts.skills])
  const certifications = removeDuplicatesByNameIgnoreCase([...currentSkillsCertsObj.certifications, ...skillsAndCerts.certifications])
  return { ...currentSkillsCertsObj, skills, certifications }
}
