// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { canActivate } from '@angular/fire/compat/auth-guard'
import { loggedInOnlyGuard } from '@engineering11/auth-web'

// Platform specific
import { FileNotFoundComponent } from '../_404/404.component'
import { HomeComponent } from './home.component'
import { AboutComponent } from './about/about.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'applications',
        pathMatch: 'full',
      },
      {
        path: 'profiles',
        loadChildren: () => import('../resume/resume.module').then(m => m.ResumeModule),
      },
      {
        path: 'resume-parsing',
        loadChildren: () => import('../resume/resume.module').then(m => m.ResumeModule),
      },
      {
        path: 'apply/:jobPostId',
        loadChildren: () => import('../applications/applications.module').then(m => m.ApplicationsModule),
        ...canActivate(loggedInOnlyGuard('registration/register-apply')),
      },
      {
        path: 'applications/:jobPostId',
        loadChildren: () => import('../applications/applications.module').then(m => m.ApplicationsModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'applications',
        loadChildren: () => import('../applications/applications.module').then(m => m.ApplicationsModule),
      },
      {
        path: 'videos',
        loadChildren: () => import('../videos/videos.module').then(m => m.VideosModule),
      },

      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: '404',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
