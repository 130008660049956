import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { UserMentionedNotification } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class MessagingUserMentionedHandler implements INotificationHandler<UserMentionedNotification, INotificationView> {
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}
  toViewModel(notification: UserMentionedNotification): INotificationView {
    return {
      description: `${notification.displayName} has mentioned you.`,
      logoUrl: '',
      originalNotification: notification,
      viewed: notification.viewed,
      title: 'Mentioned',
    }
  }
  onClick(notification: UserMentionedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['/c/m/messaging/conversation/', notification.conversationId])
  }

  notificationType: string = 'messaging/user-mentioned'
}
