// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Platform specific
import { PublicComponent } from './public.component'
import { FileNotFoundComponent } from '../_404/404.component'
import { PublicPrivacyComponent } from './views/privacy/privacy.component'
import { PublicTermsComponent } from './views/terms/terms.component'

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    // canActivate: [ AuthGuard ],
    children: [
      { path: 'profile-share', loadChildren: () => import('../profile-share/profile-share.module').then(m => m.ProfileShareModule) },
      {
        path: 'privacy',
        component: PublicPrivacyComponent,
      },
      {
        path: 'terms',
        component: PublicTermsComponent,
      },
      {
        path: '404',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
