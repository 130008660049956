// Framework
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core'

// Platform specific
import { selectors } from '@candidate/app/store/selectors'
import { environment } from '@candidate/environments/environment'
import { BetaMessageService, CnectFeatures, getFeatures, OnLogOut } from 'shared-lib'
import { CandidateHomeNavigationService, CNECT_ROUTES } from '@candidate/app/services/candidate-home-navigation.service'
import { INavigationItem } from '@engineering11/ui-lib/e11-nav-left'
import { ICandidateUser } from '@cnect/user-shared'

// Third party
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AnimationFading } from 'projects/shared-lib/src/lib/e11-animations'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [AnimationFading.shrinkFadeOut, AnimationFading.scaleUp],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  routes = CNECT_ROUTES
  message?: string
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  features$: Observable<CnectFeatures | undefined> = this.store.select(getFeatures)
  currentUser?: ICandidateUser | null
  initials?: string
  showBeta: boolean = false
  features?: CnectFeatures
  dropDownOpen = false
  unreadCount = 0
  destroy$: Subject<boolean> = new Subject<boolean>()
  navigationItems: INavigationItem[] = []
  infoTipStatus: boolean = false
  mobileSideDrawerOpened$ = this.candidateNavigationService.mobileSideDrawerToggledState$

  constructor(
    private store: Store,
    private betaMessageService: BetaMessageService,
    private elementRef: ElementRef,
    private candidateNavigationService: CandidateHomeNavigationService
  ) {
    this.navigationItems = candidateNavigationService.navigationItems
  }

  ngOnInit() {
    this.betaMessageService
      .get(environment.betaMessageId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(message => {
        this.message = message?.emailHtml
      })
    this.features$.pipe(takeUntil(this.destroy$)).subscribe(features => (this.features = features))
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(currentUser => {
      this.currentUser = currentUser
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  signOut(): void {
    this.store.dispatch(new OnLogOut())
  }

  // Handles user clicking their pfp or account name
  onClickAccount() {
    this.dropDownOpen = !this.dropDownOpen
  }

  toggleMobileSideDrawer() {
    this.candidateNavigationService.mobileSideDrawerToggle$.next(true)
  }

  // Closers - Coffee is for closers only
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any[]) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.dropDownOpen = false
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dropDownOpen = false
    }
  }
}
