// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Routing
import { RegistrationRoutingModule } from './registration.routing'

// Modules
import { SharedModule } from '../_shared/shared.module'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Components
import { RegistrationComponent } from './registration.component'
import { RegistrationRegisterComponent } from './views/register/register.component'
import { RegistrationPasswordCreateComponent } from './views/password-create/password-create.component'
import { IRegistrationConfig, RegistrationModule as RegistrationModuleSDK } from '@engineering11/registration-web'
import { environment } from '@candidate/environments/environment'
import { RegisterApplyComponent } from './views/register-apply/register-apply.component'
import { RegisterReferComponent } from './views/register-refer/register-refer.component'
import { FilesModule } from '@engineering11/files-web'

const registrationConfig: IRegistrationConfig = {
  registrationBaseURL: environment.services.registration,
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: environment.emailVerificationEmailTemplateId,
  tenantId: environment.tenantId,
}
@NgModule({
  declarations: [
    RegistrationComponent,
    RegistrationRegisterComponent,
    RegistrationPasswordCreateComponent,
    RegisterApplyComponent,
    RegisterReferComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RegistrationRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    FilesModule,
    ReactiveFormsModule,
    RegistrationModuleSDK.forRoot(registrationConfig),
  ],
  exports: [],
  providers: [],
})
export class RegistrationModule {}
