import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { VirtualDialogueSubmittedNotificationForCandidate } from '@candidate/app/models/app-notification.model'
import { INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { INotificationHandler } from '@engineering11/notifications-web/lib/models/notification-handler.model'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class VirtualDialogueSubmittedCandidate implements INotificationHandler<VirtualDialogueSubmittedNotificationForCandidate, INotificationView> {
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}
  notificationType = 'virtual-dialogue-submitted-for-candidate'
  toViewModel(notification: VirtualDialogueSubmittedNotificationForCandidate): INotificationView {
    return {
      viewed: notification.viewed,
      description: `${notification.jobTitle} has an available dialogue`,
      logoUrl: '',
      originalNotification: notification,
      title: 'Dialogue',
    }
  }
  onClick(notification: VirtualDialogueSubmittedNotificationForCandidate): boolean | Promise<boolean> {
    this.markAsRead(notification)
    return this.candidateHomeNavigationService.jobApplication(notification.jobPostId)
  }

  private markAsRead(notification: VirtualDialogueSubmittedNotificationForCandidate) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
