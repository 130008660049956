<ul>
  <li *ngFor="let day of selectedDays">
    <span *ngIf="day.selected">
      {{ day.label }}
      <span *ngIf="checkDataType(day.from, 'string') || checkDataType(day.to, 'string')">
        ({{ convertMilitaryStringToDate(day.from!) }} - {{ convertMilitaryStringToDate(day.to!) }})
      </span>
    </span>
  </li>
</ul>
