import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IAppNotification, INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'

export interface IConnectionAcceptedNotification extends IAppNotification {
  type: 'social-invite-accepted'
  addresseeFirstName: string
  addresseeLastName: string
  addresseeDisplayName?: string
}

export type ConnectionAcceptedNotificationVM = INotificationView

@Injectable({ providedIn: 'root' })
export class ConnectionAcceptedNotificationHandler
  implements INotificationHandler<IConnectionAcceptedNotification, ConnectionAcceptedNotificationVM>
{
  notificationType = 'social-invite-accepted'
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  toViewModel(notification: IConnectionAcceptedNotification): ConnectionAcceptedNotificationVM {
    return {
      logoUrl: '',
      title: 'Invite Accepted',
      description: `${notification.addresseeFirstName} ${notification.addresseeLastName} (${notification.addresseeDisplayName}) has accepted your invite.`,
      viewed: notification.viewed,
      originalNotification: notification,
    }
  }

  onClick(notification: IConnectionAcceptedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    this.router.navigate(['/network/cnections'])
    return true
  }
}
