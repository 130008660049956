import { OnInit } from '@angular/core'
// Framework
import { Component } from '@angular/core'
import { Params, PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router'
import * as fromReduce from '@candidate/app/store/reducers'
import { IRegistrationResult } from '@engineering11/registration-web'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
// Analytics
import { AnalyticsService } from '@engineering11/web-api-firebase'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { OnLogIn, OnRegistration, TokenStorage } from 'shared-lib'

@Component({
  templateUrl: './password-create.component.html',
  styleUrls: ['./password-create.component.scss'],
})
export class RegistrationPasswordCreateComponent implements OnInit {
  loading = false
  redirectPath = 'profile'
  queryParams!: Params
  urlTree!: UrlTree
  primary!: UrlSegmentGroup

  constructor(
    private analyticsService: AnalyticsService,
    private tokenStorage: TokenStorage,
    private notificationsService: E11NotificationsService,
    private store: Store<fromReduce.user.State>,
    private router: Router,
    private translate: TranslateService,
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    this.urlTree = this.router.parseUrl(this.router.url)
    this.primary = this.urlTree.root.children[PRIMARY_OUTLET]
    this.queryParams = this.urlTree.queryParams
    if (this.primary.segments.length > 1) {
      this.redirectPath = decodeURIComponent(this.primary.segments[this.primary.segments.length - 1].path || this.redirectPath)
    }
  }

  async onSubmit(result: IRegistrationResult) {
    this.loading = true
    this.tokenStorage.setItem('user', JSON.stringify(result.appUser))
    await new Promise(resolve => setTimeout(resolve, 4000))

    this.store.dispatch(new OnLogIn({ token: result.authToken, redirectPath: this.redirectPath, appUserId: result.appUser.id }))
    this.store.dispatch(new OnRegistration({ appUserId: result.appUser.id, queryParams: this.queryParams }))
    this.loading = false
    this.logger.log('submitted password create', {
      queryParams: this.queryParams,
      result,
      redirectPath: this.redirectPath,
      primary: this.primary,
      urlTree: this.urlTree,
    })
  }

  onError(error: any): void {
    // Send Notification
    // Get translations for the title and message
    let title = '',
      message = ''
    this.translate.get('An error occurred').subscribe((res: string) => {
      title = res
    })
    this.translate.get('We were unable to process this registration.').subscribe((res: string) => {
      message = res
    })
    this.notificationsService.popNotificationMessage(new E11NotificationMessage(title, message, 'danger', false, ''))
    // End Notification
  }
  navigateToLogin() {
    this.router.navigate(['/auth/login', this.redirectPath], { queryParams: this.queryParams, queryParamsHandling: 'merge' })
  }
}
