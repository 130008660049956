<div class="e11-text-center e11-text-sm e11-text-gray-500">
  <div>{{ 'Upload your own video.' | translate }}</div>
  <p>
    {{ (' Video may be up to ' | translate) + maxDuration + (' seconds and in .mp4 or .webm format.' | translate) }}
  </p>
</div>
<div class="e11-flex e11-justify-center">
  <input #videoInput class="e11-hidden" type="file" accept=".mp4, .webm" (change)="fileInputChange($event)" />
  <e11-button [value]="'Browse' | translate" [loading]="loading" [loadingText]="'Processing' | translate" (click)="videoInput.click()"></e11-button>
</div>
