import { Component, Input, Output, EventEmitter } from '@angular/core'
import { ICandidateVideo } from '../../../model/candidate-video.model'
import { Size } from '@engineering11/ui-lib/e11-ui-config'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-video-card',
  templateUrl: './ui-video-card.component.html',
})
export class UiVideoCardComponent {
  @Input()
  video!: ICandidateVideo

  @Input()
  isActive: boolean = false

  @Input() isList: boolean = false
  @Input() size: Size = 'md' // Based on context (where it is displayed) // xs, sm, md, lg, xl

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  onClick: EventEmitter<ICandidateVideo> = new EventEmitter()
}
