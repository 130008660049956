// Framework
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

// Platform specific
import { ConfigService } from '@candidate/app/services/config.service'

@Component({
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class AuthSuccessComponent implements OnInit {
  themeCurrent: ''

  constructor(private configService: ConfigService, private router: Router) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {}

  gotoLogin(): void {
    this.router.navigate(['/auth/login'])
  }
}
