<div
  class="e11-profile-card e11-text-center e11-p-4 hover:e11-bg-skin-primary-accent/10 e11-cursor-pointer e11-border e11-border-skin-app-borders e11-rounded-lg"
  [ngClass]="{
    'e11-profile-card-selected e11-bg-skin-primary-accent/10 e11-border-skin-primary-accent': selectedResumeId === resume.id
  }"
>
  <div class="e11-w-full e11-flex e11-justify-center">
    <e11-avatar [imageUrl]="resume.photo?.url" [size]="'xl'" [hasRing]="true" [hasBorder]="false"></e11-avatar>
  </div>
  <div class="e11-w-full e11-text-center">
    <div class="e11-mt-4 e11-line-clamp-1">{{ resume.title }}</div>
  </div>
  <div class="e11-w-full e11-text-center e11-text-xs e11-text-skin-light">
    {{ resume.__updatedAt | date }}
  </div>
</div>
