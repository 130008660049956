import { Injectable } from '@angular/core'
import { ICandidateResume } from '@candidate/app/models/candidate-resume.model'
import { FilesService, FileValidationService } from '@engineering11/files-web'
import { formatBytes } from '@engineering11/utility'
import { Timestamp } from '@engineering11/web-api-firebase'
import { CandidateResumeService } from './candidate-resume.service'

export const RESUME_SIZE_MAX_BYTES = 6 * 1000 * 1000 // 6 MB - not MiB
export const MAX_RESUME_SIZE_STR = formatBytes(RESUME_SIZE_MAX_BYTES)
export const RESUME_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

@Injectable({ providedIn: 'root' })
export class ResumeParsingService {
  constructor(
    private filesService: FilesService,
    private fileValidationService: FileValidationService,
    private candidateResumeService: CandidateResumeService
  ) {}

  async parseAndCreateProfile(file: File, customerKey: string, userId: string): Promise<Timestamp<ICandidateResume>> {
    this.fileValidationService.validate(file, { size: RESUME_SIZE_MAX_BYTES, types: RESUME_FILE_TYPES })
    const receipt = await this.filesService.upload(file, customerKey, userId, false)
    const resume = await this.candidateResumeService.parseAndSave(receipt, file.lastModified).toPromise()
    return resume!
  }

  async parse(file: File, customerKey: string, userId: string): Promise<Timestamp<ICandidateResume>> {
    this.fileValidationService.validate(file, { size: RESUME_SIZE_MAX_BYTES, types: RESUME_FILE_TYPES })
    const receipt = await this.filesService.upload(file, customerKey, userId, false)
    const resume = await this.candidateResumeService.parse(receipt, file.lastModified).toPromise()
    return resume!
  }
}
