// Framework
import { Component, OnInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms'

// Platform specific
import { ConfigService } from '@candidate/app/services/config.service'
import { FormCustomValidationService } from 'shared-lib'
import { ERROR_HANDLING } from '../../../../../app.constants'

// Analytics
import { AnalyticsService } from '@engineering11/web-api-firebase'
import { Platform, Module, View, AnalyticsEvents, UserAction } from '@candidate/app/analytics.constants'

@Component({
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class AuthRecoveryPasswordResetComponent implements OnInit {
  themeCurrent: 'default'
  status = false

  // Registration form(s):
  formPassword = new UntypedFormGroup({})
  formPasswordSubmitted = false

  passwordFC: UntypedFormControl = new UntypedFormControl({})
  passwordConfirmFC: UntypedFormControl = new UntypedFormControl({})

  // error handling
  passwordMinLength = { value: ERROR_HANDLING.PASSWORD_MIN_LENGTH }

  constructor(
    private configService: ConfigService,
    private formBuilder: UntypedFormBuilder,
    private customValidator: FormCustomValidationService,
    private analyticsService: AnalyticsService
  ) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {
    this.createFormPassword()
  }

  createFormPassword(): void {
    this.formPassword = this.formBuilder.group(
      {
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(ERROR_HANDLING.PASSWORD_MIN_LENGTH),
          this.customValidator.hasNumberValidator(),
        ]),
        passwordConfirm: new UntypedFormControl('', [Validators.required]),
      },
      {
        validator: [this.customValidator.emailMatch('email', 'emailConfirm'), this.customValidator.passwordMatch('password', 'passwordConfirm')],
      }
    )
    this.passwordFC = this.formPassword.get('password') as UntypedFormControl
    this.passwordConfirmFC = this.formPassword.get('passwordConfirm') as UntypedFormControl
  }

  onSubmitFormPassword(): void {
    this.formPasswordSubmitted = true
    if (this.formPassword.valid) {
      this.run(this.status)
      // this.userRegistering.password = this.formPassword.value.password;
      // write to service object so data can be recovered in case we need it
      this.sendAnalyticsEvent()
    }
  }

  run(status: boolean): void {
    this.status = !this.status
    //
  }

  sendAnalyticsEvent() {
    this.analyticsService.logEvent(Platform.dev, {
      module: Module.authentication,
      view: View.authenticationPasswordReset,
      analyticsEvent: AnalyticsEvents.passwordReset,
      userAction: UserAction.formSubmit,
    })
  }
}
