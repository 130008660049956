<ui-modal #modalBrowserDetection modalWidth="medium" [showHeader]="false" [showCloser]="false" [showFooter]="false">
  <div modal-content>
    <h3 class="e11-mb-8 e11-flex e11-items-center e11-justify-center">
      <span class="material-icons-outlined e11-mr-4"> info </span>{{ 'Notice!' | translate }}
    </h3>
    <h5 class="e11-mb-4 e11-flex e11-items-center e11-justify-center">
      {{ 'Safari does not currently offer full support
      for video recording functionality.' | translate }}
    </h5>
    <p class="e11-text-center">
      {{ 'We recommend using a different browser, such as Google Chrome, to properly film your video.' | translate }}
    </p>
    <p class="e11-text-center">{{ 'We apologize for this potential inconvenience.' | translate }}</p>
    <div class="spacer-2rem"></div>
    <div class="e11-flex e11-items-center e11-justify-center">
      <e11-button color="secondary" [style]="'pill outlined'" value="{{ 'Close' | translate }}" (click)="closeModal(false)" [buttonGroup]="true">
      </e11-button>
      <e11-button color="primary" value="{{ 'Continue' | translate }}" (click)="closeModal(true)"> </e11-button>
    </div>
  </div>
</ui-modal>
