// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Routing
import { HomeRoutingModule } from './home.routing'

// Modules
import { UiComponentLibraryModule } from '../_component-library/component-library.module'

// Components
import { HomeComponent } from './home.component'
import { SharedModule } from '../_shared/shared.module'
import { AboutComponent } from './about/about.component'

@NgModule({
  declarations: [HomeComponent, AboutComponent],
  imports: [CommonModule, SharedModule, HomeRoutingModule, UiComponentLibraryModule, FormsModule, ReactiveFormsModule],
  exports: [],
  providers: [],
})
export class HomeModule {}
