import { Component, Input, OnInit } from '@angular/core'
import { ICandidateJobVM } from 'shared-lib'

type ICandidateJobVMKeys = keyof ICandidateJobVM

@Component({
  selector: 'job-detail-display',
  templateUrl: './job-detail-display.component.html',
  styleUrls: ['./job-detail-display.component.scss'],
})
export class JobDetailDisplayComponent implements OnInit {
  @Input() jobDetail!: ICandidateJobVM

  modelKeys: ICandidateJobVMKeys[] = [
    'educationLevel',
    'department',
    'schedule',
    'travelPercentage',
    'compensationLow',
    'compensationHigh',
    'benefits',
  ]

  translationModelKeys: any = {
    educationLevel: 'Education Level',
    department: 'Department',
    schedule: 'Schedule',
    travelPercentage: 'Travel Percentage',
    compensationLow: 'Compensation Low',
    compensationHigh: 'Compensation High',
    benefits: 'Benefits',
  }

  constructor() {}

  ngOnInit(): void {}
}
