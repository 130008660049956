import { Component, Input, OnInit } from '@angular/core'
import { SelectedDay } from '../../model/enum'

@Component({
  selector: 'job-schedule',
  templateUrl: './job-schedule.component.html',
  styleUrls: ['./job-schedule.component.scss'],
})
export class JobScheduleComponent implements OnInit {
  @Input() selectedDays?: SelectedDay[] = []

  constructor() {}

  ngOnInit(): void {}

  checkDataType(data: any, type: string) {
    return typeof data === type
  }

  convertMilitaryStringToDate(date: string) {
    if (date === null || date.length <= 4) {
      return 'Not Specified'
    }

    let hours: number = parseInt(date.substring(0, 2))

    let AMPM = 'AM'

    if (hours && hours === 12) {
      AMPM = 'PM'
    } else if (hours && hours > 12) {
      hours = hours - 12
      AMPM = 'PM'
    } else if (hours === 0) {
      hours = 12
      AMPM = 'AM'
    }
    return hours.toString() + date.substring(2).toString() + AMPM
  }
}
