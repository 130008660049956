<lib-version-checker [environment]="_environment"></lib-version-checker>

<router-outlet></router-outlet>

<ui-errors (close)="close($event)"></ui-errors>
<e11-side-over></e11-side-over>
<e11-notifications></e11-notifications>
<e11-modal-global></e11-modal-global>
<e11-loader-global></e11-loader-global>

<app-toast></app-toast>
