<ul
  role="list"
  class="e11-h-full e11-px-4 e11-grid e11-grid-cols-2 e11-gap-x-4 e11-gap-y-8 sm:e11-grid-cols-3 sm:e11-gap-x-6 lg:e11-grid-cols-4 xl:e11-gap-x-8"
>
  <li *ngFor="let item of count | range">
    <div
      class="e11-group e11-block e11-w-full e11-aspect-w-10 e11-aspect-h-7 e11-rounded-lg e11-bg-gray-100 focus-within:e11-ring-2 focus-within:e11-ring-offset-2 focus-within:e11-ring-offset-gray-100 focus-within:e11-ring-skin-primary e11-overflow-hidden e11-animate-pulse"
    >
      <div class="e11-rounded-lg e11-bg-slate-200 e11-h-40 e11-w-60"></div>
      <button type="button" class="e11-absolute e11-inset-0 focus:e11-outline-none"></button>
    </div>
    <div class="e11-mt-2 e11-h-3 e11-bg-slate-200 e11-rounded e11-w-3/4"></div>
    <div class="e11-mt-1 e11-h-2 e11-bg-slate-200 e11-rounded e11-w-1/3"></div>
  </li>
</ul>
