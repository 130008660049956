<div class="component application-history">
  <div class="e11-w-full e11-mb-8">
    <e11-panel [hasBorder]="true" [hasShadow]="false">
      <ng-container *ngIf="applicationHistory; else LoadingApplicationHistory">
        <div class="empty-state text-center" *ngIf="applicationHistory.length === 0">
          <h4 class="text-grey italic">{{ 'No Application History' | translate }}</h4>
          <p class="text-grey">{{ 'There appears to be no application history for this application.' | translate }}</p>
        </div>

        <div class="" *ngIf="applicationHistory.length > 0">
          <h4 class="e11-text-skin-complimentary">{{ 'Application History' | translate }}</h4>

          <e11-divider [margin]="1"></e11-divider>

          <div *ngFor="let historyItem of applicationHistory" class="e11-mb-4">
            <p class="e11-mb-0">{{ historyItem.text }}</p>
            <p class="date e11-text-skin-light e11-text-sm">{{ historyItem.occurredAt | date : 'MMM d, y, h:mm a' }}</p>
          </div>
        </div>
      </ng-container>
      <ng-template #LoadingApplicationHistory>
        <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary'"></e11-loader-dots>
      </ng-template>
    </e11-panel>
  </div>
</div>
