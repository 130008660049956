import { Injectable } from '@angular/core'
import { ICandidateUpdateModel } from '@candidate/app/models/candidate-job-update.model'
import { environment } from '@candidate/environments/environment'
import { RestApiClient } from '@engineering11/web-api-rest'
import { NGXLogger } from 'ngx-logger'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IProcessedJobApplication, TokenStorage } from 'shared-lib'
import { CandidateApplicationRepository } from './candidate-application.repository'

@Injectable({ providedIn: 'root' })
export class CandidateApplicationService {
  private restApiClient: RestApiClient
  constructor(tokenStorage: TokenStorage, public repository: CandidateApplicationRepository, private logger: NGXLogger) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  updateCandidateApplication(applicationId: string, updates: ICandidateUpdateModel) {
    // const applicationId = this.buildJobApplicationId(jobPostId, userId)
    this.logger.log('Updating application with: ', { updates })
    return this.restApiClient.put(`candidate-job-application/${applicationId}`, updates)
  }

  getCandidateApplication(jobPostId: string, userId: string): Observable<IProcessedJobApplication | null> {
    const id = this.buildJobApplicationId(jobPostId, userId)
    return this.repository.get(id).pipe(map(item => (item ? item : null)))
  }

  buildJobApplicationId(jobPostId: string, userId: string) {
    return `${jobPostId}_${userId}`
  }
}
