import { Component, Input, OnInit } from '@angular/core'
import { ICandidateResume } from '@candidate/app/models/candidate-resume.model'
import { Timestamp } from '@engineering11/web-api-firebase'

@Component({
  selector: 'profile-tab',
  templateUrl: './profile-tab.component.html',
  styleUrls: ['./profile-tab.component.scss'],
})
export class ProfileTabComponent implements OnInit {
  @Input() resume!: Timestamp<ICandidateResume>
  @Input() selected: boolean = false

  constructor() {}

  ngOnInit(): void {}
}
