// Framework
import { Injectable } from '@angular/core'

// Other - third party
import { ViewportService as E11ViewportService } from '@engineering11/web-utilities'

// TODO remove and fix compile errors
@Injectable({
  providedIn: 'root',
})
export class ViewportService extends E11ViewportService {}
