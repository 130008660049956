import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { AuthRestService, IAuthService } from '@engineering11/auth-web'
import { e11Error, E11ErrorHandlerService, ERROR_TRACKER_TOKEN, IErrorTracker } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { filter, map, takeUntil, withLatestFrom, distinctUntilChanged } from 'rxjs/operators'
import { getCurrentTokenDecoded, getCurrentUser, ICnectUser, isEmailVerified, NotificationTranslateService } from 'shared-lib'
import { ToastCTA, ToastService } from './toast.service'
import { isNotNil, isFalse, isTrue } from '@engineering11/utility'
import { Observable, Subject } from 'rxjs'

export enum ToastType {
  EmailVerification = 'EmailVerification',
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  currentEmail?: string
  destroy$: Subject<boolean> = new Subject<boolean>()
  currentToast?: ToastType
  constructor(
    private notificationService: NotificationTranslateService,
    private errorHandler: E11ErrorHandlerService,
    private logger: NGXLogger,
    private authRest: AuthRestService,
    private toastService: ToastService,
    private store: Store
  ) {}
  ngOnInit(): void {
    this.initEmailVerificationToast()
    this.listenCurrentEmail()
  }
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ctaToast(event: string): void {
    const cta = event as ToastCTA
    switch (cta) {
      case 'resendVerificationEmail':
        this.handleResendVerificationEmail()
        break
      default:
        this.errorHandler.handleE11Error(e11Error({ title: 'Unsupported toast event', type: 'unsupported-toast', additionalData: { event } }))
    }
  }

  private listenCurrentEmail() {
    this.store
      .select(getCurrentUser)
      .pipe(
        filter(isNotNil),
        map(user => user.email),
        distinctUntilChanged<string>()
      )
      .subscribe(email => (this.currentEmail = email))
  }

  private async handleResendVerificationEmail() {
    if (!this.currentEmail) {
      this.errorHandler.handleE11Error(e11Error({ title: 'Cannot resend verification email', type: 'email-missing' }))
    }
    try {
      await this.authRest.resendVerificationEmailWithCustomLink(this.currentEmail!)
      this.notificationService.popNotificationMessage(
        'Email Resent!',
        "Please check your inbox for an email from us. Be sure to check junk & spam mail if it doesn't show up!",
        'success',
        true
      )
    } catch (e: any) {
      this.notificationService.popNotificationMessage(
        'Could not resend',
        'Please wait a few minutes and try again next time see the prompt',
        'error',
        true
      )
      this.errorHandler.handleError(e)
    }
  }

  private initEmailVerificationToast() {
    this.store.pipe(takeUntil(this.destroy$), isEmailVerified, filter(isNotNil), distinctUntilChanged()).subscribe(isEmailVerified => {
      if (!isEmailVerified) {
        this.currentToast = ToastType.EmailVerification
        this.toastService.verificationEmailToast()
        this.logger.log({ isEmailVerified })
      } else if (this.currentToast === ToastType.EmailVerification) {
        this.toastService.closeToast()
      }
    })
  }
}
