<div class="e11-relative e11-text-center">
  <e11-button
    [size]="'sm'"
    [style]="'pill'"
    [color]="'primary-accent'"
    value="{{ 'Refer a friend to cnect!!' | translate }}"
    (click)="copyReferralLink()"
  >
  </e11-button>
  <div *ngIf="showCopiedMessage" class="e11-text-xs e11-text-white e11-absolute e11--bottom-5 e11-left-0 e11-right-0 e11-m-auto">
    {{ 'Link copied to clipboard!' | translate }}
  </div>
</div>
