// Framework
import { Component, EventEmitter, Output } from '@angular/core'
import { CnectFeatures, getFeatures } from 'shared-lib'

// Third party
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { AnimationFading } from 'projects/shared-lib/src/lib/e11-animations'
import { CandidateNavigationInfoContentService, InfoContentRoutes } from '@candidate/app/services/candidate-navigation-info-content.service'

@Component({
  selector: 'info-content-header',
  templateUrl: './info-content-header.component.html',
  styleUrls: ['./info-content-header.component.scss'],
  animations: [AnimationFading.shrinkFadeOut, AnimationFading.scaleUp],
})
export class InfoContentHeaderComponent {
  @Output() closeInfoContent = new EventEmitter<boolean>()
  features$: Observable<CnectFeatures | undefined> = this.store.select(getFeatures)
  features?: CnectFeatures
  destroy$: Subject<boolean> = new Subject<boolean>()

  infoContentRoutes = InfoContentRoutes

  constructor(private store: Store, private navService: CandidateNavigationInfoContentService) {
    this.features$.pipe(takeUntil(this.destroy$)).subscribe(features => (this.features = features))
  }

  navigateTo(route: string) {
    this.navService.navigateTo(route)
  }
}
