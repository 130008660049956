<ng-container *ngrxLet="features$ as features">
  <div class="mobile-drawer-container e11-w-full e11-fixed e11-top-[87px] e11-bottom-0 e11-z-20" [ngClass]="{ 'side-drawer-active': isOpen }">
    <div
      class="mobile-drawer e11-top-0 e11-w-[90vw] e11-fixed e11-top-[87px] e11-bottom-0 e11-z-20 e11-divide-y e11-divide-gray-200 e11-flex e11-flex-col e11-shadow-xl"
    >
      <div class="e11-bg-skin-primary e11-text-white e11-flex-1 e11-h-0 e11-overflow-y-auto e11-overscroll-none e11-pt-5">
        <div *ngIf="selectedTab === 'home'" class="e11-px-4">
          <h5 class="e11-mb-5 e11-font-primary-demibold e11-text-md">{{ 'Home' | translate }}</h5>

          <ul class="e11-flex e11-flex-col e11-pl-3">
            <li
              class="e11-flex e11-h-12 e11-items-center e11-gap-2 e11-cursor-pointer"
              *ngFor="let item of navigationItems"
              (click)="routeToPath(item.route!)"
            >
              <span class="material-icons">{{ item.icon }}</span> {{ item.name | translate }}
            </li>

            <!-- My Network -->
            <ul *ngIf="features?.network">
              <li class="e11-flex e11-h-12 e11-justify-between e11-items-center e11-cursor-pointer" (click)="toggleDropdownMenu('network')">
                <div class="e11-flex e11-items-center e11-gap-2">
                  <span class="material-icons material-icons-outlined">diversity_3</span>
                  <span class="e11-font-medium e11-text-md">{{ 'My Network' | translate }}</span>
                </div>
                <span
                  class="material-icons-outlined e11-text-skin-extra-light nav-icon"
                  [ngClass]="{ 'nav-icon-active': selectedDropdownMenu === 'network' }"
                  >expand_more</span
                >
              </li>
              <ul class="e11-px-2" *ngIf="selectedDropdownMenu === 'network'">
                <li
                  class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/30 e11-rounded-md"
                  (click)="networkConnections()"
                  [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                >
                  Cnections
                </li>
                <li
                  class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer e11-rounded-md"
                  (click)="networkInvitations()"
                  [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                >
                  {{ 'Invitations' | translate }}
                </li>
              </ul>
            </ul>

            <!-- Communities -->
            <ul *ngIf="features?.communities">
              <li class="e11-flex e11-h-12 e11-justify-between e11-items-center e11-cursor-pointer" (click)="toggleDropdownMenu('community')">
                <div class="e11-flex e11-items-center e11-gap-2">
                  <span class="material-icons material-icons-outlined">forum</span>
                  <span class="e11-font-medium e11-text-md">{{ 'Communities' | translate }}</span>
                </div>
                <span
                  class="material-icons-outlined e11-text-skin-extra-light nav-icon"
                  [ngClass]="{ 'nav-icon-active': selectedDropdownMenu === 'community' }"
                  >expand_more</span
                >
              </li>
              <ul class="e11-px-2 e11-pt-2" *ngIf="selectedDropdownMenu === 'community'">
                <li
                  class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/30 e11-rounded-md"
                  (click)="communitiesPage(communitiesPageEnum.List)"
                >
                  {{ 'My Communities' | translate }}
                </li>
                <li
                  class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer e11-rounded-md"
                  (click)="communitiesPage(communitiesPageEnum.Search)"
                >
                  {{ 'Search' | translate }}
                </li>
              </ul>
            </ul>
          </ul>
        </div>

        <div *ngIf="selectedTab === 'messages'" class="e11-px-4">
          <!-- Messages -->
          <div *ngIf="features?.messaging">
            <h5 class="e11-mb-5 e11-font-primary-demibold e11-text-md">{{ 'Messages' | translate }}</h5>

            <div class="e11-px-2">
              <div class="e11-w-full e11-mt-2 e11-mb-4 e11-rounded-lg e11-bg-skin-primary-accent/20 e11-pt-4">
                <p class="e11-font-medium e11-text-md e11-mb-0 e11-pl-2">{{ 'Job Related' | translate }}</p>
                <sdk-conversation-nav
                  [type]="conversationTypesEnum.JobApplicationDM"
                  [emptyStateSubtitle]="'This is where you discuss your job applications with employers'"
                  [darkMode]="true"
                ></sdk-conversation-nav>
              </div>

              <p class="e11-font-medium e11-text-md e11-mb-2 e11-pl-2">{{ 'Social' | translate }}</p>
              <sdk-conversation-nav [type]="conversationTypesEnum.SocialDm" [darkMode]="true"></sdk-conversation-nav>
            </div>
          </div>
        </div>
      </div>

      <div class="e11-text-skin-light e11-flex e11-justify-around e11-p-4 e11-flex-shrink-0 e11-bg-skin-primary">
        <div (click)="selectedTab = 'home'" class="e11-flex e11-flex-col e11-items-center" [ngClass]="{ 'e11-text-white': selectedTab === 'home' }">
          <span class="icon material-icons">home</span>
          <span>{{ 'Home' | translate }}</span>
        </div>
        <div
          *ngIf="features?.messaging"
          (click)="selectedTab = 'messages'"
          class="e11-relative e11-flex e11-flex-col e11-items-center"
          [ngClass]="{ 'e11-text-white': selectedTab === 'messages' }"
        >
          <span class="icon material-icons material-icons-outlined">chat</span>
          <span>{{ 'Messages' | translate }}</span>
          <e11-badge
            *ngIf="unreadConversationCount$ | async"
            [value]="(unreadConversationCount$ | async) ?? 0"
            [color]="'error'"
            [size]="'sm'"
            [posAdjustX]="'-32px'"
            [posAdjustY]="'-12px'"
            [limit]="UNREAD_LIMIT"
            [containerClassOverrides]="''"
          ></e11-badge>
        </div>
        <div (click)="close(true)" class="e11-flex e11-flex-col e11-items-center">
          <span class="icon material-icons material-icons-outlined">arrow_back_ios</span>
          <span>{{ 'Close' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
