import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { NGXLogger } from 'ngx-logger'
import { CandidateReferrals, FormSelectModel, FormSelectService, ICandidateJobVM, ICandidateResponses } from 'shared-lib'
import { ReplaceValues } from '@engineering11/types'
import { Subject } from 'rxjs'
import { debounceTime, takeUntil, tap } from 'rxjs/operators'
import { hasChanged } from '@engineering11/utility'
import { ApplicationPage } from '@candidate/app/modules/applications/views/apply/apply.component'

@Component({
  selector: 'candidate-question-responses',
  templateUrl: './candidate-question-responses.component.html',
  styleUrls: ['./candidate-question-responses.component.scss'],
})
export class CandidateQuestionResponsesComponent implements OnChanges, OnDestroy {
  @Input() currentResponses?: ICandidateResponses

  @Input() jobDetail: ICandidateJobVM | undefined

  @Input() loading = false

  @Output() newResponses = new EventEmitter<ICandidateResponses>()

  responsesFormGroup = new UntypedFormGroup({})

  showConfirmationDetails: boolean = false

  formValid$ = new Subject<boolean>()

  destroy$: Subject<boolean> = new Subject<boolean>()

  referralSourceList: FormSelectModel[] = []
  showReferralDetails: boolean = false
  selectDefaultReferral = 'cnect' //
  referrerDetailRequired: boolean = false

  currentCandidateResponses: ICandidateResponses | undefined
  enumApplicationPage = ApplicationPage
  constructor(private formBuilder: UntypedFormBuilder, private logger: NGXLogger, private formSelectService: FormSelectService) {
    this.referralSourceList = this.formSelectService.getCandidateReferralList()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { jobDetail, currentResponses } = changes
    // Prevents form from being re-initialised with data that's just updated the responses, possibly overwriting what the candidate did between the last update and now.
    if (
      (jobDetail &&
        hasChanged<ICandidateJobVM>('jobId', {
          before: jobDetail.previousValue,
          after: jobDetail.currentValue,
        })) ||
      (currentResponses && !currentResponses.previousValue && currentResponses.currentValue)
    ) {
      this.initFormGroup(currentResponses?.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
    this.formValid$.complete()
  }

  get f() {
    return this.responsesFormGroup.controls as {
      [key: string]: UntypedFormControl
    }
  }

  get isFormValid() {
    return this.responsesFormGroup.valid
  }

  private initFormGroup(currentResponses?: ICandidateResponses) {
    this.logger.log('initting form group with ', currentResponses)

    // if (currentResponses?.referralSource === CandidateReferrals.Referral || currentResponses?.referralSource === CandidateReferrals.Other) {
    //   this.showReferralDetails = true
    // } else {
    //   this.showReferralDetails = false
    // }
    const maxYearsExperience = 123
    const maxMonths = 11
    const minExperience = 0
    const formInput: ReplaceValues<ICandidateResponses, UntypedFormControl> = {
      // referralSource: new FormControl(currentResponses?.referralSource),
      // referralDetail: new FormControl(currentResponses?.referralDetail),
      hoursConfirmed: new UntypedFormControl(currentResponses?.hoursConfirmed),
      travelConfirmed: new UntypedFormControl(currentResponses?.travelConfirmed),
      // relocationConfirmed: new FormControl(currentResponses?.relocationConfirmed, [Validators.required]),
      legalWorkAuthorization: new UntypedFormControl(currentResponses?.legalWorkAuthorization, [Validators.required]),
      visaSponsorshipRequired: new UntypedFormControl(currentResponses?.visaSponsorshipRequired, [Validators.required]),
      yearsRelevantExperience: new UntypedFormControl(currentResponses?.yearsRelevantExperience, [
        Validators.required,
        Validators.min(minExperience),
        Validators.max(maxYearsExperience),
      ]),
      monthsRelevantExperience: new UntypedFormControl(currentResponses?.monthsRelevantExperience, [
        Validators.required,
        Validators.min(minExperience),
        Validators.max(maxMonths),
      ]),
      isDefaultApplication: new UntypedFormControl(currentResponses?.isDefaultApplication),
    }

    this.responsesFormGroup = this.formBuilder.group(formInput)
    this.formValid$.next(this.responsesFormGroup.valid)

    this.responsesFormGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(changes => {
          // if (changes.referralSource === CandidateReferrals.Referral || changes.referralSource === CandidateReferrals.Other) {
          //   this.showReferralDetails = true
          //   this.responsesFormGroup.controls['referralDetail'].setValidators([Validators.required])
          // } else {
          //   this.showReferralDetails = false
          //   this.responsesFormGroup.controls['referralDetail'].clearValidators()
          // }
        }),
        debounceTime(1000)
      )
      .subscribe(changes => {
        this.currentCandidateResponses = {
          ...changes,
          // referralSource: changes.referralSource || CandidateReferrals.Cnect,
          monthsRelevantExperience: parseInt(changes.monthsRelevantExperience || 0),
          yearsRelevantExperience: parseInt(changes.yearsRelevantExperience || 0),
        }
        this.responsesChanged(this.currentCandidateResponses as ICandidateResponses)
      })
    // this.selectDefaultReferral = currentResponses?.referralSource || 'cnect'
  }

  private responsesChanged(newResponses: ICandidateResponses) {
    this.formValid$.next(this.responsesFormGroup.valid)
    this.newResponses.emit(newResponses)
  }

  toggleConfirmationDetails() {
    // This could be handled in the template but putting here incase we need to expand on the functionality later
    this.showConfirmationDetails = !this.showConfirmationDetails
  }
}
