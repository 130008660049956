import { Injectable } from '@angular/core'
import { IApplicationReactivatedNotification } from '@candidate/app/models/app-notification.model'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class ApplicationReactivatedNotificationHandler implements INotificationHandler<IApplicationReactivatedNotification, INotificationView> {
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}
  toViewModel(notification: IApplicationReactivatedNotification): INotificationView {
    return {
      description: `The employer has reversed their decision and has moved your application back into consideration for the ${notification.jobTitle} job post.`,
      logoUrl: '',
      originalNotification: notification,
      title: 'Job Post - Application Reactivated',
      viewed: notification.viewed,
    }
  }
  onClick(notification: IApplicationReactivatedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.candidateHomeNavigationService.jobApplication(notification.jobId)
  }
  notificationType = 'application-reactivated'
}
