// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Routing
import { AuthenticationRoutingModule } from './authentication.routing'

// Modules
import { SharedModule } from '../_shared/shared.module'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Components
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthRecoveryFindAccountComponent } from './views/account-recovery/find-account/find-account.component'
import { AuthRecoveryVerificationCodeComponent } from './views/account-recovery/verification-code/verification-code.component'
import { AuthRecoveryPasswordResetComponent } from './views/account-recovery/password-reset/password-reset.component'
import { AuthEmailVerificationComponent } from './views/email-verification/email-verification.component'
import { AuthTermsOfUseComponent } from './views/terms/terms.component'
import { AuthSuccessComponent } from './views/success/success.component'
import { AuthModule, IAuthConfig } from '@engineering11/auth-web'
import { UserModule } from '@engineering11/user-web'
import { environment } from '@candidate/environments/environment'

const authConfig: IAuthConfig = {
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: environment.emailVerificationEmailTemplateId,
  passwordRecoveryEmailTemplateId: environment.passwordRecoveryEmailTemplateId,
  tenantId: environment.tenantId,
}
@NgModule({
  declarations: [
    AuthenticationComponent,
    AuthenticationLoginComponent,
    AuthRecoveryFindAccountComponent,
    AuthRecoveryVerificationCodeComponent,
    AuthRecoveryPasswordResetComponent,
    AuthEmailVerificationComponent,
    AuthTermsOfUseComponent,
    AuthSuccessComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationRoutingModule,
    AuthModule.forRoot(authConfig),
    UserModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [],
  providers: [],
})
export class AuthenticationModule {}
