<div *ngIf="jobDetail$ | async as jobDescription">
  <header class="" style="background-color: {{ employerTheme?.primary }}">
    <nav class="e11-max-w-7xl e11-mx-auto e11-px-4 sme11-:px-6 lg:e11-px-8" aria-label="Top">
      <div class="e11-w-full e11-py-6 e11-flex e11-items-center e11-justify-between lg:e11-border-none">
        <div class="e11-w-full e11-flex e11-items-center">
          <div>
            <img
              class="e11-float-left e11-h-10 e11-w-auto e11-mr-4"
              *ngIf="jobDescription.employerLogo"
              [src]="jobDescription.employerLogo"
              [alt]="jobDescription.employerName"
            />
          </div>
          <div class="e11-grow">
            <h3 class="" style="color: {{ employerTheme?.text }}">{{ jobDescription.employerName }}</h3>
            <h4 style="color: {{ employerTheme?.text }}">{{ jobDescription.jobTitle }}</h4>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <div class="e11-relative">
    <div class="e11-max-w-full e11-mx-auto">
      <div class="e11-relative e11-shadow-xl sm:e11-overflow-hidden">
        <div class="e11-absolute e11-inset-0">
          <img class="e11-h-full e11-w-full e11-object-cover" src="{{ employerHeroImage }}" alt="" />
          <div style="background-color: {{ employerTheme?.primary }}" class="e11-absolute e11-inset-0 e11-mix-blend-multiply"></div>
        </div>
        <div class="e11-max-w-7xl e11-mx-auto e11-relative e11-px-4 md:e11-py-16 sm:e11-px-6 e11-pt-4 e11-pb-12 lg:e11-py-16 lg:e11-px-8">
          <div class="e11-grid e11-grid-cols-1 lg:e11-grid-cols-2 e11-gap-0 md:e11-gap-8">
            <!-- Left / Top -->
            <e11-panel [hasBorder]="true" [hasShadow]="false" [bgOpacity]="95">
              <div *ngIf="jobDetail$ | async as jobDescription">
                <div class="e11-text-left">
                  <div class="e11-px-8 e11-pt-2" [ngClass]="{ 'e11-mb-8': isMobile }">
                    <h3 class="e11-mb-4">{{ "Choose how you'd like to apply. You can:" | translate }}</h3>
                    <p class="e11-mb-0 e11-font-primary-demibold">{{ '1. Upload your resume' | translate }}</p>
                    <p class="e11-ml-4">{{ 'Quickly and automatically build an application by uploading your resume.' | translate }}</p>
                    <p class="e11-mb-0 e11-font-primary-demibold">{{ '2. Continue without a resume' | translate }}</p>
                    <p class="e11-ml-4">{{ 'Fill out a blank application to use for this job.' | translate }}</p>
                    <p class="e11-mb-0 e11-font-primary-demibold">
                      {{ '3. Sign into Cnect' | translate }}
                    </p>
                    <p class="e11-ml-4">{{ 'Already have a cnect account? Sign in to jump right into an application for this job.' | translate }}</p>
                    <e11-button
                      [size]="'sm'"
                      [style]="'pill outlined'"
                      [value]="'Sign into Cnect' | translate"
                      [color]="'secondary'"
                      (click)="navigateToLogin()"
                    >
                    </e11-button>
                  </div>
                </div>
              </div>
            </e11-panel>

            <!-- Right / Bottom -->
            <e11-panel [hasBorder]="true" [hasShadow]="false" [bgOpacity]="95">
              <!-- Place at top for small viewports so it can be 'above the fold' -->
              <ng-container *ngTemplateOutlet="controls"></ng-container>
              <div
                class="register-apply-option e11-overflow-x-hidden e11-relative e11-rounded e11-mb-4"
                [ngClass]="{ 'is-mobile': isMobile, '': !isMobile }"
              >
                <div class="panels">
                  <div
                    class="panel e11-absolute e11-text-center"
                    [ngClass]="{ 'out-to-left': showRegistrationForm, 'in-from-left': !showRegistrationForm }"
                  >
                    <h3 class="e11-mb-4">{{ 'Use your resume' | translate }}</h3>
                    <p>{{ 'Quickly and automatically build an application by uploading your resume' | translate }}</p>

                    <!-- Drag And Drop -->
                    <sdk-file-drop
                      (newFile)="resumeFile = $event"
                      [size]="maxFileSize"
                      [allowedFileTypes]="resumeFileTypes"
                      [dropInstruction]="'Drag and drop your resume here' | translate"
                    ></sdk-file-drop>

                    <!-- Successfully uploaded resume -->
                    <h3 *ngIf="resumeFile" class="e11-mb-4">{{ 'Great! We will use your resume.' | translate }}</h3>
                    <div class="e11-w-full e11-border e11-border-skin-success e11-bg-skin-success/10 e11-p-4 e11-rounded" *ngIf="resumeFile">
                      <div class="e11-flex e11-items-center e11-justify-center e11-text-sm">
                        <div>
                          <span class="material-icons-outlined md-36 e11-text-skin-success"> check </span>
                        </div>
                        <div class="e11-grow e11-text-center">
                          <span class="e11-flex e11-items-center e11-line-clamp-1">
                            {{ resumeFile.name }}
                          </span>
                        </div>
                        <div>
                          <span
                            class="material-icons-outlined e11-ml-2 e11-cursor-pointer e11-text-skin-light hover:e11-text-skin-dark"
                            (click)="clearSelectedFile()"
                          >
                            cancel
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel e11-absolute" [ngClass]="{ 'out-to-right': !showRegistrationForm, 'in-from-right': showRegistrationForm }">
                    <h3 class="e11-text-center e11-mb-4">{{ 'Just a few more pieces of information...' | translate }}</h3>
                    <sdk-registration-consumer-single-step
                      submitButtonText="{{ 'Continue' | translate }}"
                      (onSubmit)="onSubmit($event)"
                      (termsClicked)="termsClicked()"
                    ></sdk-registration-consumer-single-step>
                  </div>
                </div>
              </div>
              <ng-container *ngTemplateOutlet="controls"></ng-container>
            </e11-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Template: controls -->
<ng-template #controls>
  <div class="e11-flex e11-mb-4">
    <div class="e11-flex e11-w-1/2 e11-items-center e11-cursor-pointer hover:e11-text-skin-dark">
      <span *ngIf="showRegistrationForm" class="e11-flex e11-items-center e11-cursor-pointer" (click)="switchShowRegistrationForm(false)">
        <span class="material-icons-outlined e11-mr-2 md-18"> west </span>
        {{ 'Resume Selection' }}
      </span>
    </div>

    <div class="e11-flex e11-w-1/2 e11-justify-end">
      <span *ngIf="!showRegistrationForm" class="e11-flex e11-items-center e11-cursor-pointer" (click)="switchShowRegistrationForm(true)">
        {{ resumeFile ? 'Next' : 'Continue without resume' }}
        <span class="material-icons-outlined e11-ml-2 md-18"> east </span>
      </span>
    </div>
  </div>
</ng-template>
