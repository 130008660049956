import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EmployerNotInterestedNotification } from '@candidate/app/models/app-notification.model'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class EmployerNotInterestedNotificationHandler implements INotificationHandler<EmployerNotInterestedNotification, INotificationView> {
  notificationType = 'employer-not-interested'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}

  toViewModel(notification: EmployerNotInterestedNotification): INotificationView {
    return {
      description: `The employer has indicated they will not be moving forward in the process with you for the ${notification.jobTitle} job post.`,
      title: 'Job Post - Application Declined',
      viewed: notification.viewed,
      originalNotification: notification,
      logoUrl: '',
    }
  }

  async onClick(notification: EmployerNotInterestedNotification): Promise<boolean> {
    if (!notification.viewed) {
      await this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.candidateHomeNavigationService.jobApplication(notification.jobId)
  }
}
