<div class="bg"></div>
<div class="view e11-min-h-full e11-flex e11-flex-col e11-justify-center e11-py-6 e11-px-6">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <div class="e11-w-full e11-flex e11-justify-center">
      <app-logo-blue [height]="'96px'" [resizable]="false"></app-logo-blue>
    </div>
    <h2 class="e11-text-center e11-text-3xl e11-font-extrabold">
      {{ 'Sign in to your account' | translate }}
    </h2>
    <p class="e11-mt-2 e11-text-center e11-text-sm e11-text-gray-600">
      {{ 'Not signed up?' | translate }}
      <a (click)="navigateToRegistration()" class="e11-cursor-pointer e11-font-medium e11-text-skin-secondary hover:e11-text-skin-secondary">
        {{ 'Create an account' | translate }}
      </a>
    </p>
  </div>

  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <e11-panel [hasBorder]="true" [hasShadow]="false" [bgOpacity]="90" [contentClassOverrides]="'e11-p-6'">
      <auth-login (onSubmit)="onSubmit($event)" (onError)="onError($event)" (onForgottenPassword)="onForgottenPassword()"></auth-login>
    </e11-panel>
  </div>
</div>
