import { Color } from '@engineering11/ui-lib/e11-ui-config'

export enum ChipType {
  Incomplete = 'Incomplete',
  UpdateRequested = 'Update Requested',
  DialogueAvailable = 'Dialogue Available',
  Withdrawn = 'Withdrawn',
  ApplicationDeclined = 'Application Declined',
  ClosedByEmployer = 'Closed By Employer',
}

export interface IApplicationChip {
  type: ChipType
  color: Color
}

export const typeToColor: { [key: string]: Color } = {
  [ChipType.Incomplete]: 'primary',
  [ChipType.UpdateRequested]: 'primary',
  [ChipType.DialogueAvailable]: 'primary',
  [ChipType.Withdrawn]: 'warning',
  [ChipType.ApplicationDeclined]: 'warning',
  [ChipType.ClosedByEmployer]: 'warning',
}

export type ApplicationChipsDict = { [jobPostId: string]: IApplicationChip[] }
