// Framework
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { UiAccordionModule } from '@engineering11/ui/ui-accordion'

// Platform specific
import { UiBadgeModule } from '@engineering11/ui/ui-badge'
import { UiButtonModule } from '@engineering11/ui/ui-button'
import { UiChartPercentageModule } from '@engineering11/ui/ui-chart-percentage'
import { UiDividerModule } from '@engineering11/ui/ui-divider'
import { UiInputModule } from '@engineering11/ui/ui-input'
import { UiInputCheckboxModule } from '@engineering11/ui/ui-input-checkbox'
import { UiInputRadioModule } from '@engineering11/ui/ui-input-radio'
import { UiLanguagesModule } from '@engineering11/ui/ui-languages'
import { UiLoaderModule } from '@engineering11/ui/ui-loader'
import { UiLoaderDotsModule } from '@engineering11/ui/ui-loader-dots'
import { UiLoaderLineModule } from '@engineering11/ui/ui-loader-line'
import { UiModalModule } from '@engineering11/ui/ui-modal'
import { UiPanelModule } from '@engineering11/ui/ui-panel'
import { UiPanelClassicModule } from '@engineering11/ui/ui-panel-classic'
import { UiSelectModule } from '@engineering11/ui/ui-select'
import { UiSelectNativeModule } from '@engineering11/ui/ui-select-native'
import { UiSelectorModule } from '@engineering11/ui/ui-selector'
import { UiSliderModule } from '@engineering11/ui/ui-slider'
import { UiSwitchModule } from '@engineering11/ui/ui-switch'
import { UiTagModule } from '@engineering11/ui/ui-tag'
import { UiTimelineModule } from '@engineering11/ui/ui-timeline'
import { UiViewBandModule } from '@engineering11/ui/ui-view-band'
import { UiNavHorizontalModule } from '@engineering11/ui/ui-nav-horizontal'
import { UiNavSideModule } from '@engineering11/ui/ui-nav-side'
import { UiTextareaModule } from '@engineering11/ui/ui-textarea'
import { UiDatatableModule } from '@engineering11/ui/ui-datatable'
import { UiSideDrawerModule } from '@engineering11/ui/ui-side-drawer'
// import { errorMessages } from '@candidate/app/config/error-messages'

import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11CardModule } from '@engineering11/ui-lib/e11-card'
import { E11ChipModule } from '@engineering11/ui-lib/e11-chip'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11SideOverModule } from '@engineering11/ui-lib/e11-side-over'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { E11ImageModule } from '@engineering11/ui-lib/e11-image'
import { E11SidesheetModule } from '@engineering11/ui-lib/e11-sidesheet'
import { E11TextEditorModule } from '@engineering11/ui-lib/e11-text-editor'
import { E11ReadMoreModule } from '@engineering11/ui-lib/e11-read-more'
import { E11BackdropModule } from '@engineering11/ui-lib/e11-backdrop'
import { E11PhoneNumberModule } from '@engineering11/ui-lib/e11-phone-number'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { E11CountdownModule } from '@engineering11/ui-lib/e11-countdown'
import { E11LoaderModule } from '@engineering11/ui-lib/e11-loader'
import { E11LoaderSpinnerModule } from '@engineering11/ui-lib/e11-loader-spinner'
import { E11AlertModule } from '@engineering11/ui-lib/e11-alert'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'
import { E11TitleBarModule } from '@engineering11/ui-lib/e11-title-bar'
import { E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { E11SelectModule } from '@engineering11/ui-lib/e11-select'
import { E11ErrorMessagesModule } from '@engineering11/ui-lib/e11-error-messages'
import { E11NavLeftModule } from '@engineering11/ui-lib/e11-nav-left'
import { E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { E11TabsOnlyModule } from '@engineering11/ui-lib/e11-tabs-only'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { E11DirectivesModule } from '@engineering11/ui-lib/e11-directives'
import { E11PopoverModule } from '@engineering11/ui-lib/e11-popover'
import { E11AvatarModule } from '@engineering11/ui-lib/e11-avatar'
import { E11IndicatorModule } from '@engineering11/ui-lib/e11-indicator'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { E11InfotipModule } from '@engineering11/ui-lib/e11-infotip'
import { E11InputCheckboxModule } from '@engineering11/ui-lib/e11-input-checkbox'

const ImportExportModules = [
  // New UI lib modules
  E11AvatarModule,
  E11BackdropModule,
  E11BadgeModule,
  E11ButtonModule,
  E11CardModule,
  E11ChipModule,
  E11DividerModule,
  E11EmptyStateModule,
  E11LoaderDotsModule,
  E11LoaderSpinnerModule,
  E11LoaderModule,
  E11ProfilePicModule,
  E11RadioInputModule,
  E11InputModule,
  E11TooltipModule,
  E11PanelModule,
  E11TitleBarModule,
  E11PhoneNumberModule,
  E11SideOverModule,
  E11ConfirmationModule,
  E11ImageModule,
  E11ReadMoreModule,
  E11SidesheetModule,
  E11TextEditorModule,
  E11CountdownModule,
  E11AlertModule,
  E11TitleBarModule,
  E11ModalModule,
  E11ErrorMessagesModule,
  E11InputErrorsModule,
  E11SelectModule,
  E11NavLeftModule,
  E11SwitchModule,
  E11TabsModule,
  E11TabsOnlyModule,
  E11DirectivesModule,
  E11PopoverModule,
  E11IndicatorModule,
  E11TextareaModule,
  E11InfotipModule,
  E11InputCheckboxModule,

  // Old UI lib modules
  UiAccordionModule,
  UiBadgeModule,
  UiButtonModule,
  UiChartPercentageModule,
  UiDividerModule,
  UiInputModule,
  UiInputCheckboxModule,
  UiInputRadioModule,
  UiLanguagesModule,
  UiModalModule,
  UiPanelModule,
  UiPanelClassicModule,
  UiSelectModule,
  UiSelectorModule,
  UiSelectNativeModule,
  UiSliderModule,
  UiSwitchModule,
  UiTagModule,
  UiLoaderModule,
  UiLoaderLineModule,
  UiLoaderDotsModule,
  UiTimelineModule,
  UiViewBandModule,
  UiNavHorizontalModule,
  UiNavSideModule,
  UiTextareaModule,
  UiDatatableModule,
  UiSideDrawerModule,
  // REMEMBER: Toast and Notifications are root level
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules],
  exports: [...ImportExportModules],
})
export class UiComponentLibraryModule {}
