import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'empty-avatar',
  templateUrl: './empty-avatar.component.html',
  styleUrls: ['./empty-avatar.component.scss'],
})
export class EmptyAvatarComponent implements OnInit {
  @Input() name!: string
  @Input() size?: string = 'small'

  ngOnInit() {
    this.name = this.getDisplayNameFirstLetter()
  }

  getDisplayName() {
    return this.name.split(' ')[0]
  }

  getDisplayNameFirstLetter() {
    return this.name.slice(0, 1)
  }
}
