import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AnalyticsEvents, Module, Platform, UserAction, View } from '@candidate/app/analytics.constants'
import { AnalyticsService } from '@engineering11/web-api-firebase'

@Component({
  templateUrl: './find-account.component.html',
  styleUrls: ['./find-account.component.scss'],
})
export class AuthRecoveryFindAccountComponent implements OnInit {
  constructor(private analyticsService: AnalyticsService, private router: Router) {}

  ngOnInit(): void {}

  sendAnalyticsEvent() {
    this.analyticsService.logEvent(Platform.dev, {
      module: Module.authentication,
      view: View.authenticationEmailVerification,
      analyticsEvent: AnalyticsEvents.accountRecovery,
      userAction: UserAction.formSubmit,
    })
  }

  continueToLogin() {
    this.router.navigate(['profile'])
  }
}
