// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { UserModule } from '@engineering11/user-web'
// Routing
import { PublicRoutingModule } from './public.routing'

// Modules
import { UiComponentLibraryModule } from '../_component-library/component-library.module'

// Components
import { PublicComponent } from './public.component'
import { SharedModule } from '../_shared/shared.module'
import { PublicTermsComponent } from './views/terms/terms.component'
import { PublicPrivacyComponent } from './views/privacy/privacy.component'
import { ReactiveComponentModule } from '@ngrx/component'

@NgModule({
  declarations: [PublicComponent, PublicTermsComponent, PublicPrivacyComponent],
  imports: [
    CommonModule,
    SharedModule,
    PublicRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    UserModule,
    ReactiveComponentModule,
  ],
  exports: [],
  providers: [],
})
export class PublicModule {}
