import { Component, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { CnectFeatures, ConversationTypes, getFeatures } from 'shared-lib'
import { Store } from '@ngrx/store'
import { INavigationItem } from '@engineering11/ui-lib/e11-nav-left'
import { CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'
import { CommunitiesMainPage, CommunityNavigationService } from '@engineering11/community-web'
import { NavigationEnd, Router } from '@angular/router'
import { AnimationFading } from '@engineering11/ui-lib/e11-animations'
import { takeUntil } from 'rxjs/operators'
import { UiScrollLockingService } from '@engineering11/web-utilities'
import { ConversationStore } from '@engineering11/messaging-web'

type DropdownMenu = 'community' | 'network'

type Tabs = 'home' | 'messages'

@Component({
  selector: 'app-mobile-side-drawer-menu',
  templateUrl: './mobile-side-drawer-menu.component.html',
  styleUrls: ['./mobile-side-drawer-menu.component.scss'],
})
export class MobileSideDrawerMenuComponent implements OnInit, OnDestroy {
  features$: Observable<CnectFeatures | undefined> = this.store.select(getFeatures)
  conversationTypesEnum = ConversationTypes
  mobileSideDrawerToggledState$ = this.candidateNavigationService.mobileSideDrawerToggledState$
  communitiesPageEnum = CommunitiesMainPage
  unreadConversationCount$ = this.conversationStore.getUnreadConversationCount
  UNREAD_LIMIT = 9

  selectedDropdownMenu: DropdownMenu | null = null

  selectedTab: Tabs = 'home'

  navigationItems: INavigationItem[] = []

  isOpen = false

  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private store: Store,
    private scrollLockService: UiScrollLockingService,
    private candidateNavigationService: CandidateHomeNavigationService,
    private communityNavigationService: CommunityNavigationService,
    private router: Router,
    private conversationStore: ConversationStore
  ) {
    this.navigationItems = candidateNavigationService.navigationItems
  }

  ngOnInit(): void {
    this.mobileSideDrawerToggledState$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        return this.open()
      }

      this.close()
    })

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd && this.isOpen) {
        this.close(true)
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  open() {
    this.isOpen = true
    this.scrollLockService.scrollLock(true)
  }

  close(shouldEmit = false) {
    this.scrollLockService.scrollLock(false)
    this.isOpen = false

    if (shouldEmit) {
      this.candidateNavigationService.mobileSideDrawerToggle$.next(false)
    }
  }

  toggleDropdownMenu(menu: DropdownMenu) {
    if (this.selectedDropdownMenu === menu) {
      this.selectedDropdownMenu = null
      return
    }

    this.selectedDropdownMenu = menu
  }

  routeToPath(path: string) {
    this.router.navigate([path])
  }

  communitiesPage(page: CommunitiesMainPage) {
    this.communityNavigationService.communities({ page })
  }

  networkConnections() {
    this.candidateNavigationService.networkConnections()
  }

  networkInvitations() {
    this.candidateNavigationService.networkInvitations()
  }
}
