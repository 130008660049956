<li
  class="e11-relative e11-cursor-pointer"
  [ngClass]="{
    'e11-max-w-[150px] e11-w-[150px]': size === 'md',
    'e11-list-none': !isList
  }"
>
  <div
    class="e11-group e11-block e11-w-full e11-aspect-w-10 e11-aspect-h-7 e11-rounded-lg e11-bg-gray-100 focus-within:e11-ring-2 focus-within:e11-ring-offset-2 focus-within:e11-ring-offset-gray-100 focus-within:e11-ring-skin-primary e11-overflow-hidden"
    [ngClass]="{ 'e11-ring-2 e11-ring-offset-2 e11-ring-offset-gray-100 e11-ring-skin-primary': isActive }"
    (click)="onClick.emit(video)"
  >
    <div class="e11-absolute e11-flex flex-row e11-items-center e11-justify-center e11-w-full e11-z-10">
      <span class="material-icons-outlined md-48 e11-text-white"> play_circle </span>
    </div>
    <img [src]="video.thumb.signedURL" [alt]="video.name" class="e11-object-cover e11-pointer-events-none group-hover:e11-opacity-75" />
    <button type="button" class="e11-absolute e11-inset-0 focus:e11-outline-none">
      <span class="e11-sr-only">{{ video.name }}</span>
    </button>
  </div>
  <p
    class="e11-mt-2 e11-block e11-text-sm e11-font-medium e11-text-gray-900 e11-truncate e11-pointer-events-none e11-mb-0"
    (click)="onClick.emit(video)"
  >
    {{ video.name }}
  </p>
  <p class="e11-block e11-text-sm e11-font-medium e11-text-gray-500 e11-pointer-events-none e11-mt-0" (click)="onClick.emit(video)">
    {{ video.videoLength }}
  </p>
</li>
