import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IResumeGeneratedNotification } from '@candidate/app/models/app-notification.model'
import { SelectResume } from '@candidate/app/store/resume/resume.actions'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { Store } from '@ngrx/store'

@Injectable({ providedIn: 'root' })
export class ResumeGeneratedNotificationHandler implements INotificationHandler<IResumeGeneratedNotification, INotificationView> {
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService, private store: Store) {}
  notificationType = 'resume-generated'

  toViewModel(notification: IResumeGeneratedNotification): INotificationView {
    return {
      viewed: notification.viewed,
      description: 'A profile has been generated from your most recent job application',
      logoUrl: '',
      originalNotification: notification,
      title: 'Profile Generated',
    }
  }
  onClick(notification: IResumeGeneratedNotification): Promise<boolean> {
    this.markAsRead(notification)
    this.store.dispatch(new SelectResume(notification.resumeId))
    return this.router.navigate(['/home/profiles'])
  }

  private markAsRead(notification: IResumeGeneratedNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
