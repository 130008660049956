import { IJobProfile, IJobProfileContentItem } from './job-profile.model'
import { JobPostIntegrations } from 'shared-lib'
import { IContentDocument } from '@engineering11/content-web'

export const CONTENT_TYPE_JOB_POST = 'job-post'

type Modify<T, R> = Omit<T, keyof R> & R

export enum JobPostStatus {
  Published = 'Published',
  Publishing = 'Publishing',
  Draft = 'Draft',
  ClosedFilled = 'ClosedFilled',
  ClosedNotFilled = 'ClosedNotFilled',
  Deleted = 'Deleted',
}

export interface IJobPost extends Modify<IJobProfile, { status: JobPostStatus }> {
  sendToGoogle?: boolean
  editorIds?: string[]
  integrations?: Array<JobPostIntegrations>
  deactivated?: Date
}

export interface IPublishedJobPost extends IJobPost {
  totalApplicants?: number
}

export type IJobPostContentItem = Modify<
  IJobProfileContentItem,
  {
    document: IJobPost
  }
>

export function isJobPost(document: IContentDocument): document is IJobPost {
  return document.contentType === CONTENT_TYPE_JOB_POST
}
