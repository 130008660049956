<!-- <div class="header-container e11-w-full">
  <header class="header-landing e11-container-full e11-mx-auto">
    <div class="row flex">
      <div class="col-xs-8 nopadding flex-vertical-center" [routerLink]="['']">
        <img
          [src]="imgSrc"
          class="pointer"
          width="240"
          alt="{{ 'cnect' | translate }}"
          title="{{ 'cnect' | translate }}"
          aria-describedby="imgLogoCNect"
        />
      </div>
      <div class="col-xs-4 flex-align-center-justify-end">
        <e11-button [color]="'secondary'" (click)="navigateToEmployer()" value="{{ 'I am an employer' | translate }}" *ngIf="cta === 'signin'">
        </e11-button>
        <e11-button [color]="'secondary'" (click)="signOut()" value="{{ 'Logout' | translate }}" *ngIf="page === 'register-verify'"> </e11-button>
      </div>
    </div>
  </header>
</div> -->

<header>
  <nav class="e11-bg-skin-primary e11-fixed e11-w-full e11-z-[12]">
    <div class="e11-container-full e11-mx-auto e11-flex e11-px-6">
      <div class="e11-header-logo e11-flex e11-items-center e11-cursor-pointer" [routerLink]="'/network'">
        <app-logo-white [height]="'64px'"></app-logo-white>
      </div>
      <div class="e11-flex e11-grow e11-justify-end e11-items-center">
        <a class="e11-text-skin-white" target="_blank" href="https://hiring.cnected.com/employers/"> {{ 'Learn more about Cnect' | translate }}</a>
      </div>
    </div>
  </nav>
</header>
