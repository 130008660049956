import { Injectable } from '@angular/core'
import {
  ICandidateResume,
  ICandidateResumeVM,
  INewResumeThreshold,
  IResumeTokenSettings,
  ISharedResumeToken,
} from '@candidate/app/models/candidate-resume.model'
import { environment } from '@candidate/environments/environment'
import { DateService } from '@engineering11/date-time'
import { IFileReceipt } from '@engineering11/files-web'
import { AtLeast } from '@engineering11/types'
import { compareDesc, isNotNil, omit, sortWith, valueOf } from '@engineering11/utility'
import { Timestamp } from '@engineering11/web-api-firebase'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, take } from 'rxjs/operators'
import { copyToClipboard, getCurrentToken, TokenStorage } from 'shared-lib'
import { TokenManagementService } from '../token-management.service'
import { CandidateResumeRepository } from './candidate-resume.repository'

@Injectable({ providedIn: 'root' })
export class CandidateResumeService {
  private restApiClient: RestApiClient
  private restApiClientWithOutAuth: RestApiClient
  constructor(
    private resumeRepository: CandidateResumeRepository,
    private logger: NGXLogger,
    private store: Store,
    private tokenManagementService: TokenManagementService
  ) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.candidate,
      token: store.select(getCurrentToken).pipe(filter(isNotNil)),
    })

    this.restApiClientWithOutAuth = new RestApiClient({
      baseUrl: environment.services.candidate,
      token: of(null),
    })
  }

  getAllForUser(userId: string): Observable<Timestamp<ICandidateResume>[]> {
    const descByPrimary = compareDesc<Timestamp<ICandidateResume>>(valueOf('isPrimary')) // Primary first
    const descByDate = compareDesc<Timestamp<ICandidateResume>>(valueOf('__updatedAt')) // Recent activity first
    return this.resumeRepository.getAllForUser(userId).pipe(map(resumes => sortWith(resumes, [descByPrimary, descByDate])))
  }

  /**
   * Generates a new resume from the provided seed resume, or else bootstraps an entirely new one from user data
   * @param seedResume
   * @returns an observable of the resume that was created
   */
  create(seedResume?: ICandidateResume): Observable<Timestamp<ICandidateResume>> {
    const donorResume = seedResume ? omit<AtLeast<ICandidateResumeVM, 'id'>, 'shareTokens'>(seedResume, 'shareTokens') : undefined // do not actually save shareTokens
    return this.restApiClient.post<Timestamp<ICandidateResume>>('candidate-resume', { donorResume }).pipe(
      filter(isNotNil),
      map(res => {
        const resumeData = res.data
        // Deserialise dates until we have a nicer pattern
        const formattedResume = DateService.convertAllDateFields(resumeData) as Timestamp<ICandidateResume>
        return formattedResume
      }),
      take(1)
    )
  }

  update(resume: AtLeast<ICandidateResume, 'id'>) {
    const resumeToUpdate = omit<AtLeast<ICandidateResumeVM, 'id'>, 'shareTokens'>(resume, 'shareTokens') // do not actually save shareTokens
    return this.resumeRepository.update(resumeToUpdate)
  }

  delete(id: string) {
    this.logger.log('resumeService deleting', id)
    return this.resumeRepository.delete(id)
  }

  /**
   *  Last modified date helps the parser with work history.
   */
  parse(fileReceipt: IFileReceipt, lastModifiedDate?: number) {
    return this.restApiClient
      .post<Timestamp<ICandidateResume>>('candidate-resume/parse', { fileReceipt: fileReceipt, lastModifiedDate: lastModifiedDate })
      .pipe(
        filter(isNotNil),
        map(res => {
          const resumeData = res.data
          // Deserialise dates until we have a nicer pattern
          const formattedResume = DateService.convertAllDateFields(resumeData) as Timestamp<ICandidateResume>
          return formattedResume
        }),
        take(1)
      )
  }

  /**
   *  Last modified date helps the parser with work history.
   */
  parseAndSave(fileReceipt: IFileReceipt, lastModifiedDate?: number) {
    return this.restApiClient
      .post<Timestamp<ICandidateResume>>('candidate-resume/parse/save', { fileReceipt: fileReceipt, lastModifiedDate: lastModifiedDate })
      .pipe(
        filter(isNotNil),
        map(res => {
          const resumeData = res.data
          // Deserialise dates until we have a nicer pattern
          const formattedResume = DateService.convertAllDateFields(resumeData) as Timestamp<ICandidateResume>
          return formattedResume
        }),
        take(1)
      )
  }

  getNewResumeThreshold(userId: string): Observable<INewResumeThreshold> {
    return this.restApiClient.get<INewResumeThreshold>(`candidate-resume/${userId}/threshold`).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  createAccessToken(resumeId: string, tokenSettings?: IResumeTokenSettings): Observable<ISharedResumeToken> {
    return this.restApiClient.post<ISharedResumeToken>(`candidate-resume/${resumeId}/token`, tokenSettings).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  getFromToken(token: string): Observable<ICandidateResume> {
    return this.restApiClientWithOutAuth.get<ICandidateResume>(`candidate-resume/token/${token}`).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  getAllTokens(): Observable<ISharedResumeToken[]> {
    return this.restApiClient.get<ISharedResumeToken[]>('candidate-resume/tokens').pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  deactivateAccessToken(token: string) {
    return this.tokenManagementService.disableToken(token).pipe(
      map(_ => ({
        message: 'success',
      }))
    )
  }
  reactivateAccessToken(token: string) {
    return this.tokenManagementService.enableToken(token).pipe(
      map(_ => ({
        message: 'success',
      }))
    )
  }

  deleteAccessToken(token: string) {
    return this.tokenManagementService.deleteToken(token).pipe(
      map(_ => ({
        message: 'success',
      }))
    )
  }
}

export function buildOriginalUrl(token: string) {
  return `${environment.candidateBaseUrl}/#/public/profile-share/profile/${token}`
}

export function copyShareUrl(token: ISharedResumeToken) {
  const copyUrl = token.dynamicLink ? token.dynamicLink : buildOriginalUrl(token.token)
  copyToClipboard(copyUrl)
}
