import { Inject, Injectable } from '@angular/core'
import { CommunityStore } from '@engineering11/community-web'
import { ConversationStore } from '@engineering11/messaging-web'
import { ILogger, LOGGER_TOKEN } from '@engineering11/web-api-error'
import { FaviconService, IconData } from '@engineering11/web-utilities'
import { combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

const DEFAULT_ICON: IconData = { href: 'favicon.svg', type: 'image/x-icon' }
const NOTIFICATION_ICON: IconData = { href: 'assets/images/favicon-notification.svg', type: 'image/x-icon' }

@Injectable({ providedIn: 'root' })
export class FaviconSwitchingService {
  displayNotificationIcon$ = combineLatest([this.communityStore.hasNotification$, this.conversationStore.hasNotification$]).pipe(
    map(hasNotificationArray => hasNotificationArray.some(hasNotification => hasNotification)),
    distinctUntilChanged()
  )
  constructor(
    private faviconService: FaviconService,
    private conversationStore: ConversationStore,
    private communityStore: CommunityStore,
    @Inject(LOGGER_TOKEN) private logger: ILogger
  ) {
    this.initNotificationsIcon() // Where should this belong so that one subscription lives as long as the application is open
  }

  private initNotificationsIcon() {
    // ? How is this subscription terminated? Can it be? Can we prevent this from running more than once?
    this.displayNotificationIcon$.subscribe(displayNotification => {
      this.logger.log('switching favicon', { displayNotification })
      if (displayNotification) this.faviconService.setFavicon(NOTIFICATION_ICON)
      else this.faviconService.setFavicon(DEFAULT_ICON)
    })
  }
}
