<div class="wrapper">
  <ui-panel size="md" id="panel_passwordReset" borderRadius="lg" class="reset" [ngClass]="{ inactive: status }">
    <div class="row">
      <div class="col-xs-12 text-center">
        <h3>{{ 'Create New Password' | translate }}</h3>
        <div class="spacer-2rem"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1 hidden-xs"></div>
      <div class="col-sm-10 col-xs-12">
        <form class="" [formGroup]="formPassword" (ngSubmit)="onSubmitFormPassword()" novalidate>
          <div class="row">
            <div class="col-xs-12">
              <ui-input
                [type]="'password'"
                name="password"
                id="password_formPassword"
                label="{{ 'New Password' | translate }}"
                [style]="'standard'"
                [size]="'medium'"
                [parentForm]="formPassword"
                [autofocus]="false"
                formControlName="password"
                autocomplete="off"
                [showPassword]="true"
                borderGlow="primary"
                [required]="true"
                [hasError]="
                  passwordFC.hasError('minlength') || passwordFC.hasError('hasNumberError') || (passwordFC.invalid && formPasswordSubmitted)
                "
              >
              </ui-input>

              <ui-input
                [type]="'password'"
                name="passwordConfirm"
                id="passwordConfirm_formPassword"
                label="{{ 'Re-enter Password' | translate }}"
                [style]="'standard'"
                [size]="'medium'"
                [parentForm]="formPassword"
                [autofocus]="false"
                formControlName="passwordConfirm"
                autocomplete="off"
                [showPassword]="true"
                borderGlow="primary"
                [required]="true"
                [hasError]="passwordConfirmFC.invalid && formPasswordSubmitted"
              >
                <div
                  class="error-message-ani"
                  [ngClass]="{
                    active: (passwordConfirmFC.errors && passwordConfirmFC.dirty) || (passwordConfirmFC.invalid && formPasswordSubmitted)
                  }"
                >
                  <span class="error">
                    <span
                      class="error-message"
                      [ngClass]="{
                        active: passwordConfirmFC.hasError('passwordMatchError')
                      }"
                      >{{ 'Your passwords do not match' | translate }}</span
                    >
                  </span>
                </div>
              </ui-input>

              <div class="spacer-2rem"></div>

              <div class="error-message-password-list">
                <span class="error-message">
                  {{ 'Your password must meet the following requirements:' | translate }}
                </span>
                <span
                  class="error-message"
                  [ngClass]="{
                    active: passwordFC.hasError('minlength') || (formPasswordSubmitted && passwordFC.invalid),
                    pass: !passwordFC.hasError('minlength') && passwordFC.dirty && passwordFC.value.length > 0
                  }"
                >
                  {{ 'Minimum length is 8 characters' | translate }} <i class="fas fa-check"></i
                ></span>
                <span
                  class="error-message"
                  [ngClass]="{
                    active: passwordFC.hasError('hasNumberError') || (formPasswordSubmitted && passwordFC.invalid),
                    pass: !passwordFC.hasError('hasNumberError') && passwordFC.dirty && passwordFC.value.length > 0
                  }"
                  >{{ 'Include 1 number' | translate }} <i class="fas fa-check"></i
                ></span>
              </div>
            </div>
          </div>

          <div class="spacer-2rem"></div>

          <div class="row">
            <div class="col-xs-12 text-center">
              <e11-button id="submit_formPassword" value="{{ 'Submit' | translate }}" color="secondary" [type]="'submit'"> </e11-button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-1 hidden-xs"></div>
    </div>
  </ui-panel>

  <ui-panel size="md" id="panel_passwordSuccess" borderRadius="lg" class="success" [ngClass]="{ active: status }">
    <div class="row">
      <div class="col-xs-12 text-center">
        <h2 class="text-success">{{ 'Success!' | translate }}</h2>
        <p>{{ 'Alright! We did it! Now go out there and show off what you got!' | translate }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 text-center">
        <img src="../../../../../../../assets/images/auth-recover-success.png" alt="" width="400" />
      </div>
    </div>

    <div class="spacer-2rem"></div>

    <div class="row">
      <div class="col-xs-12 text-center">
        <e11-button id="button_login" value="{{ 'Back to login' | translate }}" color="secondary" [type]="'button'" [routerLink]="['/profile']">
        </e11-button>
      </div>
    </div>
  </ui-panel>
</div>
