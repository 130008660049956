<div class="row">
  <div class="col-xs-6 welcome">
    <h1 class="welcome-title">{{ 'Welcome!' | translate }}</h1>
    <p class="welcome-subtext">
      {{
        'In order to apply for this position, you need to create an account with cnect – a hiring management system. Once
      you create your profile and submit your application, you’ll be able to complete interviews and assessments within
      cnect and you will always know the status of your application.'
          | translate
      }}
    </p>

    <!-- We wont show anything w/o a name because it will be needed for UI friendly branding -->
    <e11-button
      color="primary"
      [routerLink]="['/auth/login']"
      [style]="'pill'"
      value="{{ 'Sign in' | translate }}"
      [buttonGroup]="true"
      (click)="sendAnalyticsEvent('sign_in', 'button_click')"
    >
    </e11-button>
    <e11-button
      color="secondary"
      [routerLink]="['/registration/register']"
      [style]="'pill'"
      value="{{ 'Register' | translate }}"
      [buttonGroup]="true"
      (click)="sendAnalyticsEvent('register', 'button_click')"
    >
    </e11-button>
  </div>
  <div class="col-xs-6">
    <img src="../../../../../../assets/images/landing-illustration.png" alt="" width="500" />
  </div>
</div>
