import { ICandidateApplicationUpdateStage } from '@candidate/app/models/candidate-job-update.model'
import { ApplicationUpdateStageRepository } from '@candidate/app/services/candidate-application/application-update-stage.repository'
import { buildApplicationStageId } from '@candidate/app/services/candidate-application/candidate-application-stage.service'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { IApplicationHistory, IJobApplicationStage, IProcessedJobApplication } from 'shared-lib'
import { ApplicationActions, ApplicationActionTypes } from './application.actions'

export interface State extends EntityState<IJobApplicationStage> {
  error: any | null
  processedApplicationPreview: IProcessedJobApplication | null // For the selected application
  selectedJobApplication: IProcessedJobApplication | null
  selectedApplicationHistory: IApplicationHistory[] | null // Application History for selected job - consider making entity state
  selectedApplicationUpdateStage: ICandidateApplicationUpdateStage | null
  loadingGetApplicationStage: boolean
  loadingSaveApplicationStage: boolean
  loadingApplicationPreview: boolean
  loadingApplicationHistory: boolean
  loadingSelectedJobApplication: boolean
}

export const adapter: EntityAdapter<IJobApplicationStage> = createEntityAdapter<IJobApplicationStage>({
  selectId: applicationStage => buildApplicationStageId(applicationStage),
})

export const initialState: State = adapter.getInitialState({
  error: null,
  processedApplicationPreview: null,
  selectedJobApplication: null,
  selectedApplicationHistory: null,
  selectedApplicationUpdateStage: null,
  loadingGetApplicationStage: false,
  loadingSaveApplicationStage: false,
  loadingApplicationPreview: false,
  loadingApplicationHistory: false,
  loadingSelectedJobApplication: false,
})

export function reducer(state = initialState, action: ApplicationActions): State {
  switch (action.type) {
    case ApplicationActionTypes.getApplicationStage:
      return { ...state, loadingGetApplicationStage: true }
    case ApplicationActionTypes.getApplicationStageSuccessWithEmpty:
      return { ...state, loadingGetApplicationStage: false }
    case ApplicationActionTypes.getApplicationStageSuccess:
      return adapter.setOne(action.payload, { ...state, loadingGetApplicationStage: false })
    case ApplicationActionTypes.getApplicationHistory:
      return { ...state, loadingApplicationHistory: true }
    case ApplicationActionTypes.getApplicationHistorySuccess:
      return { ...state, selectedApplicationHistory: action.payload, loadingApplicationHistory: false }
    case ApplicationActionTypes.createApplicationStage:
    case ApplicationActionTypes.updateApplicationStage:
      return { ...state, loadingSaveApplicationStage: true }
    case ApplicationActionTypes.createApplicationStageSuccess:
      return adapter.setOne(action.payload, { ...state, loadingSaveApplicationStage: false, loadingGetApplicationStage: false })
    case ApplicationActionTypes.updateApplicationStageSuccess:
      return adapter.updateOne(
        {
          id: buildApplicationStageId(action.payload),
          changes: action.payload,
        },
        {
          ...state,
          loadingSaveApplicationStage: false,
        }
      )
    case ApplicationActionTypes.processJobApplicationPreview:
      return { ...state, loadingApplicationPreview: true }
    case ApplicationActionTypes.processJobApplicationPreviewSuccess:
      return { ...state, processedApplicationPreview: action.payload, loadingApplicationPreview: false }
    case ApplicationActionTypes.stageApplicationUpdateSuccess:
      return { ...state, selectedApplicationUpdateStage: { ...state.selectedApplicationUpdateStage, ...action.payload } }
    case ApplicationActionTypes.getStagedApplicationUpdateSuccess:
      return { ...state, selectedApplicationUpdateStage: action.payload }
    case ApplicationActionTypes.getJobApplication:
      return { ...state, loadingSelectedJobApplication: true }
    case ApplicationActionTypes.getJobApplicationSuccess:
      return { ...state, selectedJobApplication: action.payload, loadingSelectedJobApplication: false }
    case ApplicationActionTypes.error:
      return { ...state, error: action.payload }
    default:
      return state
  }
}
