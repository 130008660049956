import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core'
import { UiModalComponent } from '@engineering11/ui/ui-modal'
import { UiTemplate } from './ui-template'

@Component({
  selector: 'form-modal-container',
  templateUrl: './form-modal-container.component.html',
})
export class FormModalContainerComponent implements AfterContentInit {
  @ViewChild('modalItem')
  modal!: UiModalComponent

  @Input()
  headerTitle = ''

  @Input()
  showDeleteButton: boolean = false

  @Input()
  showSubmitButton: boolean = true

  @Input()
  itemModel!: any

  @ContentChildren(UiTemplate)
  templates!: QueryList<any>

  uiTemplates: any = {}

  @Output()
  removeItem = new EventEmitter()

  @Output()
  submitItem = new EventEmitter()

  showTemplate = false

  ngAfterContentInit() {
    this.templates.forEach(item => {
      this.uiTemplates[item.getType()] = item.template
    })
  }

  open() {
    this.showTemplate = true
    this.modal.open()
  }

  close() {
    this.showTemplate = false
    this.modal.close()
  }
}
