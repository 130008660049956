import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MAX_DURATION_FIRST_IMPRESSION } from '@candidate/app/app.constants'
import { NGXLogger } from 'ngx-logger'
import { Subject } from 'rxjs'
import { MIN_DURATION_VIDEO, NotificationTranslateService } from 'shared-lib'
import { formatSecondsMMSS } from '@engineering11/utility'
import { VideoUtilService } from '@engineering11/multimedia-web'

export type VideoFileUploadData = { videoFile: File; durationStr: string; thumbs: string[] }

@Component({
  selector: 'app-video-file-upload',
  templateUrl: './video-file-upload.component.html',
  styleUrls: ['./video-file-upload.component.scss'],
})
export class VideoFileUploadComponent implements OnDestroy {
  @Input() maxDuration = MAX_DURATION_FIRST_IMPRESSION
  @Input() minDuration = MIN_DURATION_VIDEO
  @Output() newVideoData = new EventEmitter<VideoFileUploadData>()

  loading = false

  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(private logger: NGXLogger, private videoUtilService: VideoUtilService, private notificationsService: NotificationTranslateService) {}

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  async fileInputChange(event: any) {
    this.logger.log({ event })
    const videoFile: File = event.target.files[0]
    this.logger.log({ videoFile })
    if (!videoFile) {
      return
    }
    this.loading = true

    const duration = await this.videoUtilService.getVideoDuration(videoFile)
    this.loading = false
    const isValidDuration = this.validateDuration(duration)
    if (isValidDuration) {
      const durationStr = formatSecondsMMSS(duration)
      this.newVideoData.emit({ videoFile, thumbs: [], durationStr })
    }
  }

  private validateDuration(duration: number): boolean {
    this.logger.log('validating duration of: ', duration)
    if (duration > this.maxDuration) {
      this.notificationsService.popNotificationMessage(
        'Video Too Long',
        `The video must be less than ${this.maxDuration} seconds. Please upload another video.`,
        'danger',
        false
      )
      return false
    }
    if (duration < this.minDuration) {
      this.notificationsService.popNotificationMessage(
        'Video Too Short',
        `The video must be more than ${this.minDuration} seconds. Please upload another video.`,
        'danger',
        false
      )
      return false
    }
    return true
  }
}
