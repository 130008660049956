// Framework
import { NgModule } from '@angular/core'
import { canActivate } from '@angular/fire/compat/auth-guard'
import { RouterModule, Routes } from '@angular/router'
import { EmailActionsGuard } from '@candidate/app/guards/email-actions/email-actions.guard'
import { canAccessTermsPage, canAccessVerifyPage, redirectLoggedInToHome } from '@engineering11/auth-web'
// This 'DenyGuard' is used to block users from gettign back to the 'auth' module post login
// All 'user' management should be done in a 'users' module. 'auth' is not reusable for user management
import { FileNotFoundComponent } from '../_404/404.component'
// Platform specific
import { AuthenticationComponent } from './authentication.component'
import { AuthRecoveryFindAccountComponent } from './views/account-recovery/find-account/find-account.component'
import { AuthRecoveryPasswordResetComponent } from './views/account-recovery/password-reset/password-reset.component'
import { AuthRecoveryVerificationCodeComponent } from './views/account-recovery/verification-code/verification-code.component'
import { AuthEmailVerificationComponent } from './views/email-verification/email-verification.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthSuccessComponent } from './views/success/success.component'
import { AuthTermsOfUseComponent } from './views/terms/terms.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: 'login',
        component: AuthenticationLoginComponent,
        ...canActivate(redirectLoggedInToHome('home')),
      },
      {
        path: 'login/:deepLink',
        component: AuthenticationLoginComponent,
        ...canActivate(redirectLoggedInToHome('home')),
      },

      // Account recover
      {
        path: 'recover',
        component: AuthRecoveryFindAccountComponent,
        ...canActivate(redirectLoggedInToHome('home')),
      },
      {
        path: 'recover/find',
        component: AuthRecoveryFindAccountComponent,
        ...canActivate(redirectLoggedInToHome('home')),
      },
      {
        path: 'recover/verification',
        component: AuthRecoveryVerificationCodeComponent,
        ...canActivate(redirectLoggedInToHome('home')),
      },
      {
        path: 'recover/password-reset',
        component: AuthRecoveryPasswordResetComponent,
        ...canActivate(redirectLoggedInToHome('home')),
      },

      {
        path: 'register-verify',
        component: AuthEmailVerificationComponent,
        ...canActivate(canAccessVerifyPage('auth/login', 'home')),
      },
      {
        path: 'register-verify/:deepLink',
        component: AuthEmailVerificationComponent,
        ...canActivate(canAccessVerifyPage('auth/login', 'home')),
      },
      {
        path: 'success',
        component: AuthSuccessComponent,
      },
      {
        path: 'terms', // Make me last!!!!!!!
        component: AuthTermsOfUseComponent,
        ...canActivate(canAccessTermsPage('home', 'auth/register-verify')),
      },
      {
        path: 'terms/:deepLink', // Make me last!!!!!!!
        component: AuthTermsOfUseComponent,
        ...canActivate(canAccessTermsPage('home ', 'auth/register-verify')),
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
