import { environment } from '@candidate/environments/environment'
import { Injectable } from '@angular/core'
import { ICandidateJobVM, TokenStorage } from 'shared-lib'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { CandidateJobVMRepository } from './candidate-job-vm.repository'
import { isNotNil } from '@engineering11/utility'

const filterFulfilledForJob = (job: ICandidateJobVM) => {
  return { ...job, requestedUpdated: job.requestedUpdated.filter(request => !request.fulfilled) }
}

@Injectable({
  providedIn: 'root',
})
export class MyJobService {
  private restApiClient: RestApiClient
  constructor(tokenStorage: TokenStorage, private candidateJobVMRepository: CandidateJobVMRepository) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.candidate,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  getMyJobs(userId: string): Observable<ICandidateJobVM[]> {
    return this.candidateJobVMRepository.getAllForUser(userId).pipe(map(candidateJobs => candidateJobs.map(filterFulfilledForJob)))
  }

  getJobValueChanges(userId: string, jobPostId: string) {
    const id = this.buildID({ userId, jobId: jobPostId })
    return this.candidateJobVMRepository.getValueChanges(id).pipe(filter(isNotNil), map(filterFulfilledForJob))
  }

  getJob(jobPostId: string): Observable<ICandidateJobVM> {
    return this.restApiClient.get<ICandidateJobVM>(`candidate-jobs/${jobPostId}`).pipe(map(item => filterFulfilledForJob(item.data)))
  }

  addToMyJob(data: { jobId: string }): Observable<any> {
    return this.restApiClient.put<any>('candidate-jobs', data).pipe(map(item => item))
  }

  private buildID(model: Pick<ICandidateJobVM, 'userId' | 'jobId'>) {
    return `${model.userId}_${model.jobId}`
  }
}
