import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { ICandidateResume } from '@candidate/app/models/candidate-resume.model'
import { CandidateQuestionResponsesComponent } from '@candidate/app/modules/_shared/components/candidate-question-responses/candidate-question-responses.component'
import { stageHasResumeData, stageToSubmission } from '@candidate/app/services/candidate-application/application.util'
import { ApplicationChooseResume, SubmitJobApplication, UpdateCandidateResponses } from '@candidate/app/store/application/application.actions'
import { getApplicationStageForSelectedJob } from '@candidate/app/store/application/application.selectors'
import { getSelectedJob } from '@candidate/app/store/job/job.selectors'
import { getResumeEntities, isGetAllResumesLoaded } from '@candidate/app/store/resume/resume.selectors'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { E11CardState } from '@engineering11/ui-lib/e11-card'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { getEmptyFields, isNotNil } from '@engineering11/utility'
import { DisplayPager, ViewportService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { UiScrollLockingService } from 'projects/shared-lib/src/lib/service/scroll-locking.service'
import { combineLatest, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators'
import {
  ApplicationFieldPipe,
  CnectFeatures,
  getFeatures,
  ICandidateJobSubmission,
  ICandidateJobVM,
  ICandidateResponses,
  IJobApplicationStage,
  isStaticConfigLoaded,
  NotificationTranslateService,
} from 'shared-lib'

const fieldsToPrompt: (keyof IJobApplicationStage)[] = ['workHistory', 'education', 'firstImpression', 'skills', 'certifications']

export enum ApplicationPage {
  JobDescription = 'Job Description',
  PopulateApplication = 'Pre-populate Application',
  EditApplication = 'Edit Application',
  CandidateResponses = 'Confirmations',
  ApplicationReview = 'Review & Submit',
}

type ApplicationDisplayPage = { applicationPage: ApplicationPage; display?: boolean }

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent implements OnInit, OnDestroy {
  @ViewChild('CandidateQuestionsForm') candidateQuestionsForm?: CandidateQuestionResponsesComponent
  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent
  @Output() applicationClosed: EventEmitter<boolean> = new EventEmitter()
  confirmationMessage = 'You are about to submit your application. The application cannot be edited once submitted unless requested by the employer.'

  selectedJob$: Observable<ICandidateJobVM | null | undefined> = this.store.select(getSelectedJob) // TODO: Add a loader
  selectedApplication$: Observable<IJobApplicationStage | undefined> = this.store.select(getApplicationStageForSelectedJob)
  selectedApplication?: IJobApplicationStage

  features$: Observable<CnectFeatures | undefined> = this.store.select(getFeatures)
  featuresLoaded$: Observable<boolean> = this.store.select(isStaticConfigLoaded)
  resumes$ = this.store.select(getResumeEntities)
  resumesLoaded$ = this.store.select(isGetAllResumesLoaded)
  E11CardState = E11CardState

  // showPopulateApplication determines whether to enable PopulateApplication at all. skipPopulateApplication determines whether to jump to the next page
  showPopulateApplication$ = combineLatest([this.featuresLoaded$, this.resumesLoaded$]).pipe(
    filter(([featuresLoaded, resumesLoaded]) => featuresLoaded && resumesLoaded),
    distinctUntilChanged(),
    switchMap(_ => combineLatest([this.resumes$, this.features$])),
    map(([resumes, features]) => resumes.length || features?.resumeParsing)
  )
  skipPopulateApplication$ = this.selectedApplication$.pipe(
    filter(isNotNil),
    map(selectedApplication => stageHasResumeData(selectedApplication))
  )

  destroy$: Subject<boolean> = new Subject<boolean>()

  // ! Before editing the order, review the page logic in ngOnInit
  displayPager = new DisplayPager<ApplicationDisplayPage>([
    { applicationPage: ApplicationPage.JobDescription, display: false },
    { applicationPage: ApplicationPage.PopulateApplication, display: false },
    { applicationPage: ApplicationPage.EditApplication },
    { applicationPage: ApplicationPage.CandidateResponses },
    { applicationPage: ApplicationPage.ApplicationReview },
  ])

  applicationPageEnum = ApplicationPage

  previousPage?: ApplicationPage
  currentPage?: ApplicationPage
  nextPage?: ApplicationPage

  onSidePage = false

  pageLoaded = false // Important because the wayfinder stores the pages the user has visited and if it's init with the wrong start page, it'll default to green even when not visited

  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'sm' || data == 'xs'),
    filter(isNotNil)
  )

  constructor(
    private store: Store,
    private logger: NGXLogger,
    private notificationsService: NotificationTranslateService,
    private applicationFieldPipe: ApplicationFieldPipe,
    private backdropService: E11BackdropService,
    private scrollLockService: UiScrollLockingService,
    private router: Router,
    private viewportService: ViewportService
  ) {}

  ngOnInit(): void {
    this.selectedApplication$.pipe(takeUntil(this.destroy$)).subscribe(selectedApplication => {
      this.selectedApplication = selectedApplication
    })

    combineLatest([this.showPopulateApplication$, this.skipPopulateApplication$])
      .pipe(take(1)) // ! Important this only runs once when the necessary information is loaded
      .subscribe(([showPopulateApplication, skipPopulateApplication]) => {
        if (showPopulateApplication) {
          this.displayPager.updateItem(item => item.applicationPage === ApplicationPage.PopulateApplication, { display: true })
          this.displayPager.reset()
          if (skipPopulateApplication) this.displayPager.goTo(item => item.applicationPage == ApplicationPage.EditApplication)
          this.updatePageStatus()
        }
        this.pageLoaded = true
      })

    this.updatePageStatus()
    this.initRouting()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  // TODO: Determine how side page navigation can be integrated into the displayPager without hacking it
  toSidePage(page: ApplicationPage) {
    this.currentPage = page
    this.onSidePage = true
  }
  backFromSidePage() {
    this.onSidePage = false
    this.updatePageStatus()
  }

  navNext(jobPostId?: string) {
    this.displayPager.next()
    this.updatePageStatus()
    this.scrollUp()
  }

  navPrevious(jobPostId?: string) {
    this.displayPager.previous()
    this.updatePageStatus()
    this.scrollUp()
  }

  navDirectlyTo(value: ApplicationPage) {
    this.displayPager.goTo(item => item.applicationPage == value)
    this.updatePageStatus()
  }

  applyManually() {
    this.displayPager.next()
    this.updatePageStatus()
  }

  scrollUp() {
    window.scroll(0, 0)
  }

  private updatePageStatus() {
    this.currentPage = this.displayPager.getCurrent()?.applicationPage
    this.previousPage = this.displayPager.peekPrevious()?.applicationPage
    this.nextPage = this.displayPager.peekNext()?.applicationPage
  }

  confirmSubmission() {
    const incompleteFields = this.getIncompleteFields(this.selectedApplication!)
    this.logger.log({ incompleteFields })
    if (incompleteFields.length) {
      this.confirmationMessage = this.buildConfirmationMessage(incompleteFields)
      this.confirmation.open()
      this.scrollLockService.scrollLock(true)
    } else {
      this.submitApplication(this.selectedApplication!)
    }
  }
  confirmationAnswer(confirmed: boolean) {
    if (confirmed) {
      this.submitApplication(this.selectedApplication!)
    }
    this.scrollLockService.scrollLock(false)
  }

  submitApplication(stagedApplication: IJobApplicationStage) {
    const jobSubmission: ICandidateJobSubmission = stageToSubmission(stagedApplication)
    this.store.dispatch(new SubmitJobApplication(jobSubmission))
    this.applicationClosed.emit(true) // ? do we need to emit this?
    this.backdropService.backdropDisplay(false, '')
    this.scrollLockService.scrollLock(false)
    this.router.navigate(['/home/applications'])
  }

  saveAndExit() {
    this.applicationClosed.emit(true)
    // Resets on exit without jacking the transition
    setTimeout(() => {
      this.displayPager.reset()
      this.updatePageStatus()
    }, 200)
  }

  chooseResumeForApplication(resume: ICandidateResume) {
    this.store.dispatch(new ApplicationChooseResume(resume))
    this.navNext()
  }
  changeCandidateResponses(jobPostId: string, candidateResponses: ICandidateResponses) {
    this.store.dispatch(new UpdateCandidateResponses({ jobPostId, candidateResponses }))
  }

  /**
   * Needed to prevent candidate navigating to next page if their responses are invalid
   * @returns whether the next button should be disabled
   */
  isNextDisabled(): boolean {
    if (this.currentPage === ApplicationPage.CandidateResponses) {
      const formIsValid = this.candidateQuestionsForm?.isFormValid ?? true
      if (!formIsValid) {
        this.notificationsService.popNotificationMessage('Something may be wrong', 'Please confirm the information you entered.', 'warning', false)
        this.candidateQuestionsForm?.responsesFormGroup.markAllAsTouched()
        return true
      }
    }

    return false
  }

  private buildConfirmationMessage(incompleteFields: (keyof IJobApplicationStage)[]): string {
    const base = 'The following key sections of your application are empty:'
    const translatedFields = incompleteFields.map(field => this.applicationFieldPipe.transform(field))
    const list = translatedFields.join('<br/> ')
    const end = 'Are you sure you wish to submit your application?'
    return `${base}<br/>${list}<br/><br/>${end}`
  }

  /**
   * Translated incomplete fields to prompt on
   * @param application
   * @returns
   */
  private getIncompleteFields(application: IJobApplicationStage) {
    const emptyFields = getEmptyFields(application)
    return emptyFields.filter(field => fieldsToPrompt.includes(field))
  }

  private initRouting() {
    // TODO: Consider whether we can do this in a way that's easy to change
    // this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(queryParams => {
    //   const applicationPage: ApplicationPage = queryParams.applicationPage
    //   this.displayPager.goTo(displayPage => displayPage.applicationPage === applicationPage)
    //   this.updatePageStatus()
    // })
  }
}
