<h4 class="e11-text-skin-primary-accent e11-font-primary-bold e11-mb-0 e11-mr-1">
  {{ 'Step 3: ' | translate }} {{ enumApplicationPage.CandidateResponses | translate }}
</h4>
<p class="e11-w-full e11-flex e11-items-center">
  {{ 'Please answer the following questions..' | translate }}
  <e11-tooltip [color]="'dark'" tooltipText="{{ 'These are required to apply for this position.' | translate }}">
    <span class="material-icons-outlined md-18 e11-ml-1"> info </span>
  </e11-tooltip>
</p>
<e11-panel [hasBorder]="true" [hasShadow]="false">
  <form *ngIf="!loading; else Loading" action="" [formGroup]="responsesFormGroup" novalidate>
    <!-- Confirmations -->

    <!-- Authorized  -->
    <div class="e11-grid e11-grid-cols-3 e11-gap-4 e11-flex e11-items-start e11-mb-2">
      <div>
        <p>{{ 'Are you legally authorized to be employed in the U.S.?' | translate }}</p>
      </div>
      <div>
        <ui-input-radio
          name="legalWorkAuthorization"
          [value]="true"
          checked="answerVisa"
          label="{{ 'Yes I am' | translate }}"
          [style]="'inline'"
          color="secondary"
          formControlName="legalWorkAuthorization"
        >
        </ui-input-radio>
      </div>
      <div>
        <ui-input-radio
          name="legalWorkAuthorization"
          [value]="false"
          checked="!answerVisa"
          label="{{ 'No, I am not' | translate }}"
          [style]="'inline'"
          color="primary"
          formControlName="legalWorkAuthorization"
        >
        </ui-input-radio>
      </div>
    </div>

    <!-- Sponsorship  -->
    <div class="e11-grid e11-grid-cols-3 e11-gap-4 e11-flex e11-items-start e11-mb-2">
      <div>
        <p>{{ 'Will you now, or in the future, require sponsorship for employment visa status?' | translate }}</p>
      </div>
      <div>
        <ui-input-radio
          name="visaSponsorshipRequired"
          [value]="true"
          checked="answerVisa"
          label="{{ 'Yes' | translate }}"
          [style]="'inline'"
          color="secondary"
          formControlName="visaSponsorshipRequired"
        >
        </ui-input-radio>
      </div>
      <div>
        <ui-input-radio
          name="visaSponsorshipRequired"
          [value]="false"
          checked="!answerVisa"
          label="{{ 'No' | translate }}"
          [style]="'inline'"
          color="primary"
          formControlName="visaSponsorshipRequired"
        >
        </ui-input-radio>
      </div>
    </div>

    <!-- Hours  -->
    <div class="e11-grid e11-grid-cols-3 e11-gap-4 e11-items-start e11-mb-2" *ngIf="jobDetail?.setHours">
      <div>
        <p class="e11-mb-0">{{ 'Can you work the days and/or hours of the job?' | translate }}</p>
        <!-- Schedule Details -->
        <div class="e11-cursor-pointer e11-relative" (mouseenter)="toggleConfirmationDetails()" (mouseleave)="toggleConfirmationDetails()">
          <span class="e11-text-skin-primary-accent">{{ 'Schedule' | translate }}</span>
          <span class="confirmations e11-shadow-md" [ngClass]="{ active: showConfirmationDetails }">
            <job-schedule [selectedDays]="jobDetail?.schedule"></job-schedule>
          </span>
        </div>
      </div>
      <div>
        <ui-input-radio
          name="hoursConfirmed"
          [value]="true"
          checked="confirmationHours"
          label="{{ 'Yes, I am available' | translate }}"
          [style]="'inline'"
          formControlName="hoursConfirmed"
          color="secondary"
        >
        </ui-input-radio>
      </div>
      <div>
        <ui-input-radio
          name="hoursConfirmed"
          [value]="false"
          checked="!confirmationHours"
          label="{{ 'No, I am not available' | translate }}"
          [style]="'inline'"
          color="primary"
          formControlName="hoursConfirmed"
        >
        </ui-input-radio>
      </div>
    </div>

    <!-- Travel  -->
    <div class="e11-grid e11-grid-cols-3 e11-gap-4 e11-items-start e11-mb-2" *ngIf="jobDetail?.travelPercentage">
      <div>
        <p class="e11-mb-0">{{ 'Can you meet the travel requirements of' | translate }} {{ jobDetail?.travelPercentage }}%?</p>
      </div>
      <div>
        <ui-input-radio
          name="travelConfirmed"
          [value]="true"
          checked="confirmationTravel"
          label="{{ 'Yes, I am able to travel' | translate }}"
          [style]="'inline'"
          color="secondary"
          formControlName="travelConfirmed"
        >
        </ui-input-radio>
      </div>
      <div>
        <ui-input-radio
          name="travelConfirmed"
          [value]="false"
          checked="!confirmationTravel"
          label="{{ 'No, I am unable to travel' | translate }}"
          [style]="'inline'"
          color="primary"
          formControlName="travelConfirmed"
        >
        </ui-input-radio>
      </div>
    </div>

    <!-- Referral  -->
    <div class="e11-grid e11-grid-cols-3 e11-gap-4 e11-items-start e11-mb-4" *ngIf="false">
      <div>
        <p>{{ 'How did you hear about this job?' | translate }}</p>
      </div>
      <div class="e11-col-span-2">
        <e11-select
          name="select"
          id="referralSource"
          [size]="'sm'"
          [options]="referralSourceList"
          formControlName="referralSource"
          selectMessage="{{ '' | translate }}"
          [value]="selectDefaultReferral"
        >
        </e11-select>

        <div *ngIf="showReferralDetails" class="e11-mt-2">
          <e11-input
            [type]="'text'"
            name="referralDetail"
            id="id_referralDetail"
            [size]="'sm'"
            [borderGlow]="'primary'"
            [parentForm]="responsesFormGroup"
            formControlName="referralDetail"
            autocomplete="off"
            [required]="showReferralDetails"
            [hasError]="f.referralDetail.hasError('required')"
            label="{{ 'Referrer' | translate }}"
          >
            <e11-input-errors
              [parentForm]="responsesFormGroup"
              [formControl]="f.referralDetail"
              label="{{ 'Referrer' | translate }}"
            ></e11-input-errors>
          </e11-input>
        </div>
      </div>
    </div>

    <!-- Experience  -->
    <div class="e11-grid e11-grid-cols-3 e11-gap-4 e11-items-start e11-mb-4">
      <div>
        <p>{{ 'How many years of experience do you have related to this job?' | translate }}</p>
      </div>
      <div class="e11-col-span-2 e11-flex">
        <div class="e11-w-24 e11-mr-4">
          <e11-input
            [type]="'number'"
            name="yearsRelevantExperience"
            id="id_years"
            label="{{ 'Years' | translate }}"
            [size]="'sm'"
            [borderGlow]="'primary'"
            autocomplete="off"
            [parentForm]="responsesFormGroup"
            formControlName="yearsRelevantExperience"
            [hasError]="f.yearsRelevantExperience.invalid && f.yearsRelevantExperience.touched"
          >
          </e11-input>
        </div>
        <div class="e11-w-24">
          <e11-input
            [type]="'number'"
            name="monthsRelevantExperience"
            id="id_months"
            label="{{ 'Months' | translate }}"
            [size]="'sm'"
            [borderGlow]="'primary'"
            autocomplete="off"
            [parentForm]="responsesFormGroup"
            formControlName="monthsRelevantExperience"
            [hasError]="f.monthsRelevantExperience.invalid && f.monthsRelevantExperience.touched"
          >
          </e11-input>
        </div>
      </div>
    </div>

    <!-- /Confirmations -->
  </form>

  <ng-template #Loading>
    <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary'"></e11-loader-dots>
  </ng-template>
</e11-panel>
