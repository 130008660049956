import { Component, ContentChildren, EventEmitter, HostListener, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core'
import { E11SideOverService } from '@engineering11/ui-lib/e11-side-over'
import { UiScrollLockingService } from 'projects/shared-lib/src/lib/service/scroll-locking.service'
import { UiTemplate } from '../modal-container/ui-template'

@Component({
  selector: 'form-sideover-container',
  templateUrl: './form-sideover-container.component.html',
  styleUrls: ['./form-sideover-container.component.scss'],
})
export class FormSideoverContainerComponent implements OnInit {
  @Input() headerTitle = ''
  @Input() subTitle = ''
  @Input() showDeleteButton: boolean = false
  @Input() showSubmitButton: boolean = true
  @Input() loading: boolean = false
  @Input() submitDisabled: boolean = false // Consider reaching into the form in a standardised way
  @Input() closeIconLocation: 'left' | 'right' = 'right'

  @Output() removeItem = new EventEmitter()
  @Output() closeSideOver = new EventEmitter()
  @Output() submitItem = new EventEmitter()

  @ViewChild('sideOverContent') sideOverContent!: TemplateRef<any>
  @ContentChildren(UiTemplate) templates!: QueryList<any>

  uiTemplates: any = {}
  isOpen = false

  constructor(private sideOverService: E11SideOverService, private scrollLockService: UiScrollLockingService) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close()
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.templates.forEach(item => {
      this.uiTemplates[item.getType()] = item.template
    })
  }

  open() {
    this.isOpen = true
    this.sideOverService.overlayToggle(true, this.sideOverContent, { size: 'lg' })
    this.scrollLockService.scrollLock(true)
  }

  close() {
    this.closeSideOver.emit(false)
    this.sideOverService.overlayToggle(false)
    this.scrollLockService.scrollLock(false)
    // TODO: How to maintain the animation - setTimeout doesn't work because it allows the user to break their state by closing and quickly reopening
    this.isOpen = false // rip contents out of the DOM on close
  }
}
