<div
  class="ui-tag-candidate {{ type }} {{ item.level }}"
  title="{{ item.name }} - {{ 'Click to edit' | translate }}"
  (click)="!readOnly && editSkill(); $event.stopPropagation()"
  [ngClass]="{ editing: item.editing, 'e11-cursor-pointer': !readOnly }"
>
  <div class="e11-block e11-text-sm e11-font-primary-demibold e11-mb-0" [ngClass]="{ 'e11-truncate': type === 'skill' }">{{ item.name }}</div>
  <div class="break" *ngIf="type === 'skill'"></div>
  <div class="e11-block e11-text-xs" *ngIf="type === 'skill'">{{ item.level }}</div>
  <div
    *ngIf="!readOnly"
    class="ui-tag-candidate-remove e11-text-white/80 hover:e11-text-white e11-flex e11-items-center"
    alt="Remove"
    title="Remove"
    (click)="removeSkill(); $event.stopPropagation()"
  >
    <span class="material-icons-outlined md-18"> cancel </span>
  </div>
  <div
    *ngIf="!readOnly"
    class="ui-tag-candidate-edit e11-text-white/80 hover:e11-text-white e11-flex e11-items-center"
    alt="Edit"
    title="Edit"
    (click)="editSkill(); $event.stopPropagation()"
  >
    <span class="material-icons-outlined md-18"> edit </span>
  </div>
</div>
