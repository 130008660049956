import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ICommunityMemberMentionedNotification } from '@candidate/app/models/app-notification.model'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'

@Injectable({ providedIn: 'root' })
export class CommunityMemberMentionedNotificationHandler implements INotificationHandler<ICommunityMemberMentionedNotification, INotificationView> {
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}
  toViewModel(notification: ICommunityMemberMentionedNotification): INotificationView {
    return {
      description: `${notification.displayName} has mentioned you in ${notification.channelName}.`,
      logoUrl: '',
      originalNotification: notification,
      viewed: notification.viewed,
      title: 'Communities: Mentioned',
    }
  }
  onClick(notification: ICommunityMemberMentionedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['c/m/messaging/community/', notification.communityId])
  }

  notificationType: string = 'community/user-mentioned'
}
