import { createFeatureSelector, createSelector, select } from '@ngrx/store'

import * as fromReducers from '@candidate/app/store/reducers'
import { combineLatest, pipe } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { CandidateJobStatus, ICandidateJobVM, IVirtualDialogue, VirtualDialogueStatus } from 'shared-lib'
import { ApplicationChipsDict, ChipType, IApplicationChip, typeToColor } from '@candidate/app/models/application-chip.model'
import { mapNotNullish } from '@engineering11/utility'

export type DialogueWithJob = IVirtualDialogue & { job: ICandidateJobVM }

export const getJobStore = createFeatureSelector<fromReducers.job.State>('job')

export const getJobEntities = createSelector(getJobStore, fromReducers.job.jobEntitySelectors.selectAll)
export const getJobEntitiesDictionary = createSelector(getJobStore, fromReducers.job.jobEntitySelectors.selectEntities)
export const getJobsTotal = createSelector(getJobStore, fromReducers.job.jobEntitySelectors.selectTotal)
export const getSelectedJobId = createSelector(getJobStore, state => state.selectedJobId)
export const getPublicJobDetail = createSelector(getJobStore, state => state.publicJobDetail)
export const getJobSubmission = createSelector(getJobStore, state => state.jobSubmission)
export const getProcessedApplication = createSelector(getJobStore, state => state.processedApplication)
export const getJobLoadingState = createSelector(getJobStore, state => state.loadingJobs)
export const getJobErrorState = createSelector(getJobStore, state => state.error)

export const getJobApplicationStash = pipe(select(createSelector(getJobStore, state => state.jobApplicationStash)), distinctUntilChanged())

export const getSelectedJob = createSelector(getJobStore, store => {
  const entityDict = store.entities
  const selectedJobId = store.selectedJobId
  return selectedJobId && entityDict && entityDict[selectedJobId] ? entityDict[selectedJobId] : null
})

export const getSingleJob = (id: string) =>
  createSelector(getJobStore, (jobEntities: fromReducers.job.State) => {
    return jobEntities.entities[id]
  })

export const getVirtualDialoguesState = createSelector(getJobStore, state => state.virtualDialogues)

export const getVirtualDialoguesForJob = (jobPostId: string) =>
  createSelector(getVirtualDialoguesState, virtualDialogues => findDialoguesForJob(jobPostId, virtualDialogues))

export const getIncompleteDialoguesAlertData = createSelector(getJobEntitiesDictionary, getVirtualDialoguesState, (jobs, dialogues) => {
  const incompleteDialogues = dialogues.filter(dialogue => dialogue.status === VirtualDialogueStatus.Published_Employer)
  const dialoguesWithJobData: DialogueWithJob[] = mapNotNullish(incompleteDialogues, dialogue => {
    const job = jobs[dialogue.jobPostId]
    if (!job || job.status !== CandidateJobStatus.Applied) {
      return null
    }
    return { ...dialogue, job }
  })

  return dialoguesWithJobData
})

const _getBadgeCountForJob = (jobPostId: string) =>
  createSelector(getJobStore, state => {
    const requestedUpdatesCount = state.entities[jobPostId]?.requestedUpdated?.length ?? 0
    const virtualDialoguesCount = getUnfinishedDialoguesForJob(jobPostId, state.virtualDialogues).length
    return requestedUpdatesCount + virtualDialoguesCount
  })

export const getBadgeCountForJob = (jobPostId: string) => pipe(select(_getBadgeCountForJob(jobPostId)), distinctUntilChanged<number>())

export const getApplicationChipsDict = createSelector(getJobEntities, getVirtualDialoguesState, (jobEntities, virtualDialogues) =>
  getChipsForJobs(jobEntities, virtualDialogues)
)

function getUnfinishedDialoguesForJob(jobPostId: string, virtualDialogues: IVirtualDialogue[]): IVirtualDialogue[] {
  const dialoguesForJob = findDialoguesForJob(jobPostId, virtualDialogues)
  return filterUnfinishedDialogues(dialoguesForJob)
}
function findDialoguesForJob(jobPostId: string, virtualDialogues: IVirtualDialogue[]): IVirtualDialogue[] {
  return virtualDialogues.filter(virtualDialogue => virtualDialogue.jobPostId === jobPostId)
}
function filterUnfinishedDialogues(virtualDialogues: IVirtualDialogue[]): IVirtualDialogue[] {
  return virtualDialogues.filter(virtualDialogue => virtualDialogue.status !== VirtualDialogueStatus.Published_Candidate)
}

function getChipsForJobs(jobPosts: ICandidateJobVM[], allVirtualDialogues: IVirtualDialogue[]): ApplicationChipsDict {
  const jobChipsDict: ApplicationChipsDict = {}
  jobPosts.forEach(jobPost => {
    jobChipsDict[jobPost.jobId] = getChipsForJob(jobPost, allVirtualDialogues)
  })
  return jobChipsDict
}

function getChipsForJob(jobPost: ICandidateJobVM, virtualDialogues: IVirtualDialogue[]): IApplicationChip[] {
  const chipTypes: ChipType[] = []
  const unfinishedDialogues = getUnfinishedDialoguesForJob(jobPost.jobId, virtualDialogues)
  const jobIsNotClosed = jobPost.status === CandidateJobStatus.Applied || jobPost.status === CandidateJobStatus.Viewed
  if (jobPost.requestedUpdated.length && jobIsNotClosed) {
    chipTypes.push(ChipType.UpdateRequested)
  }
  if (jobPost.status === CandidateJobStatus.Viewed) {
    chipTypes.push(ChipType.Incomplete)
  }
  if (jobPost.status === CandidateJobStatus.CandidateNotInterested) {
    chipTypes.push(ChipType.Withdrawn)
  }
  if (jobPost.status === CandidateJobStatus.EmployerNotInterested) {
    chipTypes.push(ChipType.ApplicationDeclined)
  }
  if (jobPost.status === CandidateJobStatus.JobClosed) {
    chipTypes.push(ChipType.ClosedByEmployer)
  }
  if (jobIsNotClosed && unfinishedDialogues.length) {
    chipTypes.push(ChipType.DialogueAvailable)
  }
  return chipTypes.map(type => {
    return { type, color: typeToColor[type] }
  })
}
