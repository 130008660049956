// Framework
import { Injectable } from '@angular/core'

// Other - third party
import { BehaviorSubject } from 'rxjs'

export enum APP_NAVIGATION_LEFT_GROUPS {
  Applications = 'applications',
  Profiles = 'profiles',
  Network = 'network',
  Messages = 'messaging',
  Communities = 'communities',
  Videos = 'videos',
}

@Injectable({
  providedIn: 'root',
})
export class NavLeftService {
  statusSectionApplicationsSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  statusSectionProfilesSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  statusSectionNetworkSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  statusSectionMessagesSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  statusSectionCommunitiesSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  statusSectionVideosSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  statusSectionProfilesObservable$
  statusSectionApplicationsObservable$
  statusSectionNetworkObservable$
  statusSectionMessagesObservable$
  statusSectionCommunitiesObservable$
  statusSectionVideosObservable$

  constructor() {
    this.statusSectionApplicationsObservable$ = this.statusSectionApplicationsSource.asObservable()
    this.statusSectionProfilesObservable$ = this.statusSectionProfilesSource.asObservable()
    this.statusSectionNetworkObservable$ = this.statusSectionNetworkSource.asObservable()
    this.statusSectionMessagesObservable$ = this.statusSectionMessagesSource.asObservable()
    this.statusSectionCommunitiesObservable$ = this.statusSectionCommunitiesSource.asObservable()
    this.statusSectionVideosObservable$ = this.statusSectionVideosSource.asObservable()
  }

  navToggleGroup(group: APP_NAVIGATION_LEFT_GROUPS, status: boolean) {
    switch (group) {
      case APP_NAVIGATION_LEFT_GROUPS.Applications:
        this.statusSectionApplicationsSource.next(status)
        break
      case APP_NAVIGATION_LEFT_GROUPS.Profiles:
        this.statusSectionProfilesSource.next(status)
        break
      case APP_NAVIGATION_LEFT_GROUPS.Network:
        this.statusSectionNetworkSource.next(status)
        break
      case APP_NAVIGATION_LEFT_GROUPS.Messages:
        this.statusSectionMessagesSource.next(status)
        break
      case APP_NAVIGATION_LEFT_GROUPS.Communities:
        this.statusSectionCommunitiesSource.next(status)
        break
      case APP_NAVIGATION_LEFT_GROUPS.Videos:
        this.statusSectionVideosSource.next(status)
        break
      default:
        return
    }
  }
}
