<ui-panel size="md" id="panel_Verification" borderRadius="lg">
  <div class="row">
    <div class="col-sm-1 hidden-xs"></div>
    <div class="col-sm-10 col-xs-12 text-center">
      <h3>{{ 'We’ve just sent a verification code' | translate }}</h3>
      <p>
        {{ 'We just sent a verification code to your email. If you don’t see our email in your inbox, check your spam
        folder' | translate }}
      </p>
      <div class="spacer-2rem"></div>
    </div>
    <div class="col-sm-1 hidden-xs"></div>
  </div>

  <div class="row">
    <div class="col-sm-1 hidden-xs"></div>
    <div class="col-sm-10 col-xs-12">
      <form class="" [formGroup]="formRecover" (ngSubmit)="onSubmit()" novalidate>
        <ui-input
          [type]="'text'"
          name="username"
          id="email_formRecover"
          label="{{ 'Enter Code' | translate }}"
          [style]="'standard'"
          [size]="'medium'"
          [parentForm]="formRecover"
          [autofocus]="true"
          formControlName="username"
          autocomplete="off"
        >
          <div
            class="error-message-ani"
            [ngClass]="{
              active: (usernameFC.errors && usernameFC.dirty) || (usernameFC.invalid && formRecoverSubmitted)
            }"
          >
            <span class="error">
              <span class="error-message" [ngClass]="{ active: usernameFC.hasError('required') }">{{
                'Username is
                required' | translate
              }}</span>
              <span class="error-message" [ngClass]="{ active: usernameFC.hasError('minlength') }"
                >{{
                'Minimum length is {{value}}
                characters' | translate: usernameMinLength }}</span
              >
              <span class="error-message" [ngClass]="{ active: usernameFC.hasError('maxlength') }"
                >{{
                'Maximum length is {{value}}
                characters' | translate: usernameMaxLength }}</span
              >
            </span>
          </div>
        </ui-input>

        <div class="row">
          <div class="col-xs-12 text-center">
            <e11-button id="submit_formRecover" value="{{ 'Continue' | translate }}" color="secondary" [type]="'submit'"> </e11-button>
          </div>
        </div>

        <div class="spacer-2rem"></div>
      </form>
    </div>
    <div class="col-sm-1 hidden-xs"></div>
  </div>
</ui-panel>
