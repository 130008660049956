// Framework
import { Component, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { ITerms, TermsService } from '@engineering11/user-web'

@Component({
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class PublicTermsComponent implements OnInit, OnDestroy {
  themeCurrent: any
  termsOfUse?: SafeHtml
  subs = new SubscriptionManagementService()
  isLoading: boolean = true

  constructor(private termsService: TermsService, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.subs.next = this.termsService.getMostRecentTerms().subscribe((termsModels: ITerms[]) => {
      this.termsOfUse = this._sanitizer.bypassSecurityTrustHtml(termsModels[0].terms)
      this.isLoading = false
    })
  }

  ngOnDestroy(): void {
    this.subs.stop
  }
}
