import { environment } from '@candidate/environments/environment'
import { Injectable } from '@angular/core'
import { ICandidateJobSubmission, ICandidateJobSubmissionPreview, IProcessedJobApplication, TokenStorage } from 'shared-lib'
import { RestApiClient } from '@engineering11/web-api-rest'
import { map } from 'rxjs/operators'
import { CandidateApplicationService } from '../candidate-application/candidate-application.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class JobApplicationService {
  private restAPIClient: RestApiClient
  constructor(tokenStorage: TokenStorage, private candidateApplicationService: CandidateApplicationService) {
    this.restAPIClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: () => tokenStorage.getAccessToken(),
      throwNon200s: true,
    })
  }

  submitApplication(applicationInformation: ICandidateJobSubmission): Observable<IProcessedJobApplication> {
    return this.restAPIClient.post<IProcessedJobApplication>('processed-job-application', applicationInformation).pipe(map(item => item.data))
  }

  previewApplication(previewInformation: ICandidateJobSubmissionPreview): Observable<IProcessedJobApplication> {
    return this.restAPIClient.post('processed-job-application/preview', previewInformation).pipe(map(item => item.data))
  }

  withdrawApplication(jobPostId: string, candidateId: string) {
    const applicationId = this.candidateApplicationService.buildJobApplicationId(jobPostId, candidateId)
    return this.restAPIClient.put(`candidate-job-application/${applicationId}/withdraw`, {})
  }
}
