// Framework
import { HttpClient } from '@angular/common/http'
import { Component, OnInit, OnDestroy } from '@angular/core'

// Platform specific
import { ConfigService } from '@candidate/app/services/config.service'
import { environment } from '../../../../../environments/environment'
import { UiErrorsService, UiErrorsMessage } from '@engineering11/ui/ui-errors'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'

// Analytics
import { AnalyticsService } from '@engineering11/web-api-firebase'
import { Platform, Module, View, AnalyticsEvents, UserAction } from '@candidate/app/analytics.constants'
// import * as firebase from 'firebase/compat/app';

import firebase from 'firebase/compat/app'

@Component({
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class LandingStartComponent implements OnInit, OnDestroy {
  environment = environment
  env = environment.env
  themeCurrent: ''
  subs: SubscriptionManagementService = new SubscriptionManagementService()

  testJobListing: any = [] // Stores the job listing after it was found/verified
  analytics = firebase.analytics()

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private errorsService: UiErrorsService,
    private backdropService: E11BackdropService,
    private analyticsService: AnalyticsService
  ) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {
    // this.subs.next = this.testDataService.jobListingSourceObservable$.subscribe(data => {
    //   this.testJobListing = data
    // })
  }

  throwError() {
    throw new Error('Something just went sideways....')
  }

  throwHttpError() {
    this.http.get('urlhere').subscribe()
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  sendAnalyticsEvent(analyticsEvent: string, userAction: string) {
    this.analyticsService.logEvent(Platform.dev, {
      module: Module.landing,
      view: View.home,
      analyticsEvent: analyticsEvent,
      userAction: userAction,
    })
  }
}
