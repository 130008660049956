import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { CandidateHomeNavigationService, CNECT_ROUTES } from '@candidate/app/services/candidate-home-navigation.service'
import { InfoContentRoutes } from '@candidate/app/services/candidate-navigation-info-content.service'
import { IdenticonService } from '@candidate/app/services/identicon.service'
import { APP_NAVIGATION_LEFT_GROUPS, NavLeftService } from '@candidate/app/services/nav-left.service'
import { ViewportService } from '@candidate/app/services/viewport.service'
import { selectors } from '@candidate/app/store/selectors'
import { ICandidateUser } from '@cnect/user-shared'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CnectFeatures, ConversationTypes, getFeatures } from 'shared-lib'

const UI_NAVIGATION_POS = 112
@Component({
  selector: 'app-nav-left-informational',
  templateUrl: './nav-left-informational.component.html',
  styleUrls: ['./nav-left-informational.component.scss'],
})
export class AppNavLeftInformationalComponent implements OnInit, OnDestroy, AfterViewInit {
  routes = CNECT_ROUTES
  infoRoutes = InfoContentRoutes

  @ViewChild('navContainer') navContainer?: ElementRef
  @ViewChild('appFooter') appFooter?: ElementRef
  features$: Observable<CnectFeatures | undefined> = this.store.select(getFeatures)
  features?: CnectFeatures
  destroy$: Subject<boolean> = new Subject<boolean>()
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  conversationTypesEnum = ConversationTypes

  // Left Navigation
  navLeftBottomPos: number = 0
  navTop: number = UI_NAVIGATION_POS // Where the left nav starts out at
  // Groups/sections - Service will handle default values
  showSectionApplications: boolean = false
  showSectionProfiles: boolean = false
  showSectionVideos: boolean = false
  showSectionNetwork: boolean = false
  showSectionCommunities: boolean = false

  constructor(
    private store: Store,
    private candidateNavigationService: CandidateHomeNavigationService,
    private viewportService: ViewportService,
    private navLeftService: NavLeftService,
    private router: Router,
    private route: ActivatedRoute,
    public identiconService: IdenticonService
  ) {}

  viewport: string = ''

  ngOnInit(): void {
    this.processUrlForActiveSection(this.router.url)

    this.navLeftService.statusSectionApplicationsObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showSectionApplications = response
    })

    this.navLeftService.statusSectionProfilesObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showSectionProfiles = response
    })

    this.navLeftService.statusSectionVideosObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showSectionVideos = response
    })

    this.navLeftService.statusSectionNetworkObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showSectionNetwork = response
    })

    this.navLeftService.statusSectionCommunitiesObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.showSectionCommunities = response
    })

    this.viewportService.viewportSizeChanged$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.viewport = data
    })

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      if (value instanceof NavigationEnd) {
        this.processUrlForActiveSection(this.router.url)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ngAfterViewInit() {
    let datas = this.navContainer?.nativeElement.getBoundingClientRect()
    this.navLeftBottomPos = datas.top + this.navContainer?.nativeElement.offsetHeight
  }

  getFooterPos() {
    let element = document?.getElementById('appFooter')
    return element!.getBoundingClientRect().top
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event: any[]) {
    if (this.navLeftBottomPos) {
      if (this.getFooterPos() - this.navLeftBottomPos < UI_NAVIGATION_POS) {
        this.navTop = this.getFooterPos() - this.navLeftBottomPos
      } else {
        this.navTop = UI_NAVIGATION_POS
      }
    }
  }

  toggleSectionApplications() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Applications, !this.showSectionApplications)
  }

  toggleSectionProfiles() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Profiles, !this.showSectionProfiles)
  }

  toggleSectionVideos() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Videos, !this.showSectionVideos)
  }

  toggleSectionNetwork() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Network, !this.showSectionNetwork)
  }
  toggleSectionCommunities() {
    this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Communities, !this.showSectionCommunities)
  }

  getIdenticon(userId: string) {
    return this.identiconService.buildPath(userId)
  }

  processUrlForActiveSection(url: string) {
    if (url.includes(APP_NAVIGATION_LEFT_GROUPS.Applications)) {
      this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Applications, true)
    }
    if (url.includes(APP_NAVIGATION_LEFT_GROUPS.Network)) {
      this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Network, true)
    }
    if (url.includes(APP_NAVIGATION_LEFT_GROUPS.Profiles)) {
      this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Profiles, true)
    }
    if (url.includes(APP_NAVIGATION_LEFT_GROUPS.Videos)) {
      this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Videos, true)
    }
    if (url.includes(APP_NAVIGATION_LEFT_GROUPS.Communities)) {
      this.navLeftService.navToggleGroup(APP_NAVIGATION_LEFT_GROUPS.Communities, true)
    }
  }
}
