// Framework
import { Component } from '@angular/core'

import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'

// Analytics
import { AnalyticsService } from '@engineering11/web-api-firebase'
import { Platform, Module, View } from '@candidate/app/analytics.constants'

import { IAuthService } from '@engineering11/auth-web'
import { TokenStorage } from 'shared-lib'

@Component({
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class AuthTermsOfUseComponent {
  userId: string
  redirectPath = 'profile'

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private authService: IAuthService,
    private tokenStorage: TokenStorage
  ) {
    const user = this.tokenStorage.getItem('user')
    this.userId = user.id
  }

  onDecline(): void {
    this.sendAnalyticsEvent('terms_decline', 'button_click')
    this.authService.signOut()
  }

  onAccept(user: string): void {
    this.tokenStorage.setItem('user', user)
    this.sendAnalyticsEvent('terms_accept', 'form_submit')
    this.tokenStorage.setItem('user', user)
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    if (primary.segments.length > 1) {
      this.redirectPath = decodeURIComponent(primary.segments[primary.segments.length - 1].path || this.redirectPath)
    }
    this.router.navigate([this.redirectPath])
  }

  sendAnalyticsEvent(analyticsEvent: string, userAction: string) {
    this.analyticsService.logEvent(Platform.dev, {
      module: Module.authentication,
      view: View.termsOfUse,
      analyticsEvent: analyticsEvent,
      userAction: userAction,
    })
  }
}
