import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { NGXLogger } from 'ngx-logger'
import { FilesService } from '@engineering11/files-web'
import { v4 as uuidv4 } from 'uuid'
import { IFirstImpression, TokenStorage } from 'shared-lib'
import { ICandidateUser } from '@cnect/user-shared'
import { DomSanitizer } from '@angular/platform-browser'
import { takeUntil } from 'rxjs/operators'
import { VideoThumbnailsService } from '@engineering11/multimedia-web'

const mimeDB: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/webp': 'webp',
}
const MAX_THUMBS = 4
@Component({
  selector: 'first-impression-free-form',
  templateUrl: './first-impression-form.component.html',
  styleUrls: ['./first-impression-form.component.scss'],
})
export class FirstImpressionFreeFormComponent implements OnDestroy, OnInit {
  @Input()
  formReadOnly = false

  @Input()
  thumbnails: string[] = []

  @Input()
  thumbnailsLoading = false

  @Input()
  script: string = ''

  @Input()
  videoLength: string = '00:00'

  selectedThumbnail = 0

  maxThumbs = MAX_THUMBS

  @Output()
  submittedSuccessfully: EventEmitter<IFirstImpression> = new EventEmitter()

  _video!: File
  formFirstImpression = new UntypedFormGroup({})

  formSubmitted = false
  loading = false

  user!: ICandidateUser

  destroy$: Subject<boolean> = new Subject<boolean>()
  _videoURL?: string

  generateThumbLoading: boolean = false

  constructor(
    private domSanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private filesService: FilesService,
    private tokenStorage: TokenStorage,
    private videoThumbnailsService: VideoThumbnailsService
  ) {}

  ngOnInit(): void {
    this.user = this.tokenStorage.getItem('user')

    this.videoThumbnailsService
      .getCompleteCapture()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        files => {
          this.generateThumbLoading = false
          this.thumbnails = files
        },
        err => {
          this.generateThumbLoading = false
        }
      )
  }

  @Input()
  set video(value: File) {
    this.formSubmitted = false
    this._video = value
    const URL = window.URL || window.webkitURL
    this._videoURL = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(value)) as string
    this.generateThumbs(this._video)

    this.initForm(value)
  }

  ngOnDestroy(): void {
    this.resetForm()
    this.videoLength = '00:00'
    this.destroy$.next(true)
    this.destroy$.complete()
    this._videoURL = undefined
  }

  get f() {
    return this.formFirstImpression.controls as {
      [key: string]: UntypedFormControl
    }
  }

  initForm(video: File) {
    this.formFirstImpression = this.formBuilder.group({
      id: new UntypedFormControl(),
      name: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(''),
      video: new UntypedFormControl(video),
    })
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      var mimeType = file.type
      if (mimeType.match(/image\/*/) == null) {
        return
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = _event => {
        if (this.thumbnails.length < MAX_THUMBS) {
          this.thumbnails.push(reader.result as string)
        } else {
          this.thumbnails[MAX_THUMBS - 1] = reader.result as string
        }
        this.selectedThumbnail = this.thumbnails.length - 1 // TODO: Auto-select thumbnail on upload in a nicer way
      }
    }
  }

  resetForm() {
    this.formFirstImpression.reset()
  }

  async onSubmitForm() {
    this.formSubmitted = true
    this.formFirstImpression.markAllAsTouched()
    if (this.formFirstImpression.valid) {
      this.loading = true
      const id = uuidv4()
      const firstImpression: IFirstImpression = {
        id,
        name: this.formFirstImpression.get('name')?.value,
        description: this.formFirstImpression.get('description')?.value,
        thumb: await this.uploadFile(await this.getThumb(), id),
        video: await this.uploadFile(this._video, id),
        videoLength: this.videoLength,
      }
      this.logger.log('submittedSuccessfully - emitting: ', firstImpression)
      this.submittedSuccessfully.emit(firstImpression)
      this.loading = false
    }
  }

  async getThumb() {
    const blob = await (await fetch(this.thumbnails[this.selectedThumbnail])).blob()
    const ext: string = mimeDB[blob.type] || 'jpg'
    const thumbName = encodeURIComponent(`first_impression_${new Date().getTime()}.${ext}`)
    return new File([blob], thumbName)
  }
  async uploadFile(file: File, id: string) {
    const fileResult = await this.filesService.upload(file, this.user.customerKey, id)
    return fileResult
  }

  generateThumbs(file: File) {
    this.thumbnails = []
    this.generateThumbLoading = true
    this.videoThumbnailsService.capture(file)
  }
}
