import { IFileReceipt } from '@engineering11/files-web'

export enum CANDIDATE_VIDEO_TYPE {
  FIRST_IMPRESSION = 'firstImpression',
}

export interface ICandidateVideo {
  id: string
  userId: string
  video: IFileReceipt
  thumb: IFileReceipt
  type: CANDIDATE_VIDEO_TYPE
  name?: string
  description?: string
  script?: string
  videoLength?: string
  views?: number
  signedUrl?: string
  __createdAt?: Date
  __updatedAt?: Date
}
