import { Component, OnInit } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { ICandidateUser } from '@cnect/user-shared'
import { sleep } from '@engineering11/utility'
import { copyToClipboard } from '@engineering11/web-utilities'
import { select, Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { getCurrentUser } from 'shared-lib'

@Component({
  selector: 'referral-link-copy',
  templateUrl: './referral-link-copy.component.html',
  styleUrls: ['./referral-link-copy.component.scss'],
})
export class ReferralLinkCopyComponent implements OnInit {
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(getCurrentUser))
  currentUser?: ICandidateUser | null
  showCopiedMessage = false
  destroy$: Subject<boolean> = new Subject<boolean>()
  constructor(private logger: NGXLogger, private store: Store) {}

  ngOnInit(): void {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(currentUser => (this.currentUser = currentUser))
  }
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
  async copyReferralLink() {
    if (this.currentUser) {
      const referrerId = this.currentUser.id
      this.logger.log('copying referral link')
      copyToClipboard(this.getReferralLink(referrerId))

      this.showCopiedMessage = true
      await sleep(2000)
      this.showCopiedMessage = false
    }
  }

  private getReferralLink(referrerId: string) {
    return `${environment.candidateBaseUrl}/#/registration/register-referral/home?referrerId=${referrerId}`
  }
}
