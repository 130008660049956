<ng-container *ngrxLet="selectedJob$ as selectedJob">
  <ng-container *ngIf="selectedJob">
    <e11-divider [margin]="1"></e11-divider>

    <div class="e11-w-full e11-flex">
      <div class="e11-grow">
        <job-info-header [jobDetail]="selectedJob" (jobTitleClick)="toSidePage(applicationPageEnum.JobDescription)"> </job-info-header>
      </div>
      <div class="e11-grow e11-flex e11-justify-end">
        <e11-button
          *ngIf="nextPage"
          [icon]="'chevron_right'"
          [iconPosition]="'right'"
          [size]="(isMobile$ | async) ? 'sm' : 'md'"
          [color]="'primary-accent'"
          [style]="'pill'"
          (click)="!isNextDisabled() && navNext(selectedJob.jobId)"
          value="{{ (isMobile$ | async) ? '' : ('Next:' | translate) }} {{ nextPage }}"
        ></e11-button>
        <e11-button
          *ngIf="currentPage === applicationPageEnum.ApplicationReview"
          [icon]="'send'"
          [iconPosition]="'right'"
          [size]="(isMobile$ | async) ? 'sm' : 'md'"
          [color]="'primary-accent'"
          [style]="'pill'"
          (click)="confirmSubmission()"
          value="{{ 'Submit Application' | translate }}"
        ></e11-button>
      </div>
    </div>

    <ng-container *ngIf="pageLoaded; else Loading">
      <app-application-wayfinder
        [currentPage]="currentPage"
        [selectedApplication]="selectedApplication"
        [candidateResponsesComplete]="candidateQuestionsForm?.isFormValid"
        (pageSelected)="navDirectlyTo($event)"
      ></app-application-wayfinder>
      <ng-container *ngIf="currentPage === applicationPageEnum.JobDescription">
        <div *ngrxLet="selectedJob$ as selectedJob">
          <job-detail-display *ngIf="selectedJob; else Loading" [jobDetail]="selectedJob!"></job-detail-display>
        </div>
      </ng-container>
      <!-- Confirmations -->
      <span [hidden]="!(currentPage === applicationPageEnum.CandidateResponses)">
        <ng-container *ngrxLet="selectedApplication$ as selectedApplication">
          <candidate-question-responses
            #CandidateQuestionsForm
            [currentResponses]="selectedApplication?.candidateResponses | deepCopy"
            [jobDetail]="selectedJob"
            [loading]="!selectedApplication || !selectedJob"
            (newResponses)="changeCandidateResponses(selectedJob.jobId, $event)"
          >
          </candidate-question-responses>
        </ng-container>
      </span>
      <!-- Resume Select -->
      <ng-container *ngIf="currentPage === applicationPageEnum.PopulateApplication">
        <ng-container *ngrxLet="resumes$ as resumes">
          <resume-select
            [resumeList]="resumes"
            (selectedResume)="chooseResumeForApplication($event)"
            (applyManually)="applyManually()"
          ></resume-select>
        </ng-container>
      </ng-container>
      <!-- Manage -->
      <ng-container *ngIf="currentPage === applicationPageEnum.EditApplication">
        <application-manage [jobPost]="selectedJob"></application-manage>
      </ng-container>
      <!-- Submit Application -->
      <ng-container *ngIf="currentPage === applicationPageEnum.ApplicationReview">
        <ng-container *ngrxLet="selectedApplication$ as selectedApplication">
          <application-review [jobPost]="selectedJob"></application-review>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Footer Buttons -->
    <div class="e11-w-full e11-flex e11-mt-8 e11-mb-16">
      <div class="e11-w-1/2">
        <e11-button
          [disabled]="!previousPage"
          [icon]="'chevron_left'"
          [iconPosition]="'left'"
          [size]="(isMobile$ | async) ? 'sm' : 'md'"
          [color]="'secondary'"
          [style]="'pill outlined'"
          (click)="previousPage && navPrevious(selectedJob.jobId)"
          value="{{ (isMobile$ | async) ? '' : ('Back:' | translate) }} {{ previousPage ?? ('' | translate) }}"
        ></e11-button>
      </div>
      <div class="e11-w-1/2 e11-text-right">
        <e11-button
          *ngIf="nextPage"
          [icon]="'chevron_right'"
          [iconPosition]="'right'"
          [size]="(isMobile$ | async) ? 'sm' : 'md'"
          [color]="'primary-accent'"
          [style]="'pill'"
          (click)="!isNextDisabled() && navNext(selectedJob.jobId)"
          value="{{ (isMobile$ | async) ? '' : ('Next:' | translate) }} {{ nextPage }}"
        ></e11-button>
        <e11-button
          *ngIf="currentPage === applicationPageEnum.ApplicationReview"
          [icon]="'send'"
          [iconPosition]="'right'"
          [size]="(isMobile$ | async) ? 'sm' : 'md'"
          [color]="'primary-accent'"
          [style]="'pill'"
          (click)="confirmSubmission()"
          value="{{ 'Submit Application' | translate }}"
        ></e11-button>
      </div>
    </div>

    <ng-template #Loading>
      <div class="e11-flex e11-justify-center">
        <e11-loader-dots></e11-loader-dots>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<e11-confirmation
  #confirmation
  confirmTitle="{{ 'Submit Application' | translate }}"
  confirmText="{{ 'Submit' | translate }}"
  cancelText="{{ 'Edit' | translate }}"
  [backdropStyle]="'dark'"
  (answer)="confirmationAnswer($event)"
>
  <div [innerHTML]="confirmationMessage | translate"></div>
</e11-confirmation>
